import { Box, Button, IconButton, Textarea, Typography } from "@mui/joy";
import React from "react";
import { FaPen } from "react-icons/fa6";
import { MdOutlineCancelPresentation } from "react-icons/md";

import logo2 from "../logo/color.png";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../Config/firebase";
import useUser from "../Utils/useUser";
import { CircularProgress } from "@mui/material";

export default function ReviewModal({ setModalState, modalState }) {
  const [text, setText] = React.useState("");
  const addEmoji = (emoji) => () => setText(`${text}${emoji}`);
  const [user] = useUser();

  const [reviewPostLoading, setReviewPostLoading] = React.useState(false);

  const submitReview = async (e) => {
    e.preventDefault();
    setReviewPostLoading(true);

    try {
      if (user) {
        await addDoc(collection(db, "reviews"), {
          review: text,
          reviewer: user?.email
        });

        window.alert("Thank you for you review!");
      } else {
        window.alert("Please login and try again!");
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <div className="flex flex-col gap-1 w-max h-max mt-4 mb-6 p-7 bg-gradient-to-tr from-white via-p4 via-40% to-p4 rounded-xl drop-shadow-xl">
        <p className="text-lg font-semibold flex items-center justify-between text-p2 font-sans">
          <div className="flex gap-1 items-center">
            <img
              src={logo2}
              style={{
                objectFit: "contain",
                width: "2rem",
              }}
              alt=""
            />
            Write a Review{" "}
          </div>
          <FaPen />
        </p>
        <form action={submitReview} method="post" className="flex flex-col">
          <Textarea
            placeholder="What do you think about AccessCareer 👀"
            value={text}
            onChange={(event) => setText(event.target.value)}
            minRows={5}
            maxRows={7}
            startDecorator={
              <Box sx={{ display: "flex", gap: 0.5, flex: 1 }}>
                <IconButton
                  variant="outlined"
                  color="neutral"
                  onClick={addEmoji("👍")}
                >
                  👍
                </IconButton>
                <IconButton
                  variant="outlined"
                  color="neutral"
                  onClick={addEmoji("🤝")}
                >
                  🤝
                </IconButton>
                <IconButton
                  variant="outlined"
                  color="neutral"
                  onClick={addEmoji("🤩")}
                >
                  🤩
                </IconButton>
                <Button
                  type="submit"
                  variant="outlined"
                  color="neutral"
                  sx={{ ml: "auto" }}
                >
                  { 
                    reviewPostLoading ? (
                      <CircularProgress />
                    ) : "Post"
                  }
                </Button>
              </Box>
            }
            endDecorator={
              <Typography level="body-xs" sx={{ ml: "auto" }}>
                {text.length} character(s)
              </Typography>
            }
            style={{
              width: 300,
            }}
          />
        </form>
        <div className="w-full">
          <MdOutlineCancelPresentation
            className="float-right text-p3 cursor-pointer"
            size={22}
            onClick={() => {
              setModalState(!modalState);
            }}
          />
        </div>
      </div>
    </>
  );
}
