import React, {useState} from 'react';
import EmployerNav from '../Company/Components/EmployerNav';
import PostCard from "./Components/ViewProfileStuPostCard";
import usePosts from './Components/ViewProfileUsePosts';
import { ArrowLeftSharp } from "@mui/icons-material";
import { WiStars } from "react-icons/wi";
import { useNavigate, useParams } from "react-router-dom";
import StuProfileCard from './Components/ViewProfileStuProfileCard';
import { GoogleGenerativeAI } from "@google/generative-ai";

export default function ViewProfile() {
  const { id } = useParams(); // Get the id from the URL parameters
  const posts = usePosts(id); // Pass the id to the usePosts hook
  const [aiSummary, setAiSummary] = useState("You'll need an API key from Google Maker Suite (previously known as Dialogflow) to access the Gemini AI functionality. Follow the instructions on Google's documentation to obtain a key: [invalid URL removed]");
  const [openSummary, setOpenSummary] = useState(false);
  const GenAI = new GoogleGenerativeAI(process.env.REACT_APP_GEN_AI_API_KEY)

  const navigate = useNavigate();
  console.log('API Key from .env:', process.env.REACT_APP_GEN_AI_API_KEY);
  console.log('ViewProfile userId:', id); // Debugging line
  console.log('ViewProfile posts:', posts); // Debugging line
  const summarizePosts = async () => {

    if (posts.length > 0) {
      const postContent = posts.map(post => post.content).join('\n');
      try {
        const model = GenAI.getGenerativeModel({ model: "gemini-1.5-flash"});
        const prompt = `Summarize the following text for a user looking for key points:\n${postContent}`
        const result = await model.generateContent(prompt);
        const response = result.response;
        const summary = response.text();

        setAiSummary(summary.length > 0 ? summary: "Not enough information to generate summary");
        handleOpenClose();
        if (response.err) {

          console.error('Error summarizing posts:', response.error);
          return;
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      window.alert("No posts to analyze");
    }
  }
  const handleOpenClose = () => {

    setOpenSummary(!openSummary);

  }
  return (
    <>
      <EmployerNav />
      <div className="flex flex-col items-center gap-4 bg-gray-50 min-h-screen min-w-screen w-full h-full p-5">
        <div className="flex w-full justify-between items-center">
          <button
            className="flex items-center w-max"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftSharp />
            Go Back
          </button>
          {/* <div className="relative">
            <button className="w-max rounded-full bg-white shadow-lg shadow-purple-300 flex justify-center items-center cursor-pointer pl-2" onClick={() => {
              summarizePosts();
            }}>
              <p style={{  
                color: "purple",
              }}>
                Generate
              </p>
              <WiStars size={35} color='purple' />
            </button>
            <div className={`${openSummary ? "absolute z-40" : "hidden"} bg-white shadow-md shadow-purple-300 p-4 rounded-md m-1 min-w-[15rem] max-w-[25rem] right-5 flex flex-col gap-1`}>
              <p>
                {aiSummary}
              </p>
              <button className="text-sm p-2 bg-red-900 text-white font-bold rounded-md" onClick={handleOpenClose}>Close</button>
            </div>
          </div> */}
        </div>
        <StuProfileCard userId={id} /> {/* Pass the id to StuProfileCard */}
        {posts.length > 0 ? (
          posts.map(post => (
            <PostCard key={post.id} {...post} from="vp" />
          ))
        ) : (
          <p>No posts available.</p>
        )}
      </div>
    </>
  );
}
