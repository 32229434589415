import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContainer from "../Components/AuthContainer";
import { CancelOutlined, Mail, Password, Star } from "@mui/icons-material";
import { Button, Input, Option, Select, Textarea } from "@mui/joy";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { CircularProgress } from "@mui/material";
import { indianStates, industryTypes } from "../Exports";
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
} from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import Firebase Storage
import { auth, db } from "../Config/firebase";
import SocialsAuth from "../Components/socials-auth";

export default function NewUser() {
  const { type } = useParams();
  const navigation = useNavigate();

  const [registrationType, setRegType] = useState();
  const [Regtype, setType] = useState();
  const [showPwd, setShowPwd] = useState(false);
  const [loading, setLoading] = useState(false);

  const [semail, setsemail] = useState();
  const [spwd, setspwd] = useState();
  const [typeInSkill, setTypeInSkill] = useState();
  const [skills, setSkills] = useState([]);
  const [resume, setResume] = useState(null); // State for the resume file

  const [eemail, seteemail] = useState();
  const [epwd, setepwd] = useState();
  const [noOfEmps, setNoOfEmps] = useState("0-10");
  const [industry, setIndustry] = useState("Media");
  const [location, setLocation] = useState("Telangana");
  const [Name, setName] = useState("2023");

  const [alert, setAlert] = useState("");

  const storage = getStorage(); // Initialize Firebase Storage

  const signup_stu = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (skills.length === 0 && typeInSkill?.length > 0) {
      setSkills((prev) => [...prev, typeInSkill]);
    }
    if (skills.length === 0) {
      setAlert(
        "Please enter at least one skill related to your field of study."
      );
      setLoading(false);

      return;
    }

    try {
      const data = await fetchSignInMethodsForEmail(auth, semail);

      if (data?.length === 0) {
        const user = await createUserWithEmailAndPassword(auth, semail, spwd);

        // Upload resume to Firebase Storage
        let resumeURL = null;
        if (resume) {
          const storageRef = ref(storage, `resumes/${user.user.uid}`);
          await uploadBytes(storageRef, resume);
          resumeURL = await getDownloadURL(storageRef);
        }

        await addDoc(collection(db, "Users"), {
          UserID: user.user.uid,
          Employer: false,
        });

        await addDoc(collection(db, "student_skills"), {
          student: auth?.currentUser?.uid,
          skills: skills,
        });
        await addDoc(collection(db, "other_details"), {
          student: auth?.currentUser?.uid,
          name: auth?.currentUser?.email,
          phone: 0,
          linkedin: "",
          about: "",
          resumeURL: resumeURL, // Save resume URL to Firestore
        });
        await addDoc(collection(db, "emails"), {
          user: user.user.uid,
          email: semail,
        });

        await sendEmailVerification(auth.currentUser);

        window.alert("Verification Email has been sent!");
        navigation("/auth?tab=student");
      } else {
        setAlert("User with email already exists");
      }
    } catch (error) {
      console.error("Error during sign-up:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const signup_emp = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = await fetchSignInMethodsForEmail(auth, eemail);

      if (data?.length === 0) {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          eemail,
          epwd
        );
        const user = userCredential.user;

        if (user) {
          await addDoc(collection(db, "Users"), {
            UserID: user.uid,
            Employer: true,
          });

          await addDoc(collection(db, "employers"), {
            company: user.uid,
            established: Name,
            industry: industry,
            location: location,
            noofemployees: noOfEmps,
            description: "",
          });
          await addDoc(collection(db, "emails"), {
            user: user.uid,
            email: eemail,
          });

          try {
            await sendEmailVerification(user);
            window.alert("Verification Email has been sent!");
          } catch (emailError) {
            console.error("Error sending verification email:", emailError);
            window.alert("Failed to send verification email. Please check your email address and try again.");
          }

          navigation("/auth?tab=employer");
        } else {
          console.error("User not created");
        }
      } else {
        setAlert("User with email already exists");
      }
    } catch (error) {
      window.alert(error)
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNoOfEmpsChange = (event, newValue) => {
    setNoOfEmps(newValue);
  };

  const handleIndustryChange = (event, newValue) => {
    setIndustry(newValue);
  };

  const handleLocationChange = (event, newValue) => {
    setLocation(newValue);
  };

  useEffect(() => {
    setRegType(type);
    setType(type === "student" ? true : false);
  }, [registrationType]);

  useEffect(() => {
    setTimeout(() => {
      setAlert("");
    }, 5000);
  }, [alert]);

  return (
    <div>
      <Helmet>
        <title>
          AccessCareer | Login & Registration | Start your career with a fire
        </title>
        <meta
          name="description"
          content="Take your first step towards your dream career with AccessCareer! We offer a curated selection of internships across diverse fields, exclusively for college students and recent graduates. No more sifting through irrelevant job boards - find your perfect internship match on AccessCareer."
        />
        <meta
          name="keywords"
          content="Software engineering, Mechanical engineering, Electrical engineering, Chemical engineering, Civil engineering, Biomedical engineering, Data science, Cybersecurity, Artificial intelligence, Machine learning, Cloud computing, Software development, Finance, Marketing, Management, Human resources, Operations, Consulting, Graphic design, Product design, UX/UI design, Web design, Fashion design, Interior design, Internship, College internships, Recent graduate internships, Entry-level jobs, Job search, Career development"
        />
        <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
      </Helmet>

      <AuthContainer alert={alert} type={Regtype} setType={setType}>
        {Regtype ? (
          <form onSubmit={signup_stu} className="flex flex-col gap-3 w-full">
            <div>
              <label htmlFor="" className="font-semibold text-p1">
                E-mail
              </label>
              <Input
                placeholder="E-mail"
                name="email"
                startDecorator={<Mail />}
                onChange={(e) => {
                  setsemail(e.target.value);
                }}
                disabled={loading}
              />
            </div>
            <div>
              <label htmlFor="" className="font-semibold text-p1">
                Create a secure password
              </label>
              <Input
                placeholder="Password"
                type={showPwd ? "text" : "password"}
                name="password"
                startDecorator={<Password />}
                onChange={(e) => {
                  setspwd(e.target.value);
                }}
                endDecorator={
                  <>
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setShowPwd(!showPwd);
                      }}
                    >
                      {showPwd ? <BsEye /> : <BsEyeSlash />}
                    </span>
                  </>
                }
                disabled={loading}
              />
            </div>
            <div>
              <label htmlFor="" className="font-semibold text-p1">
                Add skills
              </label>
              <Input
                placeholder="Seperate skills with a space to add"
                name="skill"
                autoComplete={"off"}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setTypeInSkill(inputValue);

                  if (inputValue.includes(" ")) {
                    setSkills((prevSkills) => [
                      ...prevSkills,
                      inputValue.split(" ")[0],
                    ]);
                    setTypeInSkill("");
                  }
                }}
                value={typeInSkill}
                startDecorator={<Star />}
                disabled={loading}
              />
              <div className="flex flex-wrap max-h-[20vh] overflow-y-auto">
                {skills &&
                  skills?.length > 0 &&
                  skills.map((skill) => {
                    return (
                      <div
                        key={skill}
                        className="flex p-2 w-max rounded-full bg-blue-300 items-center justify-center m-2 text-white font-semibold gap-1"
                      >
                        {skill.slice(0, 1).toUpperCase() + skill.slice(1)}
                        <CancelOutlined
                          className="cursor-pointer text-red-800"
                          fontSize="1rem"
                          onClick={() => {
                            setSkills((prevSkills) =>
                              prevSkills.filter(
                                (prevSkill) => prevSkill !== skill
                              )
                            );
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div>
              <label htmlFor="" className="font-semibold text-p1">
                Upload Resume
              </label>
              <Input
                type="file"
                accept="application/pdf"
                onChange={(e) => setResume(e.target.files[0])}
                disabled={loading}
              />
            </div>
            <Button type="submit" disabled={loading}>
              {!loading ? "Register" : <CircularProgress />}
            </Button>
            <Link
              to={"/auth"}
              className="flex w-full justify-center p-1 hover:underline"
            >
              Login to an existing account
            </Link>
            <SocialsAuth />
          </form>
        ) : (
          // EMPLOYERS
          <form onSubmit={signup_emp} className="flex flex-col gap-3 w-full">
            <div>
              <label htmlFor="" className="font-semibold text-p1">
                E-mail
              </label>
              <Input
                placeholder="E-mail"
                startDecorator={<Mail />}
                onChange={(e) => {
                  seteemail(e.target.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="" className="font-semibold text-p1">
                Create a secure password
              </label>
              <Input
                placeholder="Password"
                type={showPwd ? "text" : "password"}
                startDecorator={<Password />}
                onChange={(e) => {
                  setepwd(e.target.value);
                }}
                endDecorator={
                  <>
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setShowPwd(!showPwd);
                      }}
                    >
                      {showPwd ? <BsEye /> : <BsEyeSlash />}
                    </span>
                  </>
                }
              />
            </div>
            <div className="w-full flex flex-col md:grid md:grid-cols-2 md:grid-rows-2 gap-1">
              <div>
                <label htmlFor="" className="font-semibold text-p1">
                  No. of employees
                </label>
                <Select defaultValue={"0-10"} onChange={handleNoOfEmpsChange}>
                  <Option value={"0-10"}>0-10</Option>
                  <Option value={"10-50"}>10-50</Option>
                  <Option value={"50-100"}>50-100</Option>
                  <Option value={"100-200"}>100-200</Option>
                  <Option value={"200+"}>200+</Option>
                </Select>
              </div>
              <div>
                <label htmlFor="" className="font-semibold text-p1">
                  Industry
                </label>
                <Select defaultValue={"Media"} onChange={handleIndustryChange}>
                  {industryTypes.map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <label htmlFor="" className="font-semibold text-p1">
                  Location
                </label>
                <Select
                  defaultValue={"Telangana"}
                  onChange={handleLocationChange}
                >
                  {indianStates.map((state) => (
                    <Option key={state} value={state}>
                      {state}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <label htmlFor="" className="font-semibold text-p1">
                  Name
                </label>
                <Input
                  placeholder="AccessCareer"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            <Button type="submit" disabled={loading}>
              {!loading ? "Register" : <CircularProgress />}
            </Button>
            <Link
              to={"/auth"}
              className="flex w-full justify-center p-1 hover:underline"
            >
              Login instead
            </Link>
            <SocialsAuth />
          </form>
        )}
      </AuthContainer>
    </div>
  );
}
