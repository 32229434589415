import React from 'react';
import useAllPosts from './Components/AllStuPosts';
import PostCard from './Components/StuPostCard';
import EmployerNav from './Components/EmployerNav';

export default function Explore() {
  const { posts, loading } = useAllPosts();

  return (
    <>
      <EmployerNav />
      <div className="flex flex-col items-center gap-4 bg-gray-50 min-h-screen min-w-screen w-full h-full p-5">
        {loading ? (
          <div>Loading...</div>
        ) : posts.length > 0 ? (
          posts.map(post => <PostCard key={post.id} {...post} from="e" />)
        ) : (
          <div>No posts available</div>
        )}
      </div>
    </>
  );
}
