import { ArrowDownwardSharp, ArrowOutwardSharp, ArrowRightSharp } from "@mui/icons-material";
import { collection, getDocs, or, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../Config/firebase";
import useUser from "../Utils/useUser";
import { Link } from "react-router-dom";

export default function Chats() {
	const [openChat, setOpenChat] = useState(false);
	const [user, authChecked] = useUser();
	const [chats, setChats] = useState([]);
	const [isEmployer, setIsEmployer] = useState(false);
	const [recipientId, setRecipientId] = useState(null); // New state for recipient ID

	const fetchChats = async () => {
		try {
			const res = await getDocs(
				query(
					collection(db, "chats"),
					or(
						where("chatOf", "==", user?.uid),
						where("chatWith", "==", user?.uid),
					),
				),
			);

			const chatsArray = [];

			await Promise.all(
				res.docs.map(async (doc) => {
					let chatWith;
					if (doc.data().chatOf !== user?.uid) {
						setIsEmployer(false);

						chatWith = await getDocs(
							query(
								collection(db, "employers"),
								where("company", "==", doc.data().chatOf),
							),
						);

						chatWith.forEach((chatDoc) => {
							chatsArray.push({
								id: doc.id,
								name: chatDoc.data().established,
								recipientId: doc.data().chatOf, // Add recipient ID
							});
						});
					} else {
						setIsEmployer(true);

						chatWith = await getDocs(
							query(
								collection(db, "other_details"),
								where("student", "==", doc.data().chatWith),
							),
						);

						chatWith.forEach((chatDoc) => {
							chatsArray.push({
								id: doc.id,
								name: chatDoc.data().name,
								recipientId: doc.data().chatWith, // Add recipient ID
							});
						});
					}
				}),
			);

			setChats(chatsArray);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (user) {
			fetchChats();
		}
	}, [user]);

	return (
		<div className="bottom-0 w-[50vw] md:w-[20vw] fixed right-1 rounded-t-md">
			{!openChat ? (
				<div className="flex justify-between p-2 bg-p3 text-white rounded-t-md">
					<p className="text-md font-semibold">
						Chats ({chats.length})
					</p>
					<ArrowOutwardSharp
						className="cursor-pointer"
						onClick={() => setOpenChat(true)}
					/>
				</div>
			) : (
				<div className="flex flex-col shadow-md">
					<div className="flex justify-between p-2 bg-p3 text-white rounded-t-md">
						<p className="text-md font-semibold">
							Chats ({chats.length})
						</p>
						<ArrowDownwardSharp
							className="cursor-pointer"
							onClick={() => setOpenChat(false)}
						/>
					</div>
					<div className="flex flex-col gap-3 p-2 bg-white overflow-y-auto max-h-[40vh]">
						{chats.map((chat) => {
							return (
								<ChatName
									id={chat.id}
									name={chat.name}
									recipientId={chat.recipientId} // Pass recipient ID to ChatName
									isEmployer={isEmployer}
									setRecipientId={setRecipientId} // Pass setRecipientId to update recipient ID state
								/>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
}

const ChatName = ({ name, id, isEmployer, recipientId, setRecipientId }) => {
	const handleClick = () => {
		setRecipientId(recipientId); // Update recipient ID state on click
	};

	return (
		<Link
			to={isEmployer ? `/employer/c/${id}` : `/c/${id}`}
			className="flex justify-between items-center w-full cursor-pointer"
			onClick={handleClick} // Handle click to update recipient ID
			
		>
			<div className="flex gap-1 items-center">{name}</div>
			<ArrowRightSharp />
		</Link>
	);
};
