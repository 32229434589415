import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import {getStorage, ref} from "firebase/storage";
import { getMessaging } from "firebase/messaging";
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCy21LnguNkEQGZU6WKTI2bg5hm1vNDJcg",
  authDomain: "anchor-interns.firebaseapp.com",
  databaseURL: "https://anchor-interns-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "anchor-interns",
  storageBucket: "anchor-interns.appspot.com",
  messagingSenderId: "249520941019",
  appId: "1:249520941019:web:d4c088cf12f41c4cfb29fe",
  measurementId: "G-LYNPYX2D4Z"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const firestore = new Firestore();
export const storage = getStorage(app);
//export const messaging = messaging();

