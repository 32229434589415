import { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { db } from '../../../Config/firebase';

const useAllPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsQuery = query(collection(db, 'posts'), orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(postsQuery);

        const postsData = await Promise.all(querySnapshot.docs.map(async (doc) => {
          const postData = doc.data();
          const userRef = collection(db, 'other_details');
          const userQuery = query(userRef, where('student', '==', postData.userId));
          const userSnapshot = await getDocs(userQuery);

          let userName = '';
          let photoURL = '';
          let userDetails = null;

          if (!userSnapshot.empty) {
            const userDoc = userSnapshot.docs[0];
            userDetails = userDoc.data();
            userName = userDetails.name;
            photoURL = userDetails.photoURL;
          }

          return {
            id: doc.id,
            userId: postData.userId, // Pass userId
            ...postData,
            userName,
            photoURL,
            userDetails,
          };
        }));

        setPosts(postsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  return { posts, loading };
};

export default useAllPosts;
