import React, { useEffect, useState } from "react";
import {
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	where,
} from "firebase/firestore";
import { db } from "../Config/firebase";
import Nav from "../Components/Nav";
import InternshipCard from "../Components/InternshipCard";
import useUser from "../Utils/useUser";

import { BookmarkOutlined, Refresh } from "@mui/icons-material";
import { CircularProgress } from "@mui/joy";
import Chats from "../Components/Chats";

export default function Saved() {
	const [info, setInfo] = useState([]);
	const [loading, setLoading] = useState(true);
	const [user, authChecked] = useUser();
	const [reload, setReload] = useState(false);

	var includes = [];

	const fetchData = async () => {
		try {
			setLoading(true);
			const uid = await user?.uid;

			const savedCollectionRef = collection(db, "savedInternships");
			const employerCollectionRef = collection(db, "employers");

			const savedInternshipsDocs = await getDocs(
				savedCollectionRef,
				query(where("of", "==", uid)),
			);

			savedInternshipsDocs.forEach((savedInternshipsDoc) => {
				savedInternshipsDoc.data().saved.map(async (saved) => {
					if (saved !== "") {
						const internshipDocRef = doc(db, "internships", saved);
						const internship = await getDoc(internshipDocRef);

						var employer = null;

						if (internship && internship.data() && internship.data().employer) {
							employer = internship.data().employer;
						} else {
							return;
						}

						const employerQuery = await getDocs(
							employerCollectionRef,
							query(
								where(
									"company",
									"==",
									employer,
								),
							),
						);

						employerQuery.forEach(async (emps) => {
							await setInfo((prev) => [
								...prev,
								{
									employers: emps.data(),
									internship: {
										...internship.data(),
										id: internship.id,
									},
								},
							]);
						});
					}
				});
			});
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [user, authChecked, reload]);

	return (
		<div>
			<Nav />

			<div className="flex h-[100%] bg-teal-50 items-center justify-center">
				<div className="flex flex-col gap-5 p-8 bg-white h-screen w-screen max-h-[100%] md:w-[70vw] border-r-1 border-l-1 border-gray-200">
					<p className="text-lg font-semibold flex items-center justify-between">
						Your saved internships...
						<BookmarkOutlined />
					</p>
					<button
						className="w-max flex items-center font-semibold text-sm gap-1 text-gray-500"
						onClick={fetchData}
					>
						<Refresh fontSize="24px" /> Refresh
					</button>
					{loading ? (
						<CircularProgress />
					) : (
						<>
							{info.map((item, index) => {
								if (!includes.includes(item.internship.id)) {
									includes.push(item.internship.id);

									return (
										<div
											className="flex flex-col gap-1 w-full"
											key={item.internship.id}
										>
											<InternshipCard
												key={index}
												company={
													item.employers.established
												}
												location={
													item.employers.location
												}
												desc={
													item.employers.description
												}
												image={item.employers.logo}
												title={item.internship.title}
												type={item.internship.workType}
												pay={item.internship.stipend}
												duration={
													item.internship.duration
												}
												id={item.internship.id}
												fromSaved={true}
												fetchFuncSaved={fetchData}
												reload={setReload}
												rval={reload}
											/>
										</div>
									);
								} else {
									return null;
								}
							})}
						</>
					)}
				</div>
			</div>

			<Chats />
		</div>
	);
}
