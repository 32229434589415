import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export const getEmailIdOfUser = async (userId) => {
    try {
        const db = getFirestore();
        const emailsCollection = collection(db, 'emails');

        const q = query(emailsCollection, where('user', '==', userId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const userEmail = querySnapshot.docs[0].data().email;

            return userEmail;
        } else {
            console.log("User email not found in Firestore");

            return undefined;
        }
    } catch (error) {
        console.error("Error fetching user email:", error);
        return undefined;
    }
};