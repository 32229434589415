import React from 'react';
import Nav from '../../../legals/Nav';
import { Helmet } from 'react-helmet';
import EmpBanner from "../../../logo/EmpBanner.jpg"
import Footer from "./CompanyFooter"
import { Link } from 'react-router-dom';
import Auth from '../../Auth';

const EmployerBanner = () => {
    return (
        <>
        <Helmet>
            <html lang="en"/>
                <title>
                    Register your Company | Hire interns | AccessCareer
                </title>
                <meta
                    name="description"
                    content={"Register your company on AccessCareer and start Hiring."}
                />
                <meta
                    name="keywords"
                    content="Internship, posting, register, interns, hire"
                />
                <meta
                    http-equiv="Content-Type"
                    content="text/html;charset=UTF-8"
                />

        </Helmet>
        <Nav />

        {/* Main Banner Section */}
        <div className="flex flex-col md:flex-row justify-between items-center bg-blue-100 p-6 md:p-10 rounded-lg shadow-lg h-auto md:h-[70vh]">
            {/* Left Side - Text and Buttons */}
            <div className="flex flex-col justify-center items-start w-full md:w-1/2 mb-6 md:mb-0">
                <h1 className="text-3xl md:text-4xl font-bold text-blue-700 mb-4">
                    Are you hiring?
                </h1>
                <p className="text-lg md:text-xl text-gray-700 mb-4">
                    Post your career opportunities on AccessCareer and connect with a wide range of talented students and graduates. Start building your future team today!
                </p>

                <p className="text-md md:text-lg text-gray-600 mb-6">
                    At AccessCareer, we’re committed to connecting you with talented students and recent graduates eager to start their careers. 
                    Posting your opportunities is simple and efficient, enabling you to find the right fit for your organization.
                </p>
                <div className="flex flex-col md:flex-row gap-4 w-full">
                    <button className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition-all w-full md:w-auto">
                        <Link to={"/auth"}>Employer Login</Link>
                    </button>
                    <button className="bg-white text-blue-600 border-2 border-blue-600 py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition-all w-full md:w-auto">
                        <Link to={"/register/se/employer"}>Register company</Link>
                    </button>
                </div>
            </div>

            {/* Right Side - Image */}
            <div className="w-full md:w-1/2 flex justify-center items-center">
                <div className="w-[80%] md:w-[90%] h-[60%] md:h-[90%] bg-blue-300 rounded-full">
                    <img src={EmpBanner} alt="Hire-Banner" className="rounded-full" />
                </div>
            </div>
        </div>

        {/* Employer Rules Section */}
        <div className="bg-white p-6 md:p-8 mt-10 shadow-lg rounded-lg max-w-full md:max-w-5xl mx-auto mb-24 ">
            <h2 className="text-2xl md:text-3xl font-bold text-blue-600 mb-4 md:mb-6">Employer Guidelines & Rules</h2>
            <ul className="list-disc pl-4 md:pl-6 text-gray-700 text-md md:text-lg">
                <li className="mb-4">
                    Ensure that your internship/job postings are accurate and provide detailed information about the role, requirements, and expectations.
                </li>
                <li className="mb-4">
                    Do not post duplicate or misleading job descriptions. Each opportunity must be unique and adhere to the company's legitimate hiring needs.
                </li>
                <li className="mb-4">
                    Employers must comply with all applicable labor laws and regulations, including fair compensation and work conditions.
                </li>
                <li className="mb-4">
                    Provide a respectful and professional environment for all interns and employees during the recruitment and employment process.
                </li>
                <li className="mb-4">
                    AccessCareer reserves the right to remove any job postings that violate our platform’s policies or terms of service.
                </li>
                <li className="mb-4">
                    Employers are responsible for responding to applicants promptly and providing feedback on the application process.
                </li>
            </ul>
        </div>

        <Footer/>
    </>
);

};

export default EmployerBanner;
