import React from "react";

import { LuInstagram, LuLinkedin } from "react-icons/lu";
import { FaXTwitter } from "react-icons/fa6";

export default function AdminFooter() {
  return (
    <>
      <div className="bottom-0 fixed flex flex-col justify-center items-center w-screen bg-p3 text-white p-1">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 w-full">
          <div className="flex-end flex flex-col w-screen p-4 mt-3">
            <div className="flex gap-3">
              <a href="" target="_blank">
                <LuInstagram size={20} />
              </a>
              <a href="" target="_blank">
                <LuLinkedin size={20} />
              </a>
              <a href="" target="_blank">
                <FaXTwitter size={20} />
              </a>
            </div>
            <p className="text-end">&#169;AccessCareer</p>
          </div>
        </div>
      </div>
    </>
  );
}
