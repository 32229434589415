import "./App.css";

import logo_color from "./logo/color.png";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { onAuthStateChanged } from "firebase/auth";

import Home from "./Pages/Home";
import Auth from "./Pages/Auth";
import Internships from "./Pages/Internships";
import Dashboard from "./Pages/Company/Dashboard";
import Applications from "./Pages/Company/Applications";
import NewUser from "./Pages/NewUser";
import { auth } from "./Config/firebase";
import Resume from "./Pages/Resume";
import Internship from "./Pages/Internship";
import NewInternship from "./Pages/Company/NewInternship";
import ProtectedRouteStu from "./Utils/ProtectedRouteStu";
import ProtectedRouteEmp from "./Utils/ProtectedRouteEmp";
import Saved from "./Pages/Saved";
import Query from "./Pages/Query";
import StuResume from "./Pages/Company/StuResume";
import MyApplications from "./Pages/MyApplications";
import Chat from "./Pages/Chat";
import Profile from "./Pages/Profile/Profile";
import ViewProfile from "./Pages/Company/ViewProfile";
import Explore from "./Pages/Company/Explore";
import Tnc from './legals/Tnc';
import PrivacyPolicy from './legals/PrivacyPolicy';
import AboutUs from "./legals/AboutUs";
import Legals from "./legals/Legals";
import EmployerBanner from "./Pages/Company/Components/CompanyLoginBanner";

function App() {
    const [user, setUser] = useState(null); // Initially, user is null
    const [loading, setLoading] = useState(true); // Loading state

    // Firebase Auth state observer
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setLoading(false); // Stop loading once we get the auth status
        });

        // Cleanup subscription
        return () => unsubscribe();
    }, []);

    if (loading) {
        // Display loading message while auth state is being checked
        return <div>Loading...</div>;
    }

    const Page404 = () => {
        return (
            <>
                <Helmet>
                    <title>AccessCareer - 404 - Page Not Found</title>
                </Helmet>

                <div className="text-center flex flex-col items-center justify-center w-screen h-screen select-none">
                    <h1 className="mb-4 text-6xl font-semibold text-p2">404</h1>
                    <p className="mb-4 text-lg text-p3">
                        Oops! Looks like you're lost.
                    </p>
                    <div className="animate-none flex flex-col items-center">
                        <img
                            src={logo_color}
                            className="max-w-[5rem] drop-shadow-md"
                            alt="AccessCareer"
                        />
                        <p className="text-sm font-semibold select-none">
                            AccessCareer
                        </p>
                    </div>
                    <p className="mt-4 text-p3">
                        Let's get you back{" "}
                        <a
                            href="/"
                            className="text-blue-500"
                        >
                            home
                        </a>
                        .
                    </p>
                </div>
            </>
        );
    };

    return (
        <BrowserRouter>
            <Routes>
                {/* Public routes */}
                <Route
                    path="/"
                    element={<Home />} // Publicly accessible homepage
                />
                <Route
                    path="/home"
                    element={<Home />} // Publicly accessible route
                />
                <Route
                    path="/auth"
                    element={<Auth />} // Auth route (login/register)
                />
                <Route
                    path="/register/se/:type"
                    element={<NewUser />} // Registration route
                />
                <Route
                    path="/internships"
                    element={<Internships />} // Public internships page
                />

                {/* Protected Student Routes */}
                <Route
                    path="/resume"
                    element={
                        <ProtectedRouteStu>
                            <Resume />
                        </ProtectedRouteStu>
                    }
                />
                <Route
                    path="/saved"
                    element={
                        <ProtectedRouteStu>
                            <Saved />
                        </ProtectedRouteStu>
                    }
                />
                <Route
                    path="/applications"
                    element={
                        <ProtectedRouteStu>
                            <MyApplications />
                        </ProtectedRouteStu>
                    }
                />
                <Route
                    path="/view/:id"
                    element={
                        <ProtectedRouteStu>
                            <Internship />
                        </ProtectedRouteStu>
                    }
                />
                <Route
                    path="/query"
                    element={
                        <ProtectedRouteStu>
                            <Query />
                        </ProtectedRouteStu>
                    }
                />
                <Route
                    path="/c/:id"
                    element={
                        <ProtectedRouteStu>
                            <Chat />
                        </ProtectedRouteStu>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <ProtectedRouteStu>
                            <Profile />
                        </ProtectedRouteStu>
                    }
                />

                {/* Protected Employer Routes */}
                <Route
                    path="/employer/dashboard"
                    element={
                        <ProtectedRouteEmp>
                            <Dashboard />
                        </ProtectedRouteEmp>
                    }
                />
                <Route
                    path="/employer/applications/:id"
                    element={
                        <ProtectedRouteEmp>
                            <Applications />
                        </ProtectedRouteEmp>
                    }
                />
                <Route
                    path="/employer/internship/new"
                    element={
                        <ProtectedRouteEmp>
                            <NewInternship />
                        </ProtectedRouteEmp>
                    }
                />
                <Route
                    path="/resume/:id"
                    element={<StuResume />}
                />
                <Route
                    path="/employer/c/:id"
                    element={
                        <ProtectedRouteEmp>
                            <Chat />
                        </ProtectedRouteEmp>
                    }
                />
                <Route
                    path="/profile/:id"
                    element={
                        <ProtectedRouteEmp>
                            <ViewProfile />
                        </ProtectedRouteEmp>
                    }
                />
                <Route
                    path="/employer/explore"
                    element={
                        <ProtectedRouteEmp>
                            <Explore />
                        </ProtectedRouteEmp>
                    }
                />
                <Route
                    path="/employer/hiring"
                    element={
                            <EmployerBanner />
                    }
                />

                {/* 404 Page Not Found */}
                <Route
                    path="*"
                    element={<Page404 />}
                />

                {/* Legals */}
                {/* Uncomment the following if legals pages are needed */}
                {/* 
                <Route 
                    path="/legals"
                    element={<Legals />}
                />
                <Route
                    path="/tnc"
                    element={<Tnc />}
                />
                <Route
                    path="/privacy"
                    element={<PrivacyPolicy />}
                />*/}
                <Route 
                    path="/aboutus"
                    element={<AboutUs />}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
