import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { HiDotsHorizontal } from "react-icons/hi";
import { Link } from "react-router-dom";
import { doc, updateDoc, arrayUnion, arrayRemove, deleteDoc, getDoc, getDocs, query, collection, where } from "firebase/firestore";
import { db, auth } from '../../Config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import defaultUser from "../../Assets/user.jpg";

const PostCard = ({ id, userId, image, content, images, from, postBy, likes, timestamp }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [currentLikes, setCurrentLikes] = useState(likes || []);
  const [user] = useAuthState(auth);
  const [userNameFetched, setUserNameFetched] = useState('');
  const [userPhotoURL, setUserPhotoURL] = useState(defaultUser);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const uid = user?.uid;
        try {
        const basicDetailsQuery = await getDocs(
          query(
            collection(db, "other_details"),
            where("student", "==", uid)
          )
        );
        basicDetailsQuery.forEach((doc) => {
          const basicDetailsData = doc.data();
          setUserNameFetched(basicDetailsData.name);
        });
  
          // Fetch the photo from userLogos collection using the user ID
          const photoDoc = await getDoc(doc(db, "userLogos", user.uid));
          if (photoDoc.exists()) {
            const photoData = photoDoc.data();
            setUserPhotoURL(photoData.photoURL);
          } else {
            console.error('No photo found in userLogos');
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      }
    };
  
    fetchUserData();
  }, [user]);
  

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLike = async () => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    const userId = user.uid;
    const postRef = doc(db, 'posts', id);

    try {
      if (currentLikes.includes(userId)) {
        setCurrentLikes(currentLikes.filter(id => id !== userId));
        await updateDoc(postRef, {
          likes: arrayRemove(userId)
        });
      } else {
        setCurrentLikes([...currentLikes, userId]);
        await updateDoc(postRef, {
          likes: arrayUnion(userId)
        });
      }
    } catch (error) {
      console.error('Error toggling like:', error);
      setCurrentLikes(currentLikes);
    }
  };

  const handleDelete = async () => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    const postRef = doc(db, 'posts', id);

    try {
      await deleteDoc(postRef);
      console.log('Post deleted successfully');
      window.location.reload(); // Refresh the page

      // Optionally, you can remove the post from the UI after deleting
      // setPosts((prevPosts) => prevPosts.filter(post => post.id !== id));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const formattedTimestamp = new Date(timestamp?.seconds * 1000).toLocaleString('en-IN', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return (
    <div className={`relative flex flex-col gap-2 md:p-8 m-2 bg-white shadow-md p-4 rounded-md min-w-full md:min-w-[40rem] max-w-screen-sm items-center`}>
      <div className="flex w-full justify-between items-center">
        <div className="flex gap-2 items-center">
          <img src={userPhotoURL || defaultUser} alt="User profile" style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            objectFit: "contain"
          }} />
          <Link to={`/resume/${user.uid}`} className='font-bold'>{userNameFetched}</Link>
        </div>
        {from === "p" && (
          <div className="relative">
            <HiDotsHorizontal className='cursor-pointer' color='gray' onClick={toggleMenu} />
            {menuVisible && <Menu onDelete={handleDelete} />}
          </div>
        )}
      </div>
      <p className='p-2 text-left w-full border-1p5 border-gray-200'>
        {content}
      </p>
      {images && images.length > 0 && <ImageCarousel images={images} />}
      <div className="flex w-full justify-between items-center mt-2">
        <button onClick={handleLike} className="text-gray-500 hover:text-gray-700">Like ({currentLikes.length})</button>
        <p className="text-gray-600 text-sm">{formattedTimestamp}</p>
      </div>
    </div>
  );
};

const Menu = ({ onDelete }) => {
  return (
    <div className="absolute right-0 top-full mt-2 flex flex-col gap-2 p-4 bg-white shadow-md rounded-md w-max z-10">
      <p className="cursor-pointer" onClick={onDelete}>
        Delete Post
      </p>
    </div>
  );
};

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative w-full max-w-full overflow-hidden">
      <div className="relative h-64">
        {images.map((imgSrc, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full transition-transform duration-500 ease-in-out transform rounded-md ${
              index === currentIndex ? 'translate-x-0' : 'translate-x-full'
            } ${index < currentIndex ? 'translate-x-[-100%]' : ''}`}
            style={{
              backgroundImage: `url(${imgSrc})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: "no-repeat"
            }}
          ></div>
        ))}
      </div>
      <button
        onClick={prevSlide}
        className="absolute opacity-50 m-1 top-1/2 left-0 transform -translate-y-1/2 bg-gray-200 text-gray-800 p-2 rounded-full hover:bg-gray-400"
      >
        <FaArrowLeft />
      </button>
      <button
        onClick={nextSlide}
        className="absolute opacity-50 m-1 top-1/2 right-0 transform -translate-y-1/2 bg-gray-200 text-gray-800 p-2 rounded-full hover:bg-gray-400"
      >
        <FaArrowRight />
      </button>
    </div>
  );
};

export default PostCard;
