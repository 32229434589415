import React, { useEffect, useState } from "react";
import Nav from "../Components/Nav";
import Card from "../Components/Card";
import Footer from "../Components/Footer";
import logo from "../logo/color.png"
import { Helmet } from "react-helmet";
import {
    Accordion,
    AccordionDetails,
    AccordionGroup,
    AccordionSummary,
    Button,
    Typography,
} from "@mui/joy";
import { VscLinkExternal } from "react-icons/vsc";
import { Link, useNavigate } from "react-router-dom";
import banner from "../backgrounds/Illustrations/6.jpg";
import banner2 from "../backgrounds/Illustrations/5.png";
import ReviewModal from "../Components/ReviewModal";
import { FaPen } from "react-icons/fa6";
import { db } from "../Config/firebase";
// import { useAuthState } from "react-firebase-hooks/auth";
import { collection, getDocs, orderBy, query, limit, where } from "firebase/firestore";
// import useUser from "../Utils/useUser";
import Chats from "../Components/Chats";
import { useHistory } from "react-router-dom";
import { Input } from "@mui/joy";
import { jobProfiles } from "../Exports";
import { InputLabel, Select, MenuItem } from "@mui/material";
import Resume from "./Resume";
import x_logo from "../logo/X_logo.png"
import Li_logo from "../logo/Linkedin.png"

export default function Home() {
    const [reviewModalState, setReviewModalState] = useState(false);
    const [name, setName] = useState();
    const [internships, setInternships] = useState([]);
    // const [user_fromHook] = useAuthState(auth);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const [user, authChecked] = useUser();
    const [profile, setProfile] = useState('');
    const [location, setLocation] = useState('');
    const [pf, setPf] = useState(""); 
    const [search, setSearch] = useState(""); // State for search input
    const [selectedProfile, setSelectedProfile] = useState('');
    const [showVideo, setShowVideo] = useState(false); // State to toggle video

    // const fetchDetails = async () => {
    //     const ref = collection(db, "other_details");

    //     if (user_fromHook) {
    //         setLoading(true);
    //         const userid = await user?.uid;

    //         try {
    //             const docs = await getDocs(
    //                 query(ref, where("student", "==", userid))
    //             );

    //             docs.forEach((doc) => {
    //                 setName(doc.data()["name"]);
    //             });
    //         } catch (e) {
    //             console.log(e);
    //         } finally {
    //             setLoading(false);
    //         }
    //     }
    // };
    const handlePfChange = (e) => {
    setPf(e.target.value);
    };

  // Navigate to internships page with selected profile
    const handleSearch = () => {

        navigate('/internships', {
            state: { profile: pf },
        });
        console.log("Selected profile: " + pf)
    };

    const filteredProfiles = jobProfiles.filter((profile) =>
    profile.toLowerCase().includes(search)
    );
    
    const fetchInternships = async () => {
        const ref = collection(db, "internships");

        try {
            const q = query(ref, orderBy("applicants", "desc"), limit(6));
            const docsSnapshot = await getDocs(q);

            const internshipsPromises = docsSnapshot.docs.map(async (doc) => {
                const employerValue = doc.data().employer;
                const employerQuery = query(
                    collection(db, "employers"),
                    where("company", "==", employerValue)
                );
                const employerSnapshot = await getDocs(employerQuery);

                let logo = null;
                employerSnapshot.forEach((comp) => {
                    logo = comp.data().logo;
                });

                return {
                    id: doc.id,
                    ...doc.data(),
                    image: logo
                };
            });

            const internshipsList = await Promise.all(internshipsPromises);
            setInternships(internshipsList);
        } catch (e) {
            console.error('Error fetching internships:', e);
        }
    };

    useEffect(() => {
        fetchInternships();
    }, []);

    return (
        <>
            <Helmet>
                <html lang="en"/>
                <title>
                    Access Career | Home | Internship/Career opportunities
                </title>
                <meta
                    name="description"
                    content={`
                        Discover work-from-home internships and career opportunities for students at AccessCareer.in. Find the perfect internship to kickstart your career. Explore flexible roles and connect with top companies offering opportunities for students and fresh graduates.
                        ${
                            internships && (
                                internships.map(internship => {
                                    return internship.title, internship.workType;
                                })
                            )
                        }
                    `}
                />
                <meta
                    name="keywords"
                    content="Software engineering, Mechanical engineering, Electrical engineering, Chemical engineering, Civil engineering, Biomedical engineering, Data science, Cybersecurity, Artificial intelligence, Machine learning, Cloud computing, Software development, Finance, Marketing, Management, Human resources, Operations, Consulting, Graphic design, Product design, UX/UI design, Web design, Fashion design, Interior design, Internship, College internships, Recent graduate internships, Entry-level jobs, Job search, Career development"
                />
                <meta
                    http-equiv="Content-Type"
                    content="text/html;charset=UTF-8"
                />

                <meta name="google-site-verification" content="uodKSlJO1Uht-QVRJqKQfHyhEvR2C5RKzlDpeblDuqg" />

                <link rel="canonical" href="https://www.accesscareer.in" />
    
            </Helmet>

            <Nav />

            <div
                className="flex justify-center items-center h-auto md:h-[72vh] relative overflow-hidden"
                style={{
                    width: "100vw",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url('${banner}')`,
                    boxSizing: "border-box",
                    backgroundAttachment: "fixed",
                }}
                aria-label="Internship banner-home"
            >
                <div 
                    className="absolute inset-0 bg-gradient-to-b from-transparent to-white"
                    style={{
                        background: "linear-gradient(to bottom, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 95%)"
                    }}
                />
                <div className="flex flex-col justify-center items-center relative z-10 px-4" >
                    <div className="flex gap-1 justify-center items-center backdrop-blur-sm rounded-md p-2">                        
                </div>

            <div className="flex flex-col gap-4">
                {//<div className="flex flex-col items-center mt-0 bg-white pt-3 pr-2 pl-2 pb-3 rounded-lg shadow-md border border-gray-200 max-w-3xl mx-auto">
                }
                <label htmlFor="profile" className="text-gray-800 font-bold text-xl sm:text-2xl mb-3 text-center sm:text-left sm:ml-44">
                <h1>Internship Profile</h1>
                </label>
                <div className="relative w-full">
                {/* Profile Selector */}
                <select
                    id="profile"
                    value={pf}
                    onChange={handlePfChange}
                    className="border border-gray-300 rounded-full pr-4 pl-4 py-3 w-full text-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all shadow-sm sm:pr-60 sm:pl-4"
                    >
                    <option value="">Select a profile</option>
                    {jobProfiles.map((profile, index) => (
                        <option key={index} value={profile}>
                        {profile}
                        </option>
                    ))}
                    </select>
                {/* Search Button */}
                <button
                    onClick={handleSearch}
                    className="absolute right-0 top-0 h-full bg-blue-600 text-white rounded-r-full px-6 py-3 text-lg font-semibold hover:bg-blue-700 transition-all"
                >
                    Search
                </button>
                </div>
            {//</div>
}
        </div>
        <a
        href="/resume"
        target="resume"
        rel="noopener noreferrer"
        className="border border-gray-300 rounded-full px-4 py-2 bg-blue-500 text-white focus:outline-none focus:ring-2  font-medium hover:bg-blue-400 hover:text-black text-sm inline-block mt-3"
        >
        View Your Resume
        </a>
        </div>
            </div>

            <p className="text-center font-semibold text-xl mt-10">
                Recently Posted Internships
            </p>
            <div className="flex flex-col justify-center items-center flex-wrap w-screen p-4 drop-shadow-sm mt-2">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14">
                {internships && internships.length > 0 ? (
                    internships.map((internships) => {
                        var rept = [];

                        if (!rept.includes(internships.id)) {
                            return (
                                <Card
                                    key={internships.id}
                                    title={internships.title}
                                    image={internships.image ? internships.image : logo}
                                    company={internships.company}
                                    type={internships.workType}
                                    pay={internships.stipend}
                                    duration={internships.duration}
                                    posted={internships.posted}
                                    id={internships.id}
                                />
                            );
                        }

                        rept.push(internships.id);
                    })
                ) : (
                    <p>No internships available</p>
                )}
                </div>

                <Link
                    to={"/internships"}
                    className="p-3 m-5 flex justify-center items-center border-2 border-p2 text-p2 font-sans hover:bg-p2 hover:text-white transition-all"
                >
                    <p className="text-lg">View More</p>
                </Link>

                <div class="relative w-full flex justify-center items-center">
                    <iframe 
                        src="https://www.youtube.com/embed/wuxw6D0pglM?si=z-1w8C2u3CychX1G" 
                        title="AccessCareer Promo" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen
                        class="w-[90%] h-[25vh] md:w-[70%] md:h-[50vh] lg:w-[50%] lg:h-[50vh] rounded-lg shadow-md">
                    </iframe>
                </div>
                
                <div className="flex flex-col justify-center items-center w-full bg-gray-100 p-6 mt-10">
                <h2 className="text-3xl font-semibold mb-4">
                    Why <span className="text-blue-500">AccessCareer?</span>
                </h2>            <p className="text-lg text-gray-700 mb-6">
                AccessCareer offers unique advantages that set us apart in the internship landscape:
            </p>
            <div className="flex flex-wrap justify-between w-full max-w-3xl">
                <div className="w-full md:w-1/3 mb-4 pr-9">
                    <h3 className="text-xl font-semibold mb-2">Wide Range of Opportunities:</h3>
                    <ul className="list-disc list-inside text-lg text-gray-700">
                        <li>Diverse internship roles across multiple industries.</li>
                        <li>Access to both remote and in-person opportunities.</li>
                        <li>Regularly updated listings to keep you informed.</li>
                    </ul>
                </div>
                <div className="w-full md:w-1/3 mb-4 pr-9">
                    <h3 className="text-xl font-semibold mb-2">Completely Free:</h3>
                    <ul className="list-disc list-inside text-lg text-gray-700">
                        <li>No registration or application fees.</li>
                        <li>Unlimited access to browse and apply for internships.</li>
                        <li>Transparent platform with no hidden costs.</li>
                    </ul>
                </div>
                <div className="w-full md:w-1/3 mb-4 pr-9">
                    <h3 className="text-xl font-semibold mb-2">User-Friendly Platform:</h3>
                    <ul className="list-disc list-inside text-lg text-gray-700">
                        <li>Intuitive design for easy navigation.</li>
                        <li>Advanced filtering options to find suitable internships.</li>
                        <li>Quick application process to enhance your chances of landing a role.</li>
                    </ul>
                </div>
                </div>
            </div>

                {/*Hiring banner*/}
                <div className="w-full text-white pt-8 pr-8 pb-8 pl-8 mt-10 bg-blue-500">
                    <div className="flex flex-col items-center justify-center">
                        <h2 className="text-3xl font-bold mb-4">Are You Hiring?</h2>
                        <p className="text-lg mb-6">
                            Post your internships on AccessCareer and reach thousands of students and fresh graduates.
                        </p>
                        <Link
                            to="https://accesscareer.in/employer/hiring"
                            className="bg-white text-blue-500 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition-all"
                        >
                            Post an Internship
                        </Link>
                    </div>
                </div>
                {/*Hiring banner-end*/}
                <div className="flex flex-col md:flex-row justify-evenly items-center">
                    <img
                        src={banner2}
                        style={{
                            width: "30rem",
                        }}
                        className="drop-shadow-lg"
                        alt="Internship banner-home-2"
                    />
                    {reviewModalState ? (
                        <>
                            <div className="z-50 ">
                                <ReviewModal
                                    setModalState={setReviewModalState}
                                    modalState={reviewModalState}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <Button
                                className="flex items-center gap-1"
                                onClick={() => {
                                    setReviewModalState(!reviewModalState);
                                }}
                            >
                                <FaPen />
                                Write a Review
                            </Button>
                        </>
                    )}
                </div>

        <div className="flex flex-col justify-center items-center w-full bg-gray-100 p-6 mt-10">
            <div className="bg-white p-6 rounded-lg shadow-md max-w-3xl">
                <h2 className="text-3xl font-semibold mb-4">About Us</h2>
                <p className="text-lg text-gray-700">
                    AccessCareer is a comprehensive platform designed to bridge the gap between students, fresh graduates, and valuable internship opportunities. Whether you're looking to gain hands-on experience in fields like software development, finance, marketing, or design, AccessCareer simplifies the search. Our user-friendly platform allows you to browse and apply for internships that align with your career aspirations, completely free of charge.
                </p>
            </div>
        </div>

                <div className="w-full md:w-6/12 mt-5">
                    <p className="font-semibold text-lg">FAQ</p>
                    <AccordionGroup>
                        <Accordion>
                            <AccordionSummary>
                                <Typography>What is AccessCareer?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    AccessCareer is an online platform that
                                    connects students with internship
                                    opportunities. We aim to be your anchor to a
                                    bright future by helping you find valuable
                                    internships that align with your career
                                    goals.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary>
                                <Typography>
                                    Are there any fees for using AccessCareer
                                    as a student?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    No, AccessCareer is completely free for
                                    students. You can browse, search for
                                    internships, and apply without any charges.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary>
                                <Typography>
                                    How do I search for internships on
                                    AccessCareer?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    You can start by typing your desired skills
                                    and job role into the searchbar or view all
                                    the available internships in the internships
                                    section.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary>
                                <Typography>
                                    Can I apply for multiple internships?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes, you can apply for as many internships
                                    as you want. We encourage you to explore
                                    various opportunities to enhance your skills
                                    and experiences.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                    <Link className="text-p2 float-right flex items-center gap-2 hover:underline">
                        Read All <VscLinkExternal />
                    </Link>
                </div>
            </div>

        <div className="flex justify-center items-center gap-2 mb-8">
            <a
                href="https://x.com/AccessCareer01?t=ucNh94zlHc7nC8zT3Rs4cA&s=09"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center bg-gray-100 border border-gray-300 px-4 py-2 rounded-full hover:bg-blue-500 hover:text-white transition-all shadow-md"
                >
                <span className="font-semibold">Follow us on</span>
                <img
                    src={x_logo}
                    alt="X logo"
                    style={{ width: "20px", height: "20px", marginLeft: "8px" }}
                />
            </a>
            <a
                href="https://www.linkedin.com/company/accesscareer"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center bg-gray-100 border border-gray-300 px-4 py-2 rounded-full hover:bg-blue-500 hover:text-white transition-all shadow-md"
                >
                <span className="font-semibold">Follow us on</span>
                <img
                    src={Li_logo}
                    alt="X logo"
                    style={{ width: "20px", height: "20px", marginLeft: "8px" }}
                />
            </a>
        </div>

        <Footer />
            <Chats />
        </>
    );
}

