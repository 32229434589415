import React, { useState } from 'react';
import { MdCancel } from 'react-icons/md';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage, auth } from '../../Config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const emojis = ["👍", "👏", "💼", "📈", "📅", "✍️"];

export default function NewPostCard({ posts, setPosts }) {
    const [chars, setChars] = useState("");
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [images, setImages] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [user] = useAuthState(auth); // Use auth state to get the current user

    const insertEmoji = (emoji) => {
      setChars(chars + emoji);
      setShowEmojiPicker(false);
    }

    const handleImageChange = (e) => {
      const files = Array.from(e.target.files);
      setImageFiles(prevFiles => [...prevFiles, ...files]);
      const imageUrls = files.map(file => URL.createObjectURL(file));
      setImages(prevImages => [...prevImages, ...imageUrls]);
    }

    const removeImage = (index) => {
      setImages((prevImages) => [...prevImages.slice(0, index), ...prevImages.slice(index + 1)]);
      setImageFiles((prevFiles) => [...prevFiles.slice(0, index), ...prevFiles.slice(index + 1)]);
    };

    const handlePost = async () => {
      if (chars.trim() === "") {
        var postEle = document.getElementById("post");

        postEle.style.border = "1px solid red";

        setInterval(() => {
          postEle.style.border = "1.5px solid #E5E7EB";
        }, 1000);
      }

      if (!user) {
        console.error('User not authenticated');
        return;
      }

      const post = {
        userId: user.uid,
        content: chars,
        images: [],
        timestamp: serverTimestamp(),
        likes: [],
        comments: []
      };

      try {
        const uploadPromises = imageFiles.map(async (file) => {
          const storageRef = ref(storage, `posts/${file.name}`);
          await uploadBytes(storageRef, file);
          const downloadURL = await getDownloadURL(storageRef);
          return downloadURL;
        });

        const imageUrls = await Promise.all(uploadPromises);
        post.images = imageUrls;

        const docRef = await addDoc(collection(db, "posts"), post);

        const optimisticPost = {
          ...post,
          id: docRef.id // Assign the ID generated by Firestore
        };

        // Update the posts state to prepend the new post
        setPosts([optimisticPost, ...posts]);

        setChars("");
        setImages([]);
        setImageFiles([]);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    };

    return (
      <div className="flex flex-col p-4 md:p-6 bg-white shadow-md rounded-md min-w-full md:min-w-[40rem] max-w-screen-sm items-center relative">
        <textarea className='w-full border-1.5 border-gray-300 outline-none rounded-md p-3 border-1p5' rows={4} id='post' placeholder="Got an update? Let the employer's know!" maxLength={250} 
          value={chars}
          onChange={(e) => setChars(e.target.value)}
          style={{ resize: "none" }}
        ></textarea>

        <div className="flex justify-evenly gap-2 items-center w-full mt-2">
          {images.length > 0 && (
            images.map((img, index) => (
              <div key={index} className="image-container relative w-full md:w-[10em] mt-2">
                <img key={index} src={img} alt={`Upload ${index}`} className="w-full h-full object-contain rounded-md" style={{
                  aspectRatio: "3/2"
                }} />
                <button
                  className="absolute top-0 right-0 p-1 bg-white rounded-full hover:bg-gray-200 focus:outline-none m-2"
                  onClick={() => removeImage(index)}
                >
                  <MdCancel />
                </button>
              </div>
            ))
          )}
        </div>

        {/* Emoji Picker */}
        {showEmojiPicker && (
          <div className="absolute bg-white border rounded-full shadow p-2 flex gap-2 items-center justify-center">
            {emojis.map(emoji => (
              <span key={emoji} className="cursor-pointer text-xl" onClick={() => insertEmoji(emoji)}>{emoji}</span>
            ))}
          </div>
        )}

        {/* Icons */}
        <div className="icons flex text-gray-500 m-2 w-full">
          <input type="file" accept='image/*' multiple id='imagePickInput' style={{ display: "none" }} 
            onChange={handleImageChange} 
          />
          <svg className="mr-2 cursor-pointer hover:text-gray-700 border rounded-full p-1 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <svg className="mr-2 cursor-pointer hover:text-gray-700 border rounded-full p-1 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => document.getElementById("imagePickInput").click()}>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
          </svg>
          <div className="count ml-auto text-gray-400 text-xs font-semibold">{chars.length}/250</div>
        </div>
        
        {/* Buttons */}
        <div className="buttons flex w-full">
          <div className="btn border border-gray-300 p-1 px-4 font-semibold cursor-pointer text-gray-500 ml-auto" onClick={() => setChars("")}>Cancel</div>
          <div className="btn border border-indigo-500 p-1 px-4 font-semibold cursor-pointer text-gray-200 ml-2 bg-indigo-500" onClick={handlePost}>Post</div>
        </div>
      </div>
    );
}