import { Button, Checkbox, Input, MenuItem, Option, Select } from "@mui/joy";
import { InputLabel } from "@mui/material";
import React from "react";
import { jobProfiles } from "../Exports";

export default function FilterForm({
  setProfile,
  setLocation,
  wfh,
  hybrid,
  inOffice,
  setWfh,
  setHybrid,
  setInOffice,
  stipend,
  setStipend,
  duration,
  setDuration,
  pf,
  fetchData,
}) {
  const handlePfChange = (e, val) => {
    setProfile(val);
  };

  return (
    <>
      <form
        className="flex flex-col gap-4"
        onSubmit={(e) => {
          e.preventDefault();
          fetchData();
        }}
      >
        <div>
          <InputLabel id="duration-label">
            Profile {pf && "(" + pf + ")"}
          </InputLabel>
          <Select label="profile" onChange={handlePfChange}>
            <Option value={""}>Any</Option>
            {jobProfiles.map((profile, index) => {
              return (
                <Option key={index} value={profile}>
                  {profile}
                </Option>
              );
            })}
          </Select>
        </div>
        <div>
          <label htmlFor="Location">Location</label>
          <Input
            placeholder="Eg. Hyderabad"
            id="Location"
            onChange={(e) => {
              setLocation(e.target.value);
            }}
          />
        </div>
        <div className="gap-2 flex flex-col">
          <Checkbox
            label="Work from home"
            onChange={(e) => {
              setWfh(e.target.checked);
            }}
          />
          <Checkbox
            label="Hybrid"
            onChange={(e) => {
              setHybrid(e.target.checked);
            }}
          />
          <Checkbox
            label="In-Office"
            onChange={(e) => {
              setInOffice(e.target.checked);
            }}
          />
        </div>
        <div className="">
          {/* <InputLabel id="stipend-label">
            Stipend ({stipend === "" || stipend === 0 ? "Any" : stipend + "+"})
          </InputLabel>
          <Select
            labelId="stipend-label"
            id="stipend-select"
            label="Stipend"
            onChange={(e, val) => {
              setStipend(val);
            }}
          >
            <Option value="0">0K+</Option>
            <Option value="2000">2K+</Option>
            <Option value="4000">4K+</Option>
            <Option value="6000">6K+</Option>
            <Option value="8000">8K+</Option>
            <Option value="10000">10K+</Option>
          </Select> */}

          <InputLabel id="duration-label">
            Work Duration ({duration}months max)
          </InputLabel>
          <Select
            labelId="duration-label"
            id="duration-select"
            label="Work Duration (Max)"
            onChange={(e, val) => {
              setDuration(val);
            }}
          >
            <Option value="1">1 Month</Option>
            <Option value="2">2 Months</Option>
            <Option value="3">3 Months</Option>
            <Option value="4">4 Months</Option>
            <Option value="5">5 Months</Option>
            <Option value="6">6 Months</Option>
          </Select>
        </div>
        <Button type="submit">Apply Filters</Button>
      </form>
    </>
  );
}
