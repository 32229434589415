import React, { useEffect, useState } from "react";
import Nav from "../Components/Nav";
import {
	Input,
	Button,
	Textarea,
	Alert,
	LinearProgress,
	CircularProgress,
} from "@mui/joy";
import { Box, Modal, Typography } from "@mui/material";
import {
	AccountTree,
	ArrowLeftSharp,
	Cancel,
	Close,
	Edit,
	FiberManualRecord,
	LinkedIn,
	Mail,
	OpenInNew,
	Person,
	PlusOne,
	Refresh,
	School,
	ViewAgenda,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { auth, db, storage } from "../Config/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
	addDoc,
	arrayRemove,
	collection,
	deleteDoc,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
	getFirestore,
} from "firebase/firestore";
import { updateEmail, sendEmailVerification, getAuth } from "firebase/auth";
import { BsPlus } from "react-icons/bs";
import useUser from "../Utils/useUser";
import { Helmet } from "react-helmet";
import Chats from "../Components/Chats";
import { MdOutlineDriveFolderUpload } from "react-icons/md";

export default function Resume() {
	const navigate = useNavigate();
	const storage = getStorage();
	const [user, authChecked] = useUser();

	const [open, setOpen] = useState(false);
	const [editAboutMe, setEditAboutMe] = React.useState(false);
	const [editEducation, setEditEducation] = React.useState(false);
	const [editSkills, setEditSkills] = React.useState(false);
	const [editExperience, setEditExperience] = React.useState(false);
	const [editPortfolio, setEditPortfolio] = React.useState(false);
	const [editEmail, setEditEmail] = useState();
	const [resumeURL, setResumeURL] = useState("");
	const [editResume, setEditResume] = useState(false);
	const [newResume, setNewResume] = useState(null);



	const [loading, setLoading] = useState(false);
	const [editLoading, setEditLoading] = useState(false);
	const [alert, setAlert] = useState();

	const [nName, setNname] = useState();
	const [nPhone, setNPhone] = useState();
	const [nLinkedin, setNLinkedin] = useState();
	const [nSummary, setNSummary] = useState(false);
	const [nEmail, setNemail] = useState();

	const [institute, setInstitute] = useState();
	const [course, setCourse] = useState();
	const [from, setFrom] = useState();
	const [to, setTo] = useState();
	const [CGPA, setCGPA] = useState();

	const [typeInSkill, setTypeInSkill] = useState();

	const [projectTitle, setProjectTitle] = useState();
	const [projectDesc, setProjectDesc] = useState();
	const [projectFrom, setprojectFrom] = useState();
	const [projectTo, setprojectTo] = useState();
	const [references, setRefrences] = useState();

	const [portfolioTitle, setPortFolioTitle] = useState();
	const [portfolioLink, setPortfolioLink] = useState();
	

	const [skill_details, setSkill_details] = useState();
	const [other_details, setOther_details] = useState([]);
	const [education, setEducation] = useState();
	const [experience, setExperience] = useState();
	const [portfolio, setPortFolio] = useState();

	const handleOpen = (modalType) => {
		switch (modalType) {
			case "aboutMe":
				setEditAboutMe(true);
				break;
			case "education":
				setEditEducation(true);
				break;
			case "experience":
				setEditExperience(true);
				break;
			case "portfolio":
				setEditPortfolio(true);
				break;
			case "resume":
				setEditResume(true);
				break;
			default:
				break;
		}
	};

	const handleClose = () => {
		setOpen(false);
		setEditAboutMe(false);
		setEditEducation(false);
		setEditExperience(false);
		setEditPortfolio(false);
		setEditResume(false);
	};

	const fetchData = async () => {
		if (user) {
			const uid = await user?.uid;

			try {
				setLoading(true);

				// Fetch and set basic details
				const basicDetailsQuery = await getDocs(
					query(
						collection(db, "other_details"),
						where("student", "==", uid),
					),
				);

				basicDetailsQuery.forEach((doc) => {
					const basicDetailsData = doc.data();
					setOther_details(basicDetailsData);
					setNname(basicDetailsData.name);
					setNPhone(basicDetailsData.phone);
					setNLinkedin(basicDetailsData.linkedin);
					setNSummary(basicDetailsData.about);
					setNemail(user?.currentUser?.email);
					setResumeURL(basicDetailsData.resumeURL || "");
				});

				// Fetch and set education details
				const educationQuery = await getDocs(
					query(
						collection(db, "education"),
						where("student", "==", uid),
					),
				);

				//Fetch and set experience details
				const educationData = educationQuery.docs.map((doc) => ({
					id: doc.id,
					data: doc.data(),
				}));
				setEducation(educationData);

				// Fetch and set experience details
				const experienceQuery = await getDocs(
					query(
						collection(db, "experience_projects"),
						where("student", "==", uid),
					),
				);

				const experienceData = experienceQuery.docs.map((doc) => ({
					data: doc.data(),
					id: doc.id,
				}));
				setExperience(experienceData);

				// Fetch and set portfolio details
				const portfolioQuery = await getDocs(
					query(
						collection(db, "portfolio"),
						where("student", "==", uid),
					),
				);

				const portfolioData = portfolioQuery.docs.map((doc) => ({
					data: doc.data(),
					id: doc.id,
				}));
				setPortFolio(portfolioData);

				//Fetch and set skills
				const skillsQuery = await getDocs(
					query(
						collection(db, "student_skills"),
						where("student", "==", uid),
					),
				);

				const skillsData = skillsQuery.docs.map((doc) => doc.data());
				setSkill_details(skillsData);
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
			}
		}
	};

	const editBasicDetails = async (e) => {
		e.preventDefault();

		if (
			!nName ||
			!nName.trim() ||
			!nLinkedin ||
			!nLinkedin.trim() ||
			!nSummary ||
			!nSummary.trim() ||
			!nPhone ||
			!nPhone.trim()
		) {
			setAlert("Fill out all the fields");
			return;
		}

		setEditLoading(true);

		try {
			const uid = await user?.uid;

			const docs = await getDocs(
				query(
					collection(db, "other_details"),
					where("student", "==", uid),
				),
			);

			docs.forEach(async (doc) => {
				await updateDoc(doc.ref, {
					name: nName,
					linkedin: nLinkedin,
					about: nSummary,
					phone: nPhone,
				}).then(() => {
					fetchAndReset();
					handleClose();
				});
			});
		} catch (e) {
			console.log(e);
			return;
		} finally {
			setEditLoading(false);
		}
	};

	const addEducation = async (e) => {
		e.preventDefault();

		const ref = collection(db, "education");

		setEditLoading(true);

		try {
			const uid = await user?.uid;

			addDoc(ref, {
				institute: institute,
				major: course,
				from: from,
				to: to,
				CGPA:CGPA,
				student: uid,
			}).then(() => {
				fetchData();
			});
		} catch (e) {
			console.log(e);
		} finally {
			setEditLoading(false);
			handleClose();
		}
	};

	const addProject = async (e) => {
		e.preventDefault();

		const ref = collection(db, "experience_projects");

		setEditLoading(true);

		try {
			const uid = await user?.uid;

			addDoc(ref, {
				title: projectTitle,
				desc: projectDesc,
				from: projectFrom,
				to: projectTo,
				student: uid,
				references: references
			}).then(() => {
				fetchData();
			});
		} catch (e) {
			console.log(e);
		} finally {
			setEditLoading(false);
			handleClose();
		}
	};

	const addPortfolio = async (e) => {
		e.preventDefault();

		const ref = collection(db, "portfolio");

		try {
			const uid = await user?.uid;

			addDoc(ref, {
				title: portfolioTitle,
				link: portfolioLink,
				student: uid,
			}).then(() => {
				fetchData();
			});
		} catch (e) {
			console.log(e);
		} finally {
			setEditLoading(false);
			handleClose();
		}
	};

	const addSkill = async (e) => {
		e.preventDefault();

		setEditLoading(true);

		try {
			const uid = user?.uid;

			const docs = await getDocs(
				query(
					collection(db, "student_skills"),
					where("student", "==", uid),
				),
			);

			docs?.forEach(async (doc) => {
				const existingSkills = doc.data()?.skills || [];

				await updateDoc(doc.ref, {
					skills: [...existingSkills, typeInSkill?.replace("-", " ")],
				});

				fetchData();

				setTypeInSkill();
				setEditSkills(false);
			});
		} catch (e) {
			console.error(e);
		} finally {
			setEditLoading(false);
		}
	};

	const fetchAndReset = () => {
		fetchData();
		resetBasicDetails();
	};

	const handleResumeUpload = async (e) => {
		e.preventDefault();
	
		if (!newResume) {
			setAlert("Please select a resume to upload");
			return;
		}
	
		setEditLoading(true);
	
		try {
			const uid = user?.uid;
			const resumeRef = collection(db, "other_details");
	
			// Create a reference to the storage location
			const resumeFileRef = ref(storage, `resumes/${uid}/${newResume.name}`);
	
			// Upload the file to Firebase storage
			await uploadBytes(resumeFileRef, newResume);
	
			// Get the download URL of the uploaded file
			const resumeFileURL = await getDownloadURL(resumeFileRef);
	
			// Query the Firestore collection to find the user's document
			const docs = await getDocs(query(resumeRef, where("student", "==", uid)));
	
			// Update the document with the new resume URL
			docs.forEach(async (doc) => {
				await updateDoc(doc.ref, {
					resumeURL: resumeFileURL,
				}).then(() => {
					fetchData();
					handleClose();
				});
			});
	
			setResumeURL(resumeFileURL); // Update local state
		} catch (e) {
			console.error("Error uploading resume:", e);
		} finally {
			setEditLoading(false);
		}
	};
	
	

	const deleteOfID = async (e, id, collection) => {
		e.preventDefault();

		try {
			e.target.innerHTML = "...";

			await deleteDoc(doc(db, collection, id));

			fetchAndReset();
		} catch (e) {
			console.log(e);

			setAlert(`Internal Server Error: Deleting in ${collection}`);
		}
	};

	const resetBasicDetails = () => {
		setNname(other_details?.name);
		setNPhone(other_details?.phone);
		setNLinkedin(other_details?.linkedin);
		setNSummary(other_details?.about);
		setNemail(user?.currentUser?.email);
	};

	const verifyEmail = (e) => {
		e.preventDefault();
	};

	useEffect(() => {
		fetchAndReset();
	}, [user, authChecked]);

	useEffect(() => {
		setTimeout(() => {
			setAlert();
		}, 3000);
	}, [alert]);

	return (
		<div>
			<Nav />

			<Helmet>
				<title>
					AccessCareer | My Resume | Your anchor to a bright future
				</title>
				<meta
					name="description"
					content="Take your first step towards your dream career with AccessCareer! We offer a curated selection of internships across diverse fields, exclusively for college students and recent graduates. No more sifting through irrelevant job boards - find your perfect internship match on AccessCareer."
				/>
				<meta
					name="keywords"
					content="Software engineering, Mechanical engineering, Electrical engineering, Chemical engineering, Civil engineering, Biomedical engineering, Data science, Cybersecurity, Artificial intelligence, Machine learning, Cloud computing, Software development, Finance, Marketing, Management, Human resources, Operations, Consulting, Graphic design, Product design, UX/UI design, Web design, Fashion design, Interior design, Internship, College internships, Recent graduate internships, Entry-level jobs, Job search, Career development"
				/>
				<meta
					http-equiv="Content-Type"
					content="text/html;charset=UTF-8"
				/>
			</Helmet>

			<div className="flex h-[100%] bg-teal-50 items-center justify-center">
				<div className="flex flex-col gap-5 p-8 bg-white h-[100%] w-screen md:w-[70vw] border-r-1 border-l-1 border-gray-200">
					<div className="flex w-full justify-between items-center">
						<button
							className="flex items-center w-max"
							onClick={() => {
								navigate(-1);
							}}
						>
							<ArrowLeftSharp />
							Go Back
						</button>
						<button
							className="flex items-center w-max"
							onClick={() => {
								fetchData();
							}}
						>
							Refresh
							<Refresh />
						</button>
					</div>
					<Container>
						<p className="text-lg font-semibold flex gap-2 items-center">
							About me
							<button
								className="text-gray-500 flex"
								onClick={() => handleOpen("aboutMe")}
							>
								<Edit fontSize="25" />
							</button>
						</p>
						<div className="flex flex-col text-gray-700">
							<p className="text-md font-semibold">
								{loading ? (
									<CircularProgress size="20" />
								) : (
									other_details?.name || "N/A"
								)}
							</p>

							{user?.emailVerified ? (
								!editEmail ? (
									<>
										<div className="flex w-max gap-2 items-center">
											<p>
												{loading ? (
													<CircularProgress size="20" />
												) : (
													user?.email || "N/A"
												)}
											</p>
											<Edit
												className="cursor-pointer"
												fontSize="20"
												onClick={() => {
													setEditEmail(true);
												}}
											/>
										</div>
									</>
								) : (
									<div className="w-[50%] flex flex-col gap-1">
										<Input
											placeholder="New e-mail"
											value={nEmail}
											onChange={(e) => {
												setNemail(e.target.value);
											}}
										></Input>
										<div className="flex gap-2">
											<button
												className="w-full"
												onClick={() => {
													setEditEmail(false);
												}}
											>
												Cancel
											</button>
											<Button
												className="w-full"
												onClick={async (e) => {
													e.preventDefault();

													if (!nEmail) {
														window.alert(
															"Fill out new email",
														);
														return;
													}

													if (user) {
														updateEmail(
															user?.currentUser,
															nEmail,
														).then(() => {
															fetchData();
															setEditEmail(false);
														});
													}
												}}
											>
												Save
											</Button>
										</div>
									</div>
								)
							) : (
								<>
									<p>
										{loading ? (
											<CircularProgress size="20" />
										) : (
											user?.email || "N/A"
										)}
									</p>
								</>
							)}

							<p>
								{loading ? (
									<CircularProgress size="20" />
								) : other_details?.phone ? (
									"+91 " + other_details?.phone
								) : (
									"N/A"
								)}
							</p>
							<a
								href={
									loading ? (
										<CircularProgress />
									) : (
										other_details?.linkedin ||
										"https://www.linkedin.com/"
									)
								}
								className="flex items-center gap-1 text-[#8d6cab] w-max"
							>
								<LinkedIn />
								LinkedIn
							</a>
							<Link to="/profile" className="text-blue-500 hover:text-blue-700">
								Profile
							</Link>
							<p className="p-2 border-1 border-gray-200 rounded-md mb-2 mt-2 text-black">
								{loading ? (
									<CircularProgress size="20" />
								) : (
									other_details?.about ||
									"You don't have a about paragraph the describes you. Go to edit profile and add it!"
								)}
							</p>
							{!user?.emailVerified && (
								<div className="w-full">
									<button
										onClick={verifyEmail}
										className="w-max text-sm font-semibold flex items-center gap-1 float-right"
									>
										Verify e-mail <Mail fontSize="20" />
									</button>
								</div>
							)}
						</div>
					</Container>
					<hr />
					<Container>
						<p className="text-lg font-semibold flex gap-2 items-center">
							Education
							<button
								className="text-gray-500 flex"
								onClick={() => {
									handleOpen("education");
								}}
							>
								<BsPlus fontSize="25" />
							</button>
						</p>
						{loading ? (
							<CircularProgress />
						) : (
							education
								?.sort((a, b) => {
									const fromComparison =
										parseInt(a.data.from) -
										parseInt(b.data.from);

									return fromComparison !== 0
										? fromComparison
										: parseInt(a.data.to) -
												parseInt(b.data.to);
								})
								.map((item, index) => (
									<div
										className="flex flex-col"
										key={`education${item.id}`}
									>
										<p className="text-xs p-1 border-gray-200 border-1 rounded-full w-max flex items-center">
											<FiberManualRecord
												fontSize="20"
												className="text-gray-400"
											/>
											{item.data?.from}-{item.data?.to}
										</p>
										<p className="text-xl font-bold">{item.data?.institute}</p>
										<p className="text-lg">{item.data?.major}</p>
										<p className="text-sm">{item.data?.CGPA}</p>
										<button
											className="text-xs text-red-800 w-max"
											onClick={(e) => {
												deleteOfID(
													e,
													item.id,
													"education",
												);
											}}
										>
											Remove
										</button>
										{index < education.length - 1 && <hr className="my-4 border-gray-300" />}
									</div>
								))
						)}
					</Container>
					<hr />
					<Container>
						<p className="text-lg font-semibold flex gap-2 items-center">
							Skills
						</p>

						<div
							className="flex flex-wrap w-full lg:w-[50em]"
							id="skills"
						>
							{loading ? (
								<CircularProgress size="20" />
							) : (
								skill_details?.length > 0 &&
								skill_details.map((objects, index) =>
									objects.skills.map((skill, skillIndex) => (
										<div
											key={`${index}-${skillIndex}`} // Use unique keys
											className="p-1 pr-2 pl-2 rounded-full border-0 border-gray-200 w-max m-2 bg-blue-50"
											id={"skills" + skillIndex + 1}
										>
											<p className="flex items-center justify-center gap-1">
												{skill}
												<Cancel
													className="cursor-pointer"
													fontSize="25"
													onClick={async (e) => {
														try {
															const docRef =
																collection(
																	db,
																	"student_skills",
																);
															const docs =
																await getDocs(
																	docRef,
																	query(
																		where(
																			"student",
																			"==",
																			user?.uid,
																		),
																	),
																);

															docs.forEach(
																async (doc) => {
																	await updateDoc(
																		doc.ref,
																		{
																			skills: arrayRemove(
																				skill,
																			),
																		},
																	);
																},
															);

															document.getElementById(
																"skills" +
																	skillIndex +
																	1,
															).style.display =
																"none";
														} catch (e) {
															console.log(e);
															setAlert(
																"Error deleting skill",
															);
														}
													}}
												/>
											</p>
										</div>
									)),
								)
							)}

							{loading ? (
								<CircularProgress size="20" />
							) : !editSkills ? (
								<div className="p-1 pr-2 pl-2 rounded-full border-1 border-gray-200 w-max m-2 bg-gray-50">
									<button
										className="flex items-center justify-center gap-1"
										onClick={(e) => {
											e.preventDefault();

											setEditSkills(true);
										}}
									>
										Add
										<PlusOne fontSize="25" />
									</button>
								</div>
							) : (
								<form
									action=""
									className="flex w-[30em] items-center gap-2"
								>
									<Input
										placeholder="Skill (Add spaces with '-')"
										onChange={(e) => {
											if (
												e.target.value.split(" ")
													.length > 1
											) {
												e.target.value =
													e.target.value.split(
														" ",
													)[0];
												setTypeInSkill(e.target.value);
											} else {
												setTypeInSkill(e.target.value);
											}
										}}
										endDecorator={
											<Button onClick={addSkill}>
												{editLoading ? (
													<LinearProgress />
												) : (
													"Add"
												)}
											</Button>
										}
									/>
									<Cancel
										fontSize="35"
										onClick={() => {
											setEditSkills(false);
										}}
										className="cursor-pointer"
									/>
								</form>
							)}
						</div>
					</Container>
					<Container>
						<p className="text-lg font-semibold flex gap-2 items-center">
							Experience and Projects
							<button
								className="text-gray-500 flex"
								onClick={() => {
									handleOpen("experience");
								}}
							>
								<Edit fontSize="25" />
							</button>
						</p>

						{loading ? (
							<CircularProgress size="20" />
						) : (
							experience?.map((grp, index) => {
								return (
									<div className="flex flex-col">
										<p className="text-xs p-1 border-gray-200 border-1 rounded-full w-max flex items-center">
											<FiberManualRecord
												fontSize="20"
												className="text-green-300"
											/>
											{grp?.data?.from}-{grp?.data.to}
										</p>
										<p className="text-xl font-bold">
											{grp?.data.title}
										</p>
										<p className="text-lg">
											{grp?.data.desc}
										</p>
										<p className="text-sm">
											{grp?.data.references}
										</p>
										<button
											className="text-xs text-red-800 w-max"
											onClick={(e) => {
												deleteOfID(
													e,
													grp.id,
													"experience_projects",
												);
											}}
										>
											Remove
										</button>
										{index < education.length - 1 && <hr className="my-4 border-gray-300" />}
									</div>
								);
							})
						)}
					</Container>
					<Container>
						<p className="text-lg font-semibold flex gap-2 items-center">
							Portfolio
							<button
								className="text-gray-500 flex"
								onClick={() => {
									handleOpen("portfolio");
								}}
							>
								<Edit fontSize="25" />
							</button>
						</p>
						<div className="flex flex-col gap-3">
							{loading ? (
								<CircularProgress size="20" />
							) : (
								portfolio?.map((grp) => {
									return (
										<div className="flex flex-col">
											<p className="text-lg">
												{grp?.data.title}
											</p>
											<a
												href={grp?.data.link}
												className="text-sm text-blue-400 font-normal underline flex gap-1 items-center"
												target="_blank"
											>
												{grp?.data.link}
												<OpenInNew fontSize="25" />
											</a>
											<button
												className="text-xs text-red-800 w-max"
												onClick={(e) => {
													deleteOfID(
														e,
														grp.id,
														"portfolio",
													);
												}}
											>
												Remove
											</button>
										</div>
									);
								})
							)}
						</div>
					</Container>
					<Container>
						<p className="text-lg font-semibold flex gap-2 items-center">
							Resume
							<button
								className="text-gray-500 flex"
								onClick={() => {
									handleOpen("resume");
								}}
							>
								<Edit fontSize="25" />
							</button>
						</p>
						{resumeURL && (
							<a
								href={resumeURL}
								target="_blank"
								rel="noopener noreferrer"
								className="button outline-none flex justify-center items-center"
							>
								View Resume
								<OpenInNew className="button-icon" />
							</a>
						)}
					</Container>
				</div>

				{/*
				 * Modals
				 */}
				<Modal
					open={editAboutMe}
					onClose={handleClose}
					aria-labelledby="edit-modal-title"
					aria-describedby="edit-modal-description"
				>
					<Box sx={style}>
						{alert && <Alert>{alert}</Alert>}
						<Typography
							className="flex items-center justify-between gap-1"
							id="modal-title"
							variant="h6"
							component="h2"
						>
							<section className="flex items-center gap-1">
								<Person />
							</section>
							<section>
								<Close
									className="cursor-pointer"
									onClick={handleClose}
								/>
							</section>
						</Typography>
						<form className=" flex flex-col gap-2">
							<div className="flex flex-col">
								<label htmlFor="">Name</label>
								<Input
									placeholder="John Doe"
									value={nName}
									onChange={(e) => {
										setNname(e.target.value); // Corrected function name to setNname
									}}
									// required
								/>
							</div>
							<div className="flex flex-col">
								<label htmlFor="phone">Phone</label>
								<Input
									id="phone"
									type="tel"
									placeholder="Do not include +91"
									value={nPhone}
									onChange={(e) => {
										setNPhone(e.target.value);
									}}
								/>
							</div>
							<div className="flex flex-col">
								<label htmlFor="">LinkedIn</label>
								<Input
									placeholder="Your LinkedIn profile link"
									value={nLinkedin}
									onChange={(e) => {
										setNLinkedin(e.target.value);
									}}
									// required
								/>
							</div>
							<div className="flex flex-col">
								<label htmlFor="">Summary</label>
								<Textarea
									placeholder="Describe yourself in a short paragraph"
									required
									minRows={2}
									maxRows={4}
									value={nSummary}
									onChange={(e) => {
										setNSummary(e.target.value);
									}}
								/>
							</div>
							<Button onClick={editBasicDetails}>
								{editLoading ? (
									<LinearProgress />
								) : (
									"Save edits"
								)}
							</Button>
						</form>
					</Box>
				</Modal>

				{/*  */}
				<Modal
					open={editEducation}
					onClose={handleClose}
					aria-labelledby="edit-modal-title"
					aria-describedby="edit-modal-description"
				>
					<Box sx={style}>
						<Typography
							className="flex items-center justify-between gap-1"
							id="modal-title"
							variant="h6"
							component="h2"
						>
							<section className="flex items-center gap-1">
								<School />
							</section>
							<section>
								<Close
									className="cursor-pointer"
									onClick={handleClose}
								/>
							</section>
						</Typography>
						<form className=" flex flex-col gap-2">
							<div className="flex flex-col">
								<label htmlFor="">Institution</label>
								<Input
									placeholder="Ivy league School"
									onChange={(e) => {
										setInstitute(e.target.value);
									}}
									required
								/>
							</div>
							<div className="flex flex-col">
								<label htmlFor="">Course</label>
								<Input
									type="text"
									placeholder="Bachelor of Bussiness Administration"
									onChange={(e) => {
										setCourse(e.target.value);
									}}
									required
								/>
							</div>
							<div className="flex flex-col">
									<label htmlFor="">CGPA</label>
									<Input
										type="number"
										placeholder="9.7"
										onChange={(e) => {
											setCGPA(e.target.value);
										}}
										required
									></Input>
								</div>
							<div className="flex gap-1 flex-col md:flex-row">
								<div className="flex flex-col">
									<label htmlFor="">From</label>
									<Input
										placeholder="2021"
										onChange={(e) => {
											setFrom(e.target.value);
										}}
										required
									/>
								</div>
								<div className="flex flex-col">
									<label htmlFor="">To</label>
									<Input
										type="number"
										placeholder="2024"
										onChange={(e) => {
											setTo(e.target.value);
										}}
										required
									></Input>
								</div>
							</div>
							<Button onClick={addEducation}>
								{editLoading ? (
									<LinearProgress />
								) : (
									"Save edits"
								)}
							</Button>
						</form>
					</Box>
				</Modal>

				{/*  */}
				<Modal
					open={editExperience}
					onClose={handleClose}
					aria-labelledby="edit-modal-title"
					aria-describedby="edit-modal-description"
				>
					<Box sx={style}>
						<Typography
							className="flex items-center justify-between gap-1"
							id="modal-title"
							variant="h6"
							component="h2"
						>
							<section className="flex items-center gap-1">
								<AccountTree />
							</section>
							<section>
								<Close
									className="cursor-pointer"
									onClick={handleClose}
								/>
							</section>
						</Typography>
						<form
							action=""
							className=" flex flex-col gap-2"
						>
							<div className="flex flex-col">
								<label htmlFor="">Company & Desgination / Project Title</label>
								<Input
									type="text"
									placeholder="A short title"
									onChange={(e) => {
										setProjectTitle(e.target.value);
									}}
									required
								/>
							</div>
							<div className="flex flex-col">
								<label htmlFor="">Description</label>
								<Textarea
									type="text"
									placeholder="Describe what it does, technologies used, and inspiration for the project"
									required
									maxRows={5}
									minRows={3}
									onChange={(e) => {
										setProjectDesc(e.target.value);
									}}
								/>
							</div>
							<div className="flex flex-col">
								<label htmlFor="">References</label>
								<Textarea
									type="text"
									placeholder="Ex:(Team Members, Team Lead, Manager)"
									maxRows={5}
									minRows={3}
									onChange={(e) => {
										setRefrences(e.target.value);
									}}
								/>
							</div>
							<div className="flex gap-1 flex-col md:flex-row">
								<div className="flex flex-col">
									<label htmlFor="">From</label>
									<Input
										placeholder="2021"
										onChange={(e) => {
											setprojectFrom(e.target.value);
										}}
										required
									/>
								</div>
								<div className="flex flex-col">
									<label htmlFor="">To</label>
									<Input
										type="number"
										placeholder="2024"
										onChange={(e) => {
											setprojectTo(e.target.value);
										}}
										required
									></Input>
								</div>
							</div>
							<Button onClick={addProject}>
								{editLoading ? (
									<LinearProgress />
								) : (
									"Save edits"
								)}
							</Button>
						</form>
					</Box>
				</Modal>

				{/*  */}
				<Modal
					open={editPortfolio}
					onClose={handleClose}
					aria-labelledby="edit-modal-title"
					aria-describedby="edit-modal-description"
				>
					<Box sx={style}>
						<Typography
							className="flex items-center justify-between gap-1"
							id="modal-title"
							variant="h6"
							component="h2"
						>
							<section className="flex items-center gap-1">
								<ViewAgenda />
							</section>
							<section>
								<Close
									className="cursor-pointer"
									onClick={handleClose}
								/>
							</section>
						</Typography>
						<form
							action=""
							className=" flex flex-col gap-2"
						>
							<div className="flex flex-col">
								<label htmlFor="">Title</label>
								<Input
									type="text"
									placeholder="A short title"
									onChange={(e) => {
										setPortFolioTitle(e.target.value);
									}}
									required
								/>
							</div>
							<div className="flex flex-col">
								<label htmlFor="">Link</label>
								<Input
									placeholder="Link to the portfolio"
									onChange={(e) => {
										setPortfolioLink(e.target.value);
									}}
									required
								/>
							</div>
							<Button onClick={addPortfolio}>
								{editLoading ? (
									<LinearProgress />
								) : (
									"Save edits"
								)}
							</Button>
						</form>
					</Box>
				</Modal>
				<Modal
					open={editResume}
					onClose={handleClose}
					aria-labelledby="edit-modal-title"
					aria-describedby="edit-modal-description"
				>
					<Box sx={style}>
						<Typography
							className="flex items-center justify-between gap-1"
							id="modal-title"
							variant="h6"
							component="h2"
						>
							<section className="flex items-center gap-1">
								<Edit />
							</section>
							<section>
								<Close className="cursor-pointer" onClick={handleClose} />
							</section>
						</Typography>
						<form action="" className="flex flex-col gap-2" onSubmit={handleResumeUpload}>
							<div className="flex flex-col justify-center items-center p-5 border-1p5 border-gray-400 mt-1 rounded-md">
								<input
									type="file"
									accept=".pdf,.doc,.docx"
									onChange={(e) => {
										setNewResume(e.target.files[0]);
									}}
									required
									className="hidden"
									id="resume_upload"
								/>
								<div className="flex flex-col w-full justify-center items-center cursor-pointer">
									<MdOutlineDriveFolderUpload
										size={50}
										color="gray"
										onClick={() => {
											document.getElementById("resume_upload").click();
										}}
									/>
									<label htmlFor="resume_upload" className="cursor-pointer">Upload Resume</label>
								</div>
							</div>
							{
								newResume && ( <p className="w-full text-center"> Resume Name </p> )
							}
							<Button type="submit">
								{editLoading ? <LinearProgress /> : "Upload Resume"}
							</Button>
						</form>
					</Box>
				</Modal>
			</div>
			<Chats />
		</div>
	);
}

const Container = ({ children }) => {
	return <div className="flex flex-col gap-2">{children}</div>;
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "max-content",
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: "1rem",
	p: 4,
};
