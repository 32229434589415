import React, { useState } from "react";
import logo from "../../../logo/color_withname.png";
import { BsChatLeft, BsChatLeftFill } from "react-icons/bs";
import { CgMore } from "react-icons/cg";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { logout } from "../../../Exports";
import { auth } from "../../../Config/firebase";

export default function EmployerNav() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleMoreClick = () => setIsOpen(!isOpen);

  return (
    <div className="static top-0 backdrop-blur-sm flex flex-wrap gap-5 md:gap-0 md:flex-nowrap p-5 pr-10 pl-10 w-screen justify-between items-center shadow-md">
      <div className="flex justify-between sm:w-full md:w-auto items-center gap-2">
        <Link to="/employer/dashboard" className="cursor-pointer">
          <img
            src={logo}
            style={{ objectFit: "contain", width: "12rem" }}
            alt="Logo"
          />
        </Link>
        <CgMore className="visible md:hidden" onClick={handleMoreClick} />
      </div>

      <ul
        className={`flex flex-wrap gap-8 items-center justify-center list-none ${
          isOpen ? "flex" : "hidden"
        } md:flex`}
      >
        <li>
          <Link
            to="/employer/dashboard"
            className={`flex flex-col cursor-pointer items-center text-p2`}
          >
            Dashboard
          </Link>
        </li>
        <li>
          <Link
            to="/employer/Explore"
            className={`flex flex-col cursor-pointer items-center text-p2`}
          >
            Explore
          </Link>
        </li>
        <li>
          <Link
            to="/employer/internship/new"
            className={`flex flex-col cursor-pointer items-center text-p2`}
          >
            New Internship
          </Link>
        </li>
        <li>
          <span
            className={`flex flex-col cursor-pointer items-center text-p2`}
            onClick={async () => {
              await logout();

              if (!auth.currentUser) {
                navigate("/auth");
              } else {
                window.alert("Error logging out");
              }
            }}
          >
            Logout
          </span>
        </li>
      </ul>
    </div>
  );
}
