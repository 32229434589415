import React from 'react';
import Nav from './Nav';
import Footer from './Footer';

const AboutUs = () => {
  return (
    <>
      <Nav />

      <div className="container mx-auto px-4 py-8 mb-4">
        <header className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-800">About Us</h1>
        </header>

        <section className="space-y-8">
          {/* Our Mission */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-700">Our Mission</h2>
            <p className="text-lg text-gray-600">
              At <strong>AccessCareer</strong>, we are committed to helping students and young professionals find meaningful internship opportunities that align with their career goals. Our platform connects students with companies across various industries, offering a streamlined process for discovering internships that offer real-world experience.
            </p>
          </div>

          {/* Our Story */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-700">Our Story</h2>
            <p className="text-lg text-gray-600">
              Founded with the goal of bridging the gap between students and employers, <strong>AccessCareer</strong> aims to grow into a trusted platform for internships. We understand how crucial hands-on experience is in shaping careers, which is why we strive to make internship opportunities accessible to all.
            </p>
          </div>

          {/* Website Rules for Users */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-700">Website Rules</h2>
            <p className="text-lg text-gray-600">
              To maintain the integrity of our platform, we have a few rules that all users must follow:
            </p>
            <ul className="list-disc list-inside text-lg text-gray-600">
              <li>Ensure your profile information is accurate and up-to-date.</li>
              <li>Only verified profiles are eligible to apply for internships.</li>
              <li>Do not spam employers or send unsolicited messages.</li>
              <li>Provide honest feedback after completing an internship.</li>
              <li>Uploading fake certificates or false documents will lead to account suspension.</li>
            </ul>
          </div>

          {/* Guidelines for Employers */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-700">Guidelines for Employers</h2>
            <p className="text-lg text-gray-600">
              Employers who use <strong>AccessCareer</strong> to post internships are expected to follow these guidelines:
            </p>
            <ul className="list-disc list-inside text-lg text-gray-600">
              <li>Provide a clear and detailed description of the internship, including duration, stipend (if applicable), and responsibilities.</li>
              <li>Ensure that the internship offers valuable learning opportunities for interns.</li>
              <li>Give feedback to applicants, whether selected or not, in a timely manner.</li>
              <li>Interns should not be overworked or exploited without prior agreements in place.</li>
            </ul>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
