import React, { useState } from 'react';
import Nav from '../../Components/Nav';
import ProfileCard from './ProfileCard';
import NewPostCard from './NewPostCard';
import PostCard from './PostCard';
import usePosts from './ProfileFetchPosts'; // Ensure this custom hook fetches posts for the logged-in user
import { WiStars } from "react-icons/wi";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { CircularProgress } from '@mui/material';
import '../../index.css'; // Import the new CSS file

export default function Profile() {
  const [posts, setPosts, loading, error] = usePosts(); // Custom hook to fetch posts
  const [aiSummary, setAiSummary] = useState("You'll need an API key from Google Maker Suite (previously known as Dialogflow) to access the Gemini AI functionality. Follow the instructions on Google's documentation to obtain a key: [invalid URL removed]");
  const [openSummary, setOpenSummary] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false); // Loading state for summary generation
  const GenAI = new GoogleGenerativeAI(process.env.REACT_APP_GEN_AI_API_KEY);

  // Ensure posts is always an array
  const safePosts = Array.isArray(posts) ? posts : [];

  const summarizePosts = async () => {
    if (safePosts.length > 0) {
      const postContent = safePosts.map(post => post.content).join('\n');
      setLoadingSummary(true); // Start loading
      try {
        const model = GenAI.getGenerativeModel({ model: "gemini-1.5-flash" });
        const prompt = `Summarize the following text for a user looking for key points:\n${postContent}`;
        const result = await model.generateContent(prompt);
        const response = result.response;
        const summary = response.text();

        setAiSummary(summary.length > 0 ? summary : "Not enough information to generate summary");
        handleOpenClose();
        if (response.err) {
          console.error('Error summarizing posts:', response.error);
          return;
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingSummary(false); // End loading
      }
    } else {
      window.alert("No posts to analyze");
    }
  };

  const handleOpenClose = () => {
    setOpenSummary(!openSummary);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading posts: {error.message}</div>;

  return (
    <>
      <Nav />
  <html lang="en"/>
      <div className="flex flex-col items-center gap-4 bg-gray-50 min-h-screen min-w-screen w-full h-full p-5">
        <ProfileCard from={"p"} />
        <NewPostCard posts={safePosts} setPosts={setPosts} /> {/* Pass posts and setPosts as props */}

        {safePosts.length === 0 ? (
          <div>No posts found.</div>
        ) : (
          safePosts.map(post => (
            <PostCard key={post.id} {...post} from="p" />
          ))
        )}

        {/* Uncomment and use the AI summary part as needed */}
        {/* <div className="relative">
          <button
            className="w-max rounded-full bg-white shadow-lg shadow-purple-300 flex justify-center items-center cursor-pointer pl-2"
            onClick={summarizePosts}
            disabled={loadingSummary} // Disable button while loading
          >
            <p style={{ color: "purple" }}>Generate</p>
            <WiStars size={35} color='purple' />
          </button>
          {loadingSummary && (
            <div className="flex justify-center items-center mt-2">
              <CircularProgress size={24} />
            </div>
          )}
          <div
            className={`${openSummary ? "block" : "hidden"} bg-white shadow-md shadow-purple-300 p-4 rounded-md m-1 min-w-[15rem] max-w-[25rem] right-5 flex flex-col gap-1 summary-box`}
          >
            <p>{aiSummary}</p>
            <button className="text-sm p-2 bg-red-900 text-white font-bold rounded-md" onClick={handleOpenClose}>Close</button>
          </div>
        </div> */}
      </div>
    </>
  );
}
