import React, { useEffect, useState } from "react";
import Nav from "../Components/Nav";
import {
  ArrowLeftSharp,
  CancelOutlined,
  CurrencyRupeeOutlined,
  HomeOutlined,
  LocationOnSharp,
  TimerOutlined,
} from "@mui/icons-material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../Config/firebase";
import { Box, Modal, Typography } from "@mui/material";
import { Alert, Button, Textarea } from "@mui/joy";
import { getAuth } from "firebase/auth";
import { Helmet } from "react-helmet";

export default function Internship() {
  const { id } = useParams();
  const [queryParams] = useSearchParams();
  const user = getAuth();
  const navigate = useNavigate();
  

  const [info, setInfo] = useState();
  const [saved, setSaved] = useState(false);
  const [hasApplied, setHasApplied] = useState(false);

  const [openApplication, setOpenApplication] = useState(false);
  const handleApplicationOpen = () => setOpenApplication(true);
  const handleApplicationClose = () => setOpenApplication(false);

  const [saveLoader, setSaveLoader] = useState(false);
  const [alert, setAlert] = useState();

  const fetchData = async () => {
    const internshipRef = doc(db, "internships", id);
    const savedInternshipsRef = collection(db, "savedInternships");
    const employersRef = collection(db, "employers");

    try {
        // Fetch the internship data
        const internshipSnap = await getDoc(internshipRef);
        if (!internshipSnap.exists()) {
            setAlert("Internship Not Found!");
            return;
        }

        const internshipData = internshipSnap.data();

        // Fetch the logo of the employer
        const employerQuery = query(
            employersRef,
            where("company", "==", internshipData.employer)
        );
        const employerSnap = await getDocs(employerQuery);

        let logo = null;
        employerSnap.forEach((doc) => {
            const employerData = doc.data();
            logo = employerData.logo;
        });

        // Fetch the saved status of the internship
        const savedInternshipsQuery = query(
            savedInternshipsRef,
            where("id", "==", user?.currentUser?.uid)
        );
        const savedInternshipsSnap = await getDocs(savedInternshipsQuery);

        let isSaved = false;
        savedInternshipsSnap.forEach((savedDoc) => {
            isSaved = savedDoc.data()?.saved?.includes(id);
        });

        // Check if the user has already applied
        const applicationsQuery = query(
            collection(db, "applications"),
            where("student", "==", user?.currentUser?.uid),
            where("internship", "==", id)
        );
        const applicationsSnap = await getDocs(applicationsQuery);

        setHasApplied(!applicationsSnap.empty);

        // Set state with fetched data
        setInfo({ ...internshipData, logo });

        setSaved(isSaved);
    } catch (error) {
        setAlert("Internal Server Error!");
        console.error("Error getting document:", error);
    }
};


  const verifySaved = async () => {
    let isSaved;
    const savedInternshipsRef = collection(db, "savedInternships");

    const savedInternshipsDocSnap = await getDocs(
      savedInternshipsRef,
      query(where("id", "==", user?.currentUser?.uid))
    );
    savedInternshipsDocSnap.forEach((savedDoc) => {
      isSaved = savedDoc.data()?.saved?.includes(id);
    });

    setSaved(isSaved);
  };

  const save_unsave = async () => {
    let currentInternshipId = id;

    try {
      setSaveLoader(true);

      const savedInternshipsRef = collection(db, "savedInternships");
      const userDocRef = doc(savedInternshipsRef, user?.currentUser?.uid);

      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const savedInternshipsData = userDocSnap.data();
        const savedArray = savedInternshipsData.saved || [];

        const isSaved = savedArray.includes(currentInternshipId);

        if (isSaved) {
          const updatedSavedArray = savedArray.filter(
            (id) => id !== currentInternshipId
          );

          await updateDoc(userDocRef, { saved: updatedSavedArray });
        } else {
          const updatedSavedArray = [...savedArray, currentInternshipId];

          await updateDoc(userDocRef, { saved: updatedSavedArray });
        }
      } else {
        await setDoc(userDocRef, {
          id: user?.currentUser?.uid,
          saved: [currentInternshipId],
        });
      }
    } catch (e) {
      setAlert("An error occurred while saving this internship!");
      console.error("Error saving/un-saving internship:", e);
    } finally {
      setSaveLoader(false);

      verifySaved();
    }
  };

  const handleApplication = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target);
      const data = {
        wswhy: formData.get("wswhy"),
        questions: [],
      };

      formData.forEach((value, key) => {
        if (key.startsWith("application_question_")) {
          const questionIndex = key.replace("application_question_", "");
          data.questions.push({
            [`application_question_${questionIndex}`]: value,
          });
        }
      });

      const exists = await getDocs(
        query(
          collection(db, "applications"),
          where("student", "==", user?.currentUser?.uid),
          where("internship", "==", id)
        )
      );

      if (exists.empty) {
        await addDoc(collection(db, "applications"), {
          company: info.employer,
          internship: id,
          student: user?.currentUser?.uid,
          status: "Applied",
          why_should_we_hire_you: data.wswhy,
          answers:
            data.questions.length > 0
              ? data.questions.map((answer) => {
                  return answer;
                })
              : [],
          dateApplied: Date.now(),
        });

        await updateDoc(doc(db, "internships", id), {
          applicants: increment(1),
        });
      } else {
        setAlert(
          "You've already applied to this internship! Let's wait for the employer's response, shall we?"
        );
      }
    } catch (e) {
      console.log(e);

      setAlert("Error applying to internship");
    } finally {
      handleApplicationClose();
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert();
    }, 5000);
    return () => clearTimeout(timer);
  }, [alert]);

  return (
    <>
      <Nav />

      <Helmet>
        <title>{`${info?.title || queryParams.get("title")} | ${queryParams.get("company")} | Internships | AccessCareer`}</title>
        <meta name="description" content={`${info?.description}`} />
        <meta name="keywords" content={`${info?.skills?.join(', ') || ""}`} />
      </Helmet>

      <div className="flex h-[100%] bg-teal-50 items-center justify-center">
        <div className="flex flex-col gap-5 p-8 bg-white h-[100%] w-screen md:w-[70vw] border-r-1 border-l-1 border-gray-200">
          {alert && <Alert>{alert}</Alert>}
          <button
            className="w-max font-semibold"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowLeftSharp />
            Go back
          </button>
          <section className="flex flex-col">
            <div className="flex w-full items-center justify-center">
              <p className="text-2xl font-semibold mb-2">{info?.title}</p>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col gap-5 w-full">
                <div className="flex flex-col">
                  <p className="text-xl font-semibold">
                    {queryParams.get("company")}
                  </p>
                  <p className="text-md font-semibold text-gray-500">
                    {queryParams.get("tagline") !== "undefined"
                      ? queryParams.get("tagline")
                      : ""}
                  </p>
                </div>
                <div className="flex flex-wrap gap-3">
                  <p className="flex items-center gap-1 text-sm">
                    <HomeOutlined htmlColor="gray" />
                    {info?.workType}
                  </p>
                  <p className="flex items-center gap-1 text-sm">
                    <TimerOutlined htmlColor="gray" />
                    {info?.duration} Months
                  </p>
                  <p className="flex items-center gap-1 text-sm">
                    <CurrencyRupeeOutlined htmlColor="gray" />
                    {info?.stipend}/Month
                  </p>
                  <p className="flex items-center gap-1 text-sm">
                    <LocationOnSharp htmlColor="gray" />
                    {info?.location}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <img
                  className="w-[70px] h-[70px] rounded-md"
                  src={info?.logo}
                  alt="Company Logo"
                />
              </div>
            </div>
          </section>

          <section className="flex flex-col gap-4">
            <div className="flex justify-between w-full">
              <p className="font-bold">About this internship</p>
              <Button
                loading={saveLoader}
                onClick={save_unsave}
                className="flex items-center gap-2"
                variant="soft"
              >
                {saved ? (
                  <>
                    <CancelOutlined />
                    Unsave
                  </>
                ) : (
                  "Save"
                )}
              </Button>
            </div>
            <div>
              <p className="font-semibold">Number of openings</p>
              <p>{info?.openings}</p>
            </div>
            <div>
              <p className="font-semibold">Responsibilities</p>
              <p>{info?.description}</p>
            </div>
            <div>
              <p className="font-semibold">Perks</p>
              <p>{info?.perks || "No perks specified"}</p>
            </div>
            <div>
              <p className="font-semibold">Skills Required</p>
              <p>{info?.skills?.join(', ') || "No skills specified"}</p>
            </div>
            <div>
              <p className="font-semibold">Employment Type</p>
              <p>{info?.workType || "Not specified"}</p>
            </div>
          </section>

          {hasApplied ? (
            <Alert color="warning">
              You've already applied to this internship!
            </Alert>
          ) : (
            <Button onClick={handleApplicationOpen}>Apply</Button>
          )}

          <Modal
            open={openApplication}
            onClose={handleApplicationClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] p-10 w-[95vw] md:w-[70vw] lg:w-[50vw] h-[85vh] overflow-scroll bg-white border-none outline-none">
              <form onSubmit={handleApplication}>
                <div className="flex flex-col gap-5">
                  <p className="font-semibold">
                    Why should we hire you for this role?
                  </p>
                  <Textarea
                    required
                    name="wswhy"
                    className="bg-gray-50 h-[25vh]"
                  />

                  <p className="font-semibold">
                    Other questions from the employer
                  </p>
                  {info?.application_questions &&
                    info?.application_questions.map((item, index) => {
                      return (
                        <div key={index} className="flex flex-col gap-3">
                          <p>{item}</p>
                          <Textarea
                            name={`application_question_${index}`}
                            required
                            className="bg-gray-50"
                          />
                        </div>
                      );
                    })}
                  <Button type="submit">Submit Application</Button>
                </div>
              </form>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
}
