import { Delete } from "@mui/icons-material";
import { Button } from "@mui/joy";
import {
  arrayRemove,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React from "react";

import { GoClock, GoHome } from "react-icons/go";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { MdCurrencyRupee } from "react-icons/md";
import { Link } from "react-router-dom";
import { db } from "../Config/firebase";
import useUser from "../Utils/useUser";

export default function InternshipCard({
  title,
  desc,
  image,
  company,
  type,
  pay,
  duration,
  id,
  sid,
  location,
  tagline,
  fromSaved,
  fetchFuncSaved,
  reload,
  rval,
}) {
  const [user, authChanged] = useUser();

  const removeSaved = async (e) => {
    e.preventDefault();

    try {
      const savedDocs = await getDocs(
        collection(db, "savedInternships"),
        query(where("of", "==", user?.uid))
      );

      savedDocs.forEach(async (saved_doc) => {
        await updateDoc(saved_doc.ref, {
          saved: arrayRemove(id),
        });
      });

      document.getElementById(id).style.display = "none";
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div
        className="flex flex-col p-5 border-1p5 border-gray-200 rounded-md w-full shadow-md"
        id={id}
      >
        <div className="flex justify-between">
          <div>
            <p className="text-2xl font-bold text-p3">{title}</p>
            <p className="text-lg font-semibold text-gray-500">{company}</p>
          </div>
          {image ? (
            <img
              src={image}
              style={{
                objectFit: "contain",
                width: "5rem",
                height: "5rem",
              }}
              alt=""
            />
          ) : (
            <div
              style={{
                objectFit: "contain",
                width: "5rem",
                height: "5rem",
              }}
            ></div>
          )}
        </div>
        <p className="flex items-center gap-1 text-md text-gray-800">
          {type?.toLowerCase() === "work from home" ? (
            <GoHome color="gray" />
          ) : (
            <HiOutlineBuildingOffice color="gray" />
          )}
          {type}
        </p>
        <div className="flex gap-8 mt-5 items-center text-sm">
          <p className="flex items-center gap-1 text-gray-800">
            <MdCurrencyRupee color="gray" />
            {pay}/month
          </p>
          <p className="flex items-center gap-1 text-gray-800">
            <GoClock color="gray" />
            {duration} month(s)
          </p>
        </div>
        <p className="text-md font-semibold mt-4">About {company}</p>
        <p className="font-sans text-sm">{desc}</p>
        <div className="w-full flex items-center justify-between pt-2 mt-2 border-t-1 border-gray-200">
          <Link
            className="text-p2 font-semibold mt-3 float-right"
            to={`/view/${id}?company=${company}&desc=${desc}&title=${title}&tagline=${tagline}`}
          >
            View and Apply
          </Link>
          {fromSaved && (
            <button
              onClick={removeSaved}
              value={id}
              className="text-sm text-red-700 cursor-pointer w-max"
            >
              <Delete />
            </button>
          )}
        </div>
      </div>
    </>
  );
}
