import React, { useState } from "react";
import logo from "../logo/color_withname.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Login, Logout, Menu } from "@mui/icons-material";
import { auth } from "../Config/firebase";
import useUser from "../Utils/useUser";
import AboutUs from "../legals/AboutUs";
import { CgMenu } from "react-icons/cg";  // Import CgMenu for the hamburger icon

export default function Nav() {
	const location = useLocation();
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);

	const [user, authChecked] = useUser();

	const logout = () => {
		auth.signOut().then(() => {
			navigate("/auth");
		});
	};

	return (
	<>
		<div className="sticky flex flex-wrap gap-5 md:gap-0 md:flex-nowrap p-5 pr-10 pl-10 w-screen justify-between items-center shadow-md bg-white z-50">
		<div className="flex justify-between sm:w-full md:w-auto items-center gap-2">
		<html lang="en"/>
			<Link to="/" className="cursor-pointer">
			<img
				src={logo}
				style={{ objectFit: "contain", width: "12rem" }}
				alt="logo"
			/>
			</Link>
			<CgMenu
			className="text-3xl cursor-pointer md:hidden ml-10"
			onClick={() => setIsOpen(!isOpen)}
			/>
		</div>

		{/* Sidebar for mobile */}
		<div
			className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg transform ${
			isOpen ? 'translate-x-0' : 'translate-x-full'
			} transition-transform duration-300 ease-in-out md:hidden z-50`}
		>
			<button
			className="absolute top-5 right-5 text-2xl"
			onClick={() => setIsOpen(false)}
			>
			&times;
			</button>
			<ul className="flex flex-col gap-8 p-8 list-none">
			<li>
				<Link
				className="cursor-pointer text-p1"
				to="aboutus"
				onClick={() => setIsOpen(false)}
				>
				About us
				</Link>
			</li>
			<li>
				<Link
				className="cursor-pointer text-p1"
				to="/internships"
				onClick={() => setIsOpen(false)}
				>
				Internships
				</Link>
			</li>
			{/* Add other links here */}
			<li>
				<Link
				className="cursor-pointer text-p1"
				to="/saved"
				onClick={() => setIsOpen(false)}
				>
				Saved
				</Link>
			</li>
			<li>
				<Link
				className="cursor-pointer text-p1"
				to="/resume"
				onClick={() => setIsOpen(false)}
				>
				Resume
				</Link>
			</li>
			<li>
				<Link
				className="cursor-pointer text-p1"
				to="/applications"
				onClick={() => setIsOpen(false)}
				>
				Applications
				</Link>
			</li>
			<li>
				<Link
				className="cursor-pointer text-p1"
				to="/profile"
				onClick={() => setIsOpen(false)}
				>
				Profile
				</Link>
			</li>
			<li>
				{user ? (
				<button
					className="cursor-pointer text-p1"
					onClick={() => {
					logout();
					setIsOpen(false);
					}}
				>
					Logout
				</button>
				) : (
				<Link
					className="cursor-pointer text-p1"
					to="/auth"
					onClick={() => setIsOpen(false)}
				>
					Login
				</Link>
				)}
			</li>
			</ul>
		</div>

		{/* Normal desktop navbar */}
		<ul className="hidden md:flex flex-wrap gap-8 items-start justify-center list-none">
			<li>
			<Link
				className="flex flex-col cursor-pointer items-center text-p1 pt-1"
				to="aboutus"
			>
				About us
			</Link>
			</li>
			<li>
			<Link
				className="flex flex-col cursor-pointer items-center text-p1 pt-1"
				to="/internships"
			>
				Internships
			</Link>
			</li>
			<li>
				<button className="border rounded-lg ml-4 pt-1 pb-0.5 pr-0.5 bg-blue-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all shadow-sm hover:bg-gray-100 hover:text-black text-center">
					<li className="flex justify-between items-center">
						<Link
						className="flex flex-col cursor-pointer items-center w-full ml-3 text-white hover:text-black pr-2 pb-1"
						to="/auth"
						>
						Find opportunities
						</Link>
					</li>
				</button>
			</li>
			<li>
			<Menu
				className="cursor-pointer text-p1"
				onClick={() => setOpenMenu(!openMenu)}
			/>
			<div
				className={`md:top-16 md:right-12 p-2 rounded-md bg-white shadow-md border-1 border-gray-200  ${
				openMenu ? "absolute transition-all duration-300 ease-in-out" : "hidden"
				}`}
			>
				<ul className="w-full space-y-1">
				<li className="flex justify-between items-center">
					<Link
					className="flex flex-col cursor-pointer items-center text-p1 w-full"
					to="/saved"
					>
					Saved
					</Link>
				</li>
				<li className="flex justify-between items-center">
					<Link
					className="flex flex-col cursor-pointer items-center text-p1 w-full"
					to="/resume"
					>
					Resume
					</Link>
				</li>
				<li className="flex justify-between items-center">
					<Link
					className="flex flex-col cursor-pointer items-center text-p1 w-full"
					to="/applications"
					>
					Applications
					</Link>
				</li>
				<li className="flex justify-between items-center">
					<Link
					className="flex flex-col cursor-pointer items-center text-p1 w-full"
					to="/profile"
					>
					Profile
					</Link>
				</li>
				<li className="p-1 border-1 border-gray-200 rounded-md mt-1">
					{user ? (
					<button
						className="flex justify-between w-full cursor-pointer items-center text-p1"
						onClick={logout}
					>
						Logout
					</button>
					) : (
					<Link
						className="flex justify-between w-full cursor-pointer items-center text-p1"
						to="/auth"
					>
						Login
					</Link>
					)}
				</li>
				</ul>
			</div>
			</li>
		</ul>
		</div>
	</>
	);

}
