import { useNavigate, Link } from "react-router-dom";
import { useState , useCallback} from "react";
// import { auth } from "./Config/firebase";
import { auth, db, storage } from "./Config/firebase";
import { uploadBytes, ref, getDownloadURL , getStorage} from "firebase/storage";
import { updateDoc, doc, setDoc, getDoc, collection, getDocs, query, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";


export const server_url = "http://localhost:8000";



export const predefinedSkills = [
  // Programming Languages
  "JavaScript",
  "Python",
  "Java",
  "C++",
  "C#",
  "Ruby",
  "PHP",

  // Web Development
  "HTML",
  "CSS",
  "React",
  "Angular",
  "Vue.js",
  "Node.js",
  "Express.js",
  "Django",
  "Flask",

  // Mobile App Development
  "React Native",
  "Swift",
  "Kotlin",
  "Flutter",

  // Database Management
  "SQL",
  "MongoDB",
  "MySQL",
  "PostgreSQL",

  // Data Science and Machine Learning
  "Python (NumPy, Pandas, Scikit-Learn)",
  "TensorFlow",
  "PyTorch",
  "Data Analysis",
  "Machine Learning",
  "Deep Learning",

  // DevOps
  "Docker",
  "Kubernetes",
  "Jenkins",
  "Git",

  // Cloud Computing
  "Amazon Web Services (AWS)",
  "Microsoft Azure",
  "Google Cloud Platform (GCP)",

  // Cybersecurity
  "Ethical Hacking",
  "Penetration Testing",
  "Network Security",

  // Design
  "UI/UX Design",
  "Adobe Creative Suite",
  "Figma",
  "Sketch",

  // Business and Marketing
  "Digital Marketing",
  "SEO",
  "Content Marketing",
  "Social Media Marketing",
  "Market Research",

  // Project Management
  "Agile",
  "Scrum",
  "Kanban",

  // Communication Skills
  "Verbal Communication",
  "Written Communication",
  "Presentation Skills",

  // Problem Solving
  "Critical Thinking",
  "Analytical Skills",
  "Problem-solving Skills",

  // Languages
  "Fluent in English",
  "Knowledge of other languages (e.g., Spanish, French)",

  // Miscellaneous
  "Time Management",
  "Adaptability",
  "Teamwork",
  "Leadership",
  "Attention to Detail",
  "Creativity",
];

export const industryTypes = [
  "Agriculture",
  "Automotive",
  "Aviation",
  "Biotechnology",
  "Chemical",
  "Construction",
  "Consulting",
  "Consumer Goods",
  "Defense",
  "Education",
  "Energy",
  "Engineering",
  "Entertainment",
  "Environmental",
  "Fashion",
  "Finance",
  "Food and Beverage",
  "Government",
  "Healthcare",
  "Hospitality",
  "Information Technology",
  "Insurance",
  "Legal",
  "Manufacturing",
  "Marketing",
  "Media",
  "Mining",
  "Nonprofit",
  "Pharmaceutical",
  "Real Estate",
  "Retail",
  "Telecommunications",
  "Transportation",
  "Utilities",
  "Other",
  // Add more industry types as needed
];

export const indianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

export const jobProfiles = [
  "Software Engineer/Developer",
  "Data Scientist/Analyst",
  "Project Manager",
  "UX/UI Designer",
  "Digital Marketing Specialist",
  "Financial Analyst",
  "Human Resources Manager",
  "Sales Representative/Manager",
  "Content Writer",
  "Graphic Designer",
  "Network Administrator/Engineer",
  "Customer Service Representative",
  "Medical Doctor",
  "Nurse",
  "Pharmacist",
  "Teacher/Educator",
  "Mechanical Engineer",
  "Electrical Engineer",
  "Civil Engineer",
  "Architect",
  "Research Scientist",
  "Business Analyst",
  "Product Manager",
  "Social Media Manager",
  "Event Planner",
  "Chef/Culinary Professional",
  "Aerospace Engineer",
  "Lawyer/Attorney",
  "Police Officer",
  "Firefighter",
];

export const logout = async () => {
  try {
    await auth.signOut();

    return true;
  } catch (e) {
    console.log(e);

    return false;
  }
};


export function useUpdateDP(uid) {
    const [file, setFile] = useState(null);
    const storage = getStorage();

    const updateDP = useCallback(async () => {
        if (!file || !uid) return;

        const storageRef = ref(storage, `userLogos/${uid}`);
        try {
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);

            const docRef = doc(db, 'userLogos', uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                await updateDoc(docRef, { photoURL: url });
            } else {
                await setDoc(docRef, { photoURL: url });
            }

            return url;
        } catch (error) {
            console.error('Error updating profile picture:', error);
        }
    }, [file, uid]);

    return { setFile, updateDP };
}
