import React from "react";

import { GoClock, GoHome } from "react-icons/go";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { MdCurrencyRupee } from "react-icons/md";
import { VscLinkExternal } from "react-icons/vsc";

import { Link } from "react-router-dom";

export default function Card({
  title,
  image,
  company,
  type,
  pay,
  duration,
  posted,
  id,
}) {
  return (
    <>
      <div className="flex flex-col p-3 rounded-lg text-p3 w-[90vw] md:w-[18rem] h-[17rem] bg-white shadow-md">
        <div className="flex w-full justify-between items-center">
          <p className="flex flex-col">
            <span className="text-black font-semibold text-lg font-sans">
              {title}
            </span>
            <Link className="text-p3 font-semibold text-sm">{company}</Link>
          </p>
          <img
            src={image}
            className="shadow-sm"
            style={{
              objectFit: "contain",
              width: "4rem",
              height: "4rem",
            }}
            alt=""
          />
        </div>
        <span className="w-full border-1 border-gray-300 mt-4 mb-4"></span>
        <ul className="flex flex-col list-none text-p3 gap-2 text-sm">
          <li className="flex items-center gap-1">
            {type === "Work From Home" ? (
              <GoHome />
            ) : (
              <HiOutlineBuildingOffice />
            )}
            {type}
          </li>
          <li className="flex items-center gap-1">
            <MdCurrencyRupee />
            {pay}/month
          </li>
          <li className="flex items-center gap-1">
            <GoClock />
            {duration} month(s)
          </li>
        </ul>
        <Link className="text-p2 hover:underline mt-4 flex items-center gap-2" to={`/view/${id}`}>
          View Details
          <VscLinkExternal />
        </Link>
        <p className="text-xs text-gray-400 text-right">{posted}</p>
      </div>
    </>
  );
}
