import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/joy";
import {
  ArrowLeftSharp,
  FiberManualRecord,
  LinkedIn,
  OpenInNew,
  Refresh,
} from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { db } from "../../Config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import EmployerNav from "./Components/EmployerNav";
import { FaUser } from "react-icons/fa6";

export default function Resume() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();

  const [skillDetails, setSkillDetails] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);
  const [portfolio, setPortfolio] = useState([]);

  const fetchData = async () => {
    if (id) {
      const uid = id;

      try {
        setLoading(true);

        // Fetch and set basic details
        const basicDetailsQuery = await getDocs(
          query(
            collection(db, "other_details"),
            where("student", "==", uid)
          )
        );

        basicDetailsQuery.forEach((doc) => {
          const basicDetailsData = doc.data();
          setOtherDetails(basicDetailsData);
        });

        // Fetch and set education details
        const educationQuery = await getDocs(
          query(
            collection(db, "education"),
            where("student", "==", uid)
          )
        );

        const educationData = educationQuery.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setEducation(educationData);

        // Fetch and set experience details
        const experienceQuery = await getDocs(
          query(
            collection(db, "experience_projects"),
            where("student", "==", uid)
          )
        );

        const experienceData = experienceQuery.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        setExperience(experienceData);

        // Fetch and set portfolio details
        const portfolioQuery = await getDocs(
          query(
            collection(db, "portfolio"),
            where("student", "==", uid)
          )
        );

        const portfolioData = portfolioQuery.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        setPortfolio(portfolioData);

        // Fetch and set skills
        const skillsQuery = await getDocs(
          query(
            collection(db, "student_skills"),
            where("student", "==", uid)
          )
        );

        const skillsData = skillsQuery.docs.map((doc) => doc.data());
        setSkillDetails(skillsData);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    setTimeout(() => {
      setAlert();
    }, 3000);
  }, [alert]);

  return (
    <div>
      <EmployerNav />

      <div className="flex h-[100%] bg-teal-50 items-center justify-center">
        <div className="flex flex-col gap-5 p-8 bg-white h-[100%] w-screen md:w-[70vw] border-r-1 border-l-1 border-gray-200">
          <div className="flex w-full justify-between items-center">
            <button
              className="flex items-center w-max"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowLeftSharp />
              Go Back
            </button>
            <button
              className="flex items-center w-max"
              onClick={() => {
                fetchData();
              }}
            >
              Refresh
              <Refresh />
            </button>
          </div>
          <Container>
            <p className="text-lg font-semibold flex gap-2 items-center">
              About me
            </p>
            <div className="flex flex-col text-gray-700">
              <p className="text-md font-semibold">
                {loading ? (
                  <CircularProgress size="20" />
                ) : (
                  otherDetails?.name || "N/A"
                )}
              </p>

              <p>
                {loading ? (
                  <CircularProgress size="20" />
                ) : otherDetails?.phone ? (
                  "+91 " + otherDetails?.phone
                ) : (
                  "N/A"
                )}
              </p>
              <a
                href={
                  loading ? (
                    <CircularProgress />
                  ) : (
                    otherDetails?.linkedin ||
                    "https://www.linkedin.com/"
                  )
                }
                className="flex items-center gap-1 text-[#8d6cab] w-max"
              >
                <LinkedIn />
                LinkedIn
              </a>
              <Link to={`/profile/${id}`} className="flex gap-2 items-center">
                <FaUser />
                View Profile
              </Link>
              <p className="p-2 border-1 border-gray-200 rounded-md mb-2 mt-2 text-black">
                {loading ? (
                  <CircularProgress size="20" />
                ) : (
                  otherDetails?.about ||
                  "You don't have an about paragraph that describes you. Go to edit profile and add it!"
                )}
              </p>
            </div>
          </Container>
          <hr />
          <Container>
            <p className="text-lg font-semibold flex gap-2 items-center">
              Education
            </p>
            {loading ? (
              <CircularProgress />
            ) : (
              education
                ?.sort((a, b) => {
                  const fromComparison =
                    parseInt(a.data.from) - parseInt(b.data.from);

                  return fromComparison !== 0
                    ? fromComparison
                    : parseInt(a.data.to) -
                      parseInt(b.data.to);
                })
                .map((item, index) => (
                  <div
                    className="flex flex-col"
                    key={`education${item.id}`}
                  >
                    <p className="text-xs p-1 border-gray-200 border-1 rounded-full w-max flex items-center">
                      <FiberManualRecord
                        fontSize="20"
                        className="text-gray-400"
                      />
                      {item.data?.from}-{item.data?.to}
                    </p>
                    <p className="text-xl font-bold">
                      {item.data?.institute}
                    </p>
                    <p className="text-lg">
                      {item.data?.major}
                    </p>
                    <p className="text-sm">
                      {item.data?.CGPA}
                    </p>
                    {index < education.length - 1 && <hr className="my-4 border-gray-300" />}
                  </div>
                ))
            )}
          </Container>
          <hr />
          <Container>
            <p className="text-lg font-semibold flex gap-2 items-center">
              Skills
            </p>

            <div
              className="flex flex-wrap w-full lg:w-[50em]"
              id="skills"
            >
              {loading ? (
                <CircularProgress size="20" />
              ) : (
                skillDetails?.length > 0 &&
                skillDetails.map((objects, index) =>
                  objects.skills.map((skill, skillIndex) => (
                    <div
                      key={`${index}-${skillIndex}`} // Use unique keys
                      className="p-1 pr-2 pl-2 rounded-full border-0 border-gray-200 w-max m-2 bg-blue-50"
                      id={"skills" + skillIndex + 1}
                    >
                      <p className="flex items-center justify-center gap-1">
                        {skill}
                      </p>
                    </div>
                  )),
                )
              )}
            </div>
          </Container>
          <Container>
            <p className="text-lg font-semibold flex gap-2 items-center">
              Experience and Projects
            </p>

            {loading ? (
              <CircularProgress size="20" />
            ) : (
              experience?.map((grp, index) => {
                return (
                  <div className="flex flex-col" key={grp.id}>
                    <p className="text-xs p-1 border-gray-200 border-1 rounded-full w-max flex items-center">
                      <FiberManualRecord
                        fontSize="20"
                        className="text-green-300"
                      />
                      {grp?.data?.from}-{grp?.data.to}
                    </p>
                    <p className="text-xl font-bold">
                      {grp?.data.title}
                    </p>
                    <p className="text-lg">
                      {grp?.data.desc}
                    </p>
                    <p className="text-lg">
                      {grp?.data.references}
                    </p>
                    {index < education.length - 1 && <hr className="my-4 border-gray-300" />}
                  </div>
                );
              })
            )}
          </Container>
          <Container>
            <p className="text-lg font-semibold flex gap-2 items-center">
              Portfolio
            </p>
            <div className="flex flex-col gap-3">
              {loading ? (
                <CircularProgress size="20" />
              ) : (
                portfolio?.map((doc) => (
                  <a
                    href={doc?.data?.link}
                    key={doc.id}
                    className="text-md font-semibold flex gap-1 items-center text-blue-400"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {doc?.data?.title}
                    <OpenInNew fontSize="20" />
                  </a>
                ))
              )}
            </div>
          </Container>
          <Container>
						<p className="text-lg font-semibold flex gap-2 items-center">
							Resume
						</p>
							<a
								href={otherDetails?.resumeURL}
								target="_blank"
								rel="noopener noreferrer"
								className="button"
							>
								View Resume
								<OpenInNew className="button-icon" />
							</a>
					</Container>
        </div>
      </div>
    </div>
  );
}

const Container = ({ children }) => (
  <div className="flex flex-col w-full">{children}</div>
);
