import React from "react";
import { Link } from "react-router-dom";
import { IoMailOpenOutline } from "react-icons/io5";
import { LuInstagram, LuLinkedin } from "react-icons/lu";
import {
  FaBuildingCircleArrowRight,
  FaXTwitter,
} from "react-icons/fa6";
import { GoLaw } from "react-icons/go";

export default function Footer() {
  return (
    <>
      <div className="bottom-0 flex flex-col justify-center items-center w-screen bg-p3 text-white p-4 ">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 w-full">
          <div className="flex flex-col">
            {/* <Link className="flex items-center gap-1" to={"/Legals"}>
              <GoLaw />
              Legals
            </Link> */}
            <Link className="flex items-center gap-1 " to={"https://mail.google.com/mail/?view=cm&fs=1&to=accesscareer.official@gmail.com"}>
              <IoMailOpenOutline />
              Contact Us
            </Link>
            <Link className="flex items-center gap-1" to={"/auth?tab=employer"}>
              <FaBuildingCircleArrowRight />
              Company login
            </Link>
          </div>
          <div className="flex flex-col gap-2">
            <Link to={"/internships"}>Search SDE Internships</Link>
            <Link to={"/internships"}>Search Marketing Internships</Link>
            <Link to={"/internships"}>Search SEO Internships</Link>
            <Link to={"/internships"}>View All Internships</Link>
          </div>
          <div className="flex flex-col gap-2">
            <Link to={"/internships"}>Internship in Delhi</Link>
            <Link to={"/internships"}>Internship in Hyderabad</Link>
            <Link to={"/internships"}>Internship in Bangalore</Link>
            <Link to={"/internships"}>Internship in Pune</Link>
          </div>
        </div>
        <div className="flex-end flex flex-col w-full p-4 mt-3">
          <div className="flex gap-3">
            <a href="https://www.linkedin.com/company/accesscareer" target="_blank">
              <LuLinkedin size={20} />
            </a>
            <a href="https://x.com/AccessCareer01" target="_blank">
              <FaXTwitter size={20} />
            </a>
          </div>
          <p className="text-end">&#169;AccessCareer</p>
        </div>
      </div>
    </>
  );
}
