import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { db } from "../../../Config/firebase"; // Updated path
import { collection, getDocs, query, where, doc, getDoc, addDoc } from "firebase/firestore";
import defaultUser from "../../../Assets/user.jpg"; // Updated path
import { Link } from 'react-router-dom';
import useUser from '../../../Utils/useUser'; // Assuming useUser hook is in Utils

export default function StuProfileCard({ userId }) {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [photoURL, setPhotoURL] = useState(defaultUser);
  const [applicationsCount, setApplicationsCount] = useState(0);
  const [shortlistedCount, setShortlistedCount] = useState(0);
  const [user, authChecked] = useUser(); // Assuming useUser hook returns [user, authChecked]

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Query the other_details collection to find the document with the student field equal to userId
        const q = query(collection(db, "other_details"), where("student", "==", userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          setUserDetails(userDoc.data());
        }

        // Get the profile photo from the userLogos collection
        const profilePicDoc = await getDoc(doc(db, "userLogos", userId));
        if (profilePicDoc.exists()) {
          setPhotoURL(profilePicDoc.data().photoURL);
        }

        // Fetch application counts
        const applicationsQuery = query(
          collection(db, "applications"),
          where("student", "==", userId)
        );
        const applicationsSnapshot = await getDocs(applicationsQuery);
        const applications = applicationsSnapshot.docs.map(doc => doc.data());
        const shortlisted = applications.filter(application => application.status.toLowerCase() === 'shortlisted');
        setApplicationsCount(applications.length);
        setShortlistedCount(shortlisted.length);
      } catch (e) {
        console.error("Error fetching user data: ", e);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  const openChat = async () => {
    try {
      let chat;
      const exists = await getDocs(
        query(
          collection(db, "chats"),
          where("chatOf", "==", user?.uid),
          where("chatWith", "==", userId)
        )
      );

      if (exists.empty) {
        const newChat = await addDoc(collection(db, "chats"), {
          chatOf: user?.uid,
          chatWith: userId,
        });

        chat = newChat.id;
      } else {
        exists.forEach(doc => {
          chat = doc.id;
        });
      }

      window.open(`/employer/c/${chat}`);
    } catch (e) {
      console.log(e);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className="relative flex md:flex-row flex-col p-4 md:p-6 bg-white shadow-lg rounded-lg min-w-full md:min-w-[40rem] md:max-h-[15rem] max-w-screen-sm items-center">
      <div className="flex items-center">
        <img
          src={photoURL || defaultUser}
          style={{
            objectFit: "contain",
            width: "10em",
            height: "10em",
            borderRadius: "50%",
            border: "1px solid lightgray"
          }}
          alt="Avatar"
        />
        <div className="ml-4">
          <p className="text-lg font-semibold">{userDetails?.name || "N/A"}</p>
          <p className="text-gray-600 text-sm">{userDetails?.title || "N/A"}</p>
          <div className="flex flex-col justify-center items-center md:items-start">
            <div className="flex w-full justify-between text-sm text-gray-600">
              <div className='mr-4'>
                <p className="text-gray-600">{applicationsCount} Applications</p>
              </div>
              <p className="text-gray-600">{shortlistedCount} Shortlisted</p>
            </div>
            <div className="flex mt-2 space-x-2">
              <Link
                to={`/resume/${userId}`}
                className="p-2 bg-blue-500 text-white rounded-md text-center"
              >
                View Resume
              </Link>
              <button
                onClick={openChat}
                className="p-2 bg-green-500 text-white rounded-md text-center"
              >
                Chat
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
