import React, { useEffect, useState } from "react";
import EmployerNav from "./Components/EmployerNav";
import {
	ArrowLeftSharp,
	Article,
	Chat,
	Delete,
	List,
	PlusOne,
} from "@mui/icons-material";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
	TableContainer,
	CircularProgress,
	Table,
	TableHead,
	TableRow,
} from "@mui/material";
import {
	addDoc,
	collection,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import Chats from "../../Components/Chats";
import useUser from "../../Utils/useUser";

export default function Applications() {
	const [queryParams] = useSearchParams();
	const { id } = useParams();
	const [filter, setFilter] = useState("all");
	const [alert, setAlert] = useState();
	const [loading, setLoading] = useState(false);
	const [statusLoading, setStatusLoading] = useState(false);

	const [user, authChecked] = useUser();

	const [data, setData] = useState([]);

	const fetchData = async () => {
		try {
			setLoading(true);
			let newData = [];

			// Initialize the base query
			let baseQuery = collection(db, "applications");

			// Create an array to store all the conditions
			let conditions = [where("internship", "==", id)];

			// Apply filters to the query using an array
			if (filter !== "all") {
				if (filter === "shortlisted") {
					conditions.push(where("status", "==", "Shortlisted"));
				} else if (filter === "hired") {
					conditions.push(where("status", "==", "Hired"));
				} else if (filter === "rejected") {
					conditions.push(where("status", "==", "Rejected"));
				} 
				} else if (filter === "all") {
				if (conditions.length > 1) {
					conditions = [where("internship", "==", id)];
				} 
			}	

			// Apply all conditions to the base query
			baseQuery = query(baseQuery, ...conditions);

			let reqDocs = await getDocs(baseQuery);

			if (!reqDocs.empty) {
				const fetchPromises = reqDocs.docs.map(async (doc) => {
					if (!data.some((item) => item.id === doc.id)) {
						try {
							const fetchStudent = getDocs(
							query(
								collection(db, "other_details"),
								where("student", "==", doc.data().student)
							)
							);
							const fetchSkills = getDocs(
							query(
								collection(db, "student_skills"),
								where("student", "==", doc.data().student)
							)
							);

							const [studentSnapshot, skillsSnapshot] = await Promise.all([
								fetchStudent,
								fetchSkills,
							]);

							const skillsData = skillsSnapshot.docs.map(
								(skill) => skill.data().skills
							);

							newData.push({
								data: doc.data(),
								id: doc.id,
								student: studentSnapshot.docs[0].data(),
								skills: skillsData,
							});
						} catch (error) {
							console.error("Error fetching student data:", error);
						}
					}
				});

				await Promise.all(fetchPromises);
			}

			setData(newData);
		} catch (e) {
			console.error("Error in fetchData:", e);
			setAlert("An error occurred!");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [filter]);

	const setApplicationStatus = async (set, id, e, name, color) => {
		e.preventDefault();

		try {
			setStatusLoading(true);
			e.target.disabled = true;
			e.target.style.backgroundColor = "gray";

			const ref = collection(db, "applications");
			const docs = await getDocs(query(ref, where("student", "==", id)));

			docs.forEach(async (doc) => {
				updateDoc(doc.ref, {
					status: set,
				})
					.then(() => {
						window.alert(`Status updated for ${name}`);
					})
					.catch((e) => {
						console.log(e);
						throw e;
					});
			});

			return;
		} catch (e) {
			console.log(e);
			return;
		} finally {
			setStatusLoading(false);
			e.target.style.backgroundColor = color;
		}
	};

	const openChat = async (student) => {
		try {
			var chat;
			const exists = await getDocs(
				query(
					collection(db, "chats"),
					where("chatOf", "==", user?.uid),
					where("chatWith", "==", student),
				),
			);

			if (exists.empty) {
				const new_chat = await addDoc(collection(db, "chats"), {
					chatOf: user?.uid,
					chatWith: student,
				});

				chat = new_chat.id;
			} else {
				exists.forEach((doc) => {
					chat = doc.id;
				});
			}

			window.open(`/employer/c/${chat}`);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchData();
	}, [filter]);

	useEffect(() => {
		setTimeout(() => {
			setAlert();
		}, 5000);
	}, [alert]);

	return (
		<div>
			<EmployerNav />
			<Link
				to={"/employer/dashboard"}
				className="m-4 flex items-center gap-2 font-semibold w-max"
			>
				<ArrowLeftSharp />
				Go back
			</Link>

			<div className="p-10">
				<div className="flex flex-col gap-2 p-5 border-1p5 border-gray-300 mb-5">
					<p className="text-lg font-semibold">
						{queryParams.get("title")}
					</p>
					<p>{queryParams.get("desc")}</p>
					<div className="flex flex-wrap justify-evenly max-w-[50em]">
						<p className="text-gray-400 font-semibold">
							Rs. {queryParams.get("stipend")}
						</p>
						<p className="text-gray-400 font-semibold">
							<span className=" font-normal">Work Type:</span>{" "}
							{queryParams.get("type")}
						</p>
						<p className="text-gray-400 font-semibold">
							<span className=" font-normal">Work Hours:</span>{" "}
							{queryParams.get("hours")}
						</p>
					</div>
				</div>

				<div className="flex items center gap-3 w-full">
					<button
						className={`p-2 text-center w-full bg-gray-200 border-gray-500 font-semibold text-sm ${
							filter === "all"
								? "border-b-2 shadow-md"
								: "border-0"
						}`}
						onClick={() => {
							setFilter("all");
						}}
					>
						All
					</button>
					<button
						className={`p-2 text-center w-full bg-gray-200 border-gray-500 font-semibold text-sm ${
							filter === "shortlisted"
								? "border-b-2 shadow-md"
								: "border-0"
						}`}
						onClick={() => {
							setFilter("shortlisted");
						}}
					>
						Shortlisted
					</button>
					<button
						className={`p-2 text-center w-full bg-gray-200 border-gray-500 font-semibold text-sm ${
							filter === "hired"
								? "border-b-2 shadow-md"
								: "border-0"
						}`}
						onClick={() => {
							setFilter("hired");
						}}
					>
						Hired
					</button>
					<button
						className={`p-2 text-center w-full bg-gray-200 border-gray-500 font-semibold text-sm ${
							filter === "rejected"
								? "border-b-2 shadow-md"
								: "border-0"
						}`}
						onClick={() => {
							setFilter("rejected");
						}}
					>
						Rejected
					</button>
				</div>

				<div className="w-full mt-5">
					<TableContainer
						sx={{
							border: "1px solid lightgray",
							background: "#fcfcfc",
							padding: "1rem",
						}}
					>
						{!loading && data.length > 0 ? (
							<Table>
								<TableHead>
									{data &&
										data.map((application, index) => (
											<>
												<TableRow
													key={application?.id}
													className="flex flex-col"
												>
													<b
														className={`text-lg ${
															application?.data
																?.status ==
															"Rejected"
																? "text-red-700"
																: application
																		?.data
																		?.status ==
																  "Hired"
																? "text-blue-500"
																: "text-green-500"
														}`}
													>
														{
															application?.student
																.name
														}{" "}
														<span className="font-normal text-xs">
															{
																application
																	?.data
																	?.status
															}
														</span>
													</b>
													<div className="max-w-[18em] flex flex-wrap gap-1 p-1 border-1 border-gray-300">
														<p>
															{application?.skills?.map(
																(
																	skill,
																	index,
																) => {
																	return (
																		<p
																			key={
																				index
																			}
																		>
																			{
																				skill
																			}{" "}
																			{application
																				?.skills
																				?.length >
																				1 &&
																				"|"}
																		</p>
																	);
																},
															)}
														</p>
													</div>
													<p className="mt-1">
														{
															application?.data
																?.why_should_we_hire_you
														}
													</p>
													<div className="flex w-full items-center justify-evenly gap-1 mt-2 font-semibold">
														<Link
															to={`/resume/${application?.data?.student}`}
															className="w-full p-2 bg-gray-200 flex items-center justify-center gap-1 "
														>
															Resume <Article />
														</Link>
														<button
															onClick={(e) => {
																setApplicationStatus(
																	"Shortlisted",
																	application
																		?.data
																		?.student,
																	e,
																	application
																		?.student
																		.name,
																	"rgb(254 240 138 / var(--tw-bg-opacity))",
																);
															}}
															className="w-full p-2 bg-yellow-200 flex items-center justify-center gap-1 "
														>
															Shortlist <List />
														</button>
														<button
															onClick={(e) => {
																setApplicationStatus(
																	"Hired",
																	application
																		?.data
																		?.student,
																	e,
																	application
																		?.student
																		.name,
																	"rgb(187 247 208 / var(--tw-bg-opacity))",
																);
															}}
															className="w-full p-2 bg-green-200 flex items-center justify-center gap-1 "
														>
															Hire <PlusOne />
														</button>
														<button
															onClick={(e) => {
																setApplicationStatus(
																	"In-Touch",
																	application
																		?.data
																		?.student,
																	e,
																	application
																		?.student
																		.name,
																	"rgb(191 219 254 / var(--tw-bg-opacity))",
																);
																openChat(
																	application
																		?.data
																		?.student,
																);
															}}
															className="w-full p-2 bg-blue-200 flex items-center justify-center gap-1 "
														>
															Chat <Chat />
														</button>
														<button
															onClick={(e) => {
																setApplicationStatus(
																	"Rejected",
																	application
																		?.data
																		?.student,
																	e,
																	application
																		?.student
																		.name,
																	"rgb(254 202 202 / var(--tw-bg-opacity))",
																);
															}}
															className="w-full p-2 bg-red-200 flex items-center justify-center gap-1 "
														>
															Reject <Delete />
														</button>
													</div>
												</TableRow>
												<br></br>
											</>
										))}
								</TableHead>
							</Table>
						) : (
							<div className="p-10 text-lg font-semibold text-center">No applicants found.</div>
						)}
					</TableContainer>
				</div>
			</div>
			<Chats />
		</div>
	);
}
