import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AuthContainer from "../Components/AuthContainer";
import { Button, Input } from "@mui/joy";
import { Mail, Password } from "@mui/icons-material";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { auth, db } from "../Config/firebase";
import { signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { collection, getDocs, where, query } from "firebase/firestore";
import SocialsAuth from "../Components/socials-auth";

export default function Auth() {
  const navigate = useNavigate();
  const [search_query] = useSearchParams();
  const tab = search_query.get("tab");

  const [studentLogin, setStudentLogin] = useState(true);
  const [showPwd, setShowPwd] = useState(false);
  const [loading, setLoading] = useState(false);

  const [semail, setsemail] = useState();
  const [spwd, setspwd] = useState();

  const [eemail, seteemail] = useState();
  const [epwd, setepwd] = useState();

  const [alert, setAlert] = useState();

  const setCookie = (name, value, days) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  useState(() => {
    if (tab === "student") {
      setStudentLogin(true);
    } else if (tab === "employer") {
      setStudentLogin(false);
    } else {
      setStudentLogin(true);
    }
  }, tab);

  const signin_stu = async (e) => {
  e.preventDefault();

  setLoading(true);

  var isEmployer = false;

  if (spwd.length >= 6) {
    try {
      const resp = await signInWithEmailAndPassword(auth, semail, spwd);

      if (resp.code === "auth/invalid-credential") {
        setAlert("Invalid Credentials");
        return;
      }

      const userDocRef = collection(db, "Users");
      const userDocs = await getDocs(query(userDocRef, where("UserID", "==", resp.user.uid)));

      userDocs.forEach((doc) => {
        isEmployer = doc.data()?.Employer;
      });

      if (isEmployer) {
        setAlert("Student with the email does not exist!");
        await signOut(auth);
        return;
      }

      if (resp.user.emailVerified) {
        setCookie("authToken", resp.user.stsTokenManager.accessToken, 7);
        navigate("/");
      } else {
        window.alert("Please verify your email before logging in.");
        await signOut(auth);
      }
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setAlert("User with email does not exist!");
      } else if (error.code === "auth/invalid-credential") {
        setAlert("Incorrect credentials");
      } else if (error.code === "auth/too-many-requests") {
        setAlert("Access to this account has been temporarily disabled due to many failed login attempts.");
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  } else {
    setAlert("Password must be at least 6 characters");
    setLoading(false);
  }
};


  const signin_employee = async (e) => {
  e.preventDefault();
  setLoading(true);

  var isEmployer = false;

  try {
    const resp = await signInWithEmailAndPassword(auth, eemail, epwd);

    if (!resp.user.emailVerified) {
      window.alert("Please verify your email before logging in.");
      setLoading(false);
      return;
    }

    const userDocs = await getDocs(query(collection(db, "Users"), where("UserID", "==", resp.user.uid)));

    if (userDocs.empty) {
      setAlert("Internal Server Error");
      throw new Error(`Document with where clause of UserID === ${resp.user.uid} not found!`);
    }

    userDocs.forEach((doc) => {
      isEmployer = doc.data()?.Employer;
    });

    if (isEmployer) {
      setCookie("authToken", resp.user.stsTokenManager.accessToken, 7);
      navigate("/employer/dashboard");
    } else {
      setAlert("Employer not found!");
      await signOut(auth);
    }
  } catch (error) {
    if (error.code === "auth/invalid-credential") {
      setAlert("Invalid credentials. Please try again.");
    } else if (error.code === "auth/too-many-requests") {
      setAlert("Too many tries!");
    } else if (error.code === "auth/user-not-found") {
      setAlert("User with email does not exist");
    } else {
      setAlert(`Internal Server Error! ${error.message}`);
      console.log(error);
    }
  } finally {
    setLoading(false);
  }
};


  useEffect(() => {
    setTimeout(() => {
      setAlert("");
    }, 5000);
  }, [alert]);

  return (
    <>
      <Helmet>
        <html lang="en"/>
        <title>
          AccessCareer | Internships | Login and Registration
        </title>
        <meta
          name="description"
          content="Take your first step towards your dream career with AccessCareer! We offer a curated selection of internships across diverse fields, exclusively for college students and recent graduates. No more sifting through irrelevant job boards - find your perfect internship match on AccessCareer."
        />
        <meta
          name="keywords"
          content="Software engineering, Mechanical engineering, Electrical engineering, Chemical engineering, Civil engineering, Biomedical engineering, Data science, Cybersecurity, Artificial intelligence, Machine learning, Cloud computing, Software development, Finance, Marketing, Management, Human resources, Operations, Consulting, Graphic design, Product design, UX/UI design, Web design, Fashion design, Interior design, Internship, College internships, Recent graduate internships, Entry-level jobs, Job search, Career development"
        />
        <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
      </Helmet>

      <AuthContainer
        type={studentLogin}
        setType={setStudentLogin}
        alert={alert}
      >
        {studentLogin ? (
          <form
            method="post"
            onSubmit={signin_stu}
            className="flex flex-col gap-3 w-full"
          >
            <div>
              <label htmlFor="" className="font-semibold text-p1">
                E-mail
              </label>
              <Input
                placeholder="E-mail"
                name="email"
                startDecorator={<Mail />}
                onChange={(e) => {
                  setsemail(e.target.value);
                }}
                disabled={loading}
              />
            </div>
            <div>
              <label htmlFor="" className="font-semibold text-p1">
                Password
              </label>
              <Input
                placeholder="Password"
                type={showPwd ? "text" : "password"}
                name="password"
                startDecorator={<Password />}
                onChange={(e) => {
                  setspwd(e.target.value);
                }}
                endDecorator={
                  <>
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setShowPwd(!showPwd);
                      }}
                    >
                      {showPwd ? <BsEye /> : <BsEyeSlash />}
                    </span>
                  </>
                }
                disabled={loading}
              />
            </div>
            <Button type="submit" disabled={loading}>
              {!loading ? "Login" : <CircularProgress />}
            </Button>
            <Link
              to={"/register/se/student"}
              className="flex w-full justify-center p-1 rounded-md bg-gray-100 hover:bg-gray-200 transition-all duration-200"
            >
              Create New Account
            </Link>
            <SocialsAuth />
          </form>
        ) : (
          <form
            action=""
            className="flex flex-col gap-3 w-full"
            onSubmit={signin_employee}
          >
            <div>
              <label htmlFor="" className="font-semibold text-p1">
                E-mail
              </label>
              <Input
                placeholder="E-mail"
                startDecorator={<Mail />}
                onChange={(e) => {
                  seteemail(e.target.value);
                }}
                disabled={loading}
              />
            </div>
            <div>
              <label htmlFor="" className="font-semibold text-p1">
                Password
              </label>
              <Input
                placeholder="Password"
                type={showPwd ? "text" : "password"}
                startDecorator={<Password />}
                onChange={(e) => {
                  setepwd(e.target.value);
                }}
                endDecorator={
                  <>
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setShowPwd(!showPwd);
                      }}
                    >
                      {showPwd ? <BsEye /> : <BsEyeSlash />}
                    </span>
                  </>
                }
                disabled={loading}
              />
            </div>
            <Button type="submit" disabled={loading}>
              {!loading ? "Login" : <CircularProgress />}
            </Button>
            <Link
              to={"/register/se/employer"}
              className="flex w-full justify-center p-1 rounded-md bg-gray-200 hover:bg-gray-100 transition-all duration-200"
            >
              Register Your Company
            </Link>
            <SocialsAuth />
          </form>
        )}
      </AuthContainer>
    </>
  );
}