import React, { useState, useEffect } from 'react';
import { MdEdit } from 'react-icons/md';
import { TbPhotoEdit } from "react-icons/tb";
import { CircularProgress } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../Config/firebase";
import { collection, getDocs, query, updateDoc, where, doc, getDoc, setDoc } from "firebase/firestore";
import useUser from "../../Utils/useUser";
import defaultUser from "../../Assets/user.jpg";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function ProfileCard({ from }) {
  const navigate = useNavigate();
  const [user, authChecked] = useUser();
  const [dpModal, setDpModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [editAboutMe, setEditAboutMe] = useState(false);
  const [edit, setEdit] = useState(false);
  const [addMask, setAddMask] = useState(false);
  const [nName, setNname] = useState('');
  const [nTitle, setNtitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [other_details, setOther_details] = useState({});
  const [alert, setAlert] = useState();
  const [applicationsCount, setApplicationsCount] = useState(0);
  const [shortlistedCount, setShortlistedCount] = useState(0);
  const [dpLoading, setDpLoading] = useState(false);
  const [newdp, setNewDp] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (user) {
      fetchData();
      fetchApplicationCounts();
      fetchProfilePicture(); // Fetch profile picture
    }
  }, [user]);

  const handleHover = () => {
    setAddMask(!addMask);
  };

  const handleClose = () => {
    setOpen(false);
    setEditAboutMe(false);
  };

  const fetchData = async () => {
    if (user) {
      const uid = user?.uid;
      try {
        setLoading(true);
        const basicDetailsQuery = await getDocs(
          query(
            collection(db, "other_details"),
            where("student", "==", uid)
          )
        );
        basicDetailsQuery.forEach((doc) => {
          const basicDetailsData = doc.data();
          setOther_details(basicDetailsData);
          setNname(basicDetailsData.name);
          setNtitle(basicDetailsData.title);
        });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchApplicationCounts = async () => {
    if (user) {
      const uid = user?.uid;
      try {
        const applicationsQuery = query(
          collection(db, "applications"),
          where("student", "==", uid)
        );

        const applicationsSnapshot = await getDocs(applicationsQuery);
        const applications = applicationsSnapshot.docs.map(doc => doc.data());

        const shortlisted = applications.filter(application => application.status.toLowerCase() === 'shortlisted');

        setApplicationsCount(applications.length);
        setShortlistedCount(shortlisted.length);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const fetchProfilePicture = async () => {
    if (user) {
      const uid = user?.uid;

      setDpLoading(true);
      
      try {
        const docRef = doc(db, "userLogos", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setNewDp(data.photoURL);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setDpLoading(false);
      }
    }
  };

  const editBasicDetails = async (e) => {
    e.preventDefault();
    if (!nName || !nTitle) {
      setAlert("Fill out all the fields");
      return;
    }
    setEditLoading(true);
    try {
      const uid = user?.uid;
      const docs = await getDocs(
        query(
          collection(db, "other_details"),
          where("student", "==", uid)
        )
      );
      docs.forEach(async (doc) => {
        await updateDoc(doc.ref, { name: nName, title: nTitle }).then(() => {
          fetchAndReset();
          setEdit(false); // Add this line to switch back to the normal view
          handleClose();
        });
      });
    } catch (e) {
      console.log(e);
    } finally {
      setEditLoading(false);
    }
  };

  const fetchAndReset = () => {
    fetchData();
    fetchApplicationCounts();
    resetBasicDetails();
  };

  const resetBasicDetails = () => {
    setNname(other_details?.name);
    setNtitle(other_details?.title);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setDpLoading(true);
      try {
        setFile(file); // Update the file state with the selected file
        const url = await updateDP(file, user.uid); // Call updateDP to upload the file
        console.log("Uploaded photo URL:", url);
        if (url) {
          setNewDp(url); // Update state with the new URL if successful
          console.log("New DP set:", url);
        }
      } catch (e) {
        console.error('Error handling file change:', e);
      } finally {
        setDpLoading(false);
      }
    }
  };

  const updateDP = async (file, uid) => {
    const storage = getStorage();
    if (!file) throw new Error("No file selected");
    const storageRef = ref(storage, `userLogos/${uid}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    await setDoc(doc(db, "userLogos", uid), {
      photoURL: downloadURL,
      uploadedAt: new Date(),
      userId: uid
    });
    return downloadURL;
  };

  useEffect(() => {
    console.log([dpLoading, newdp]);
  }, [dpLoading])

  return (
    <div className={`relative flex md:flex-row flex-col p-4 mdp-8 bg-white ${from === 'e' ? " border-1p5 border-gray-300" : "shadow-md"} rounded-md min-w-full md:min-w-[40rem] md:max-h-[15rem] max-w-screen-sm items-center`}>
      {from === 'p' && (
        <button className="absolute right-4" onClick={() => setEdit(!edit)}>
          <MdEdit />
        </button>
      )}

      <input type="file" style={{ display: "none" }} id="new_dp" onChange={(e) => {
        if (e.target.value) {
          handleFileChange(e);
        }
      }} />
      <div className="relative">
        {from === "p" && (
          <button className="absolute right-0 bottom-6 bg-white rounded-full p-1 shadow-sm" onClick={() => document.getElementById("new_dp").click()}>
            <TbPhotoEdit />
          </button>
        )}
        {dpLoading ? (
              <CircularProgress size="20" style={styles.dpStyle} />
          ) : (
              <img
                  src={newdp || user?.photoURL || defaultUser}
                  style={styles.dpStyle}
                  alt="Avatar"
                  onMouseOver={handleHover}
                  onMouseOut={handleHover}
              />
          )
        }
      </div>
      <div className="ml-4 flex flex-col justify-center items-center md:items-start">
        {!edit ? (
          <>
            <p className="text-md font-semibold">
              {loading ? (
                <CircularProgress size="20" />
              ) : (
                other_details?.name || user?.displayName || "N/A"
              )}
            </p>
            <p className="text-sm text-gray-500">
              {loading ? (
                <CircularProgress size="10" />
              ) : (
                other_details?.title || user?.email || "N/A"
              )}
            </p>
            <p className="text-sm text-gray-500">
              Applications: {applicationsCount} | Shortlisted: {shortlistedCount}
            </p>
          </>
        ) : (
          <form onSubmit={editBasicDetails}>
            <label>
              <p className="text-sm text-gray-500">Name:</p>
              <input
                type="text"
                value={nName}
                onChange={(e) => setNname(e.target.value)}
                className="border border-gray-300 p-1 rounded-md"
              />
            </label>
            <label>
              <p className="text-sm text-gray-500">Title:</p>
              <input
                type="text"
                value={nTitle}
                onChange={(e) => setNtitle(e.target.value)}
                className="border border-gray-300 p-1 rounded-md"
              />
            </label>
            {alert && <p className="text-red-500">{alert}</p>}
            <div className="flex gap-2 mt-2">
              <button
                type="button"
                onClick={() => setEdit(false)}
                className="bg-gray-500 text-white px-2 py-1 rounded-md"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-2 py-1 rounded-md"
                disabled={editLoading}
              >
                {editLoading ? <CircularProgress size="15" /> : "Save"}
              </button>
            </div>
          </form>
        )}
        {other_details?.role && (
          <div className="mt-2 flex flex-wrap gap-2">
            {other_details.role.split(',').map((r, index) => (
              <span key={index} className="bg-blue-100 text-blue-500 px-2 py-1 rounded-md text-xs">
                {r}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

const styles = {
  dpStyle: {
    width: 75,
    height: 75,
    borderRadius: "50%"
  }
};
