import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { db } from "../../../Config/firebase";
import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import defaultUser from "../../../Assets/user.jpg";

export default function StuProfileCard({ userId }) {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [photoURL, setPhotoURL] = useState(defaultUser);
  const [applicationsCount, setApplicationsCount] = useState(0);
  const [shortlistedCount, setShortlistedCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Query the other_details collection to find the document with the student field equal to userId
        const q = query(collection(db, "other_details"), where("student", "==", userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          setUserDetails(userDoc.data());
        }

        // Get the profile photo from the userLogos collection
        const profilePicDoc = await getDoc(doc(db, "userLogos", userId));
        if (profilePicDoc.exists()) {
          setPhotoURL(profilePicDoc.data().photoURL);
        }

        // Fetch application counts
        const applicationsQuery = query(
          collection(db, "applications"),
          where("student", "==", userId)
        );
        const applicationsSnapshot = await getDocs(applicationsQuery);
        const applications = applicationsSnapshot.docs.map(doc => doc.data());
        const shortlisted = applications.filter(application => application.status.toLowerCase() === 'shortlisted');
        setApplicationsCount(applications.length);
        setShortlistedCount(shortlisted.length);
      } catch (e) {
        console.error("Error fetching user data: ", e);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className="relative flex md:flex-row flex-col p-4 md:p-8 bg-white shadow-md rounded-md min-w-full md:min-w-[40rem] md:max-h-[15rem] max-w-screen-sm items-center">
      <div className="flex items-center">
        <img
          src={photoURL || defaultUser}
          style={{
            objectFit: "contain",
            maxWidth: "10em",
            maxHeight: "10em",
            minWidth: "10em",
            minHeight: "10em",
            borderRadius: "50%",
            border: "0.5px solid lightgray"
          }}
          alt="Avatar"
        />
        <p className="ml-4 text-md font-semibold">
          {userDetails?.name || "N/A"}
        </p>
      </div>
      <div className="ml-4 flex flex-col justify-center items-center md:items-start">
        <p className="text-gray-500">
          {userDetails?.title || ""}
        </p>
        <div className="flex w-full justify-between text-sm text-gray-600">
          <div className='mr-4'>
            <p className="text-gray-600">{applicationsCount} Applications </p>
          </div>
          <p className="text-gray-600"> {shortlistedCount} Shortlisted</p>
        </div>
      </div>
    </div>
  );
}