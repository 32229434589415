import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.footerContent}>
        <p>© {new Date().getFullYear()} AccessCareer. All rights reserved.</p>
        <p>Last updated: August 2, 2024</p>
        <nav>
          <ul style={styles.navList}>
            <li><Link to="/">Home | </Link></li>
            <li><Link to="/legals">Legals | </Link></li>
            <li><Link to="/">Contact Us</Link></li>
          </ul>
        </nav>
        <p>For legal inquiries, please contact us at <a href="mailto:support@AccessCareer.com">support@AccessCareer.com</a></p>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    padding: '20px',
    backgroundColor: '#f8f9fa',
    textAlign: 'center',
    borderTop: '1px solid #e1e1e1',
  },
  footerContent: {
    maxWidth: '1200px',
    margin: '0 auto',
  },
  navList: {
    listStyle: 'none',
    padding: 0,
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    gap: "10px"
  },
  navListItem: {
    margin: '0 10px',
  },
};

export default Footer;
