import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../Config/firebase';

const usePosts = (userId) => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    if (!userId) return;

    const fetchPosts = async () => {
      try {
        const q = query(collection(db, 'posts'), where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        const fetchedPosts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log('Fetched posts:', fetchedPosts); // Debugging line
        setPosts(fetchedPosts);
      } catch (error) {
        console.error('Error fetching posts: ', error);
        setPosts([]); // Ensure posts is an array even if there's an error
      }
    };

    fetchPosts();
  }, [userId]);

  return posts;
};

export default usePosts;
