import React, { useEffect, useState } from "react";
import EmployerNav from "./Components/EmployerNav";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
	Typography,
	Modal,
	CircularProgress,
	imageListClasses,
} from "@mui/material";
import { Input, LinearProgress, Textarea, Button, Alert } from "@mui/joy";
import {
	Assignment,
	Cancel,
	CancelOutlined,
	Close,
	Delete,
	Edit,
	EditAttributes,
	Margin,
	UploadFileOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
	collection,
	getDocs,
	where,
	query,
	updateDoc,
	doc,
	getDoc,
	setDoc,
	deleteDoc
} from "firebase/firestore";
import { auth, db, storage } from "../../Config/firebase";
import { indianStates, industryTypes } from "../../Exports";
import useUser from "../../Utils/useUser";
import CompoanyFooter from "./Components/CompanyFooter";
import Chats from "../../Components/Chats";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { v4 } from "uuid";

export default function Dashboard() {
	const [loading, setLoading] = useState(true);
	const [editLoading, setEditLoading] = useState(false);
	const [newDescModal, setDescModalState] = useState(false);
	const [editProfile, setEditProfile] = useState(false);
	const [editInternshipModal, setEditInternshipModal] = useState(false);
	const filereader = new FileReader();
	const [alert, setAlert] = useState();
	const [user, authChecked] = useUser();
	const [imgUpload, setImgUpload] = useState(null);

	const [companyData, setCompanyData] = useState({});
	const [compInternships, setCompInternships] = useState([]);

	const [desc, setDesc] = useState("");

	const [nName, setNname] = useState("");
	const [nTagsline, setNTagline] = useState("");
	const [noOfEmps, setEmps] = useState("");
	const [nLocation, setNLocation] = useState("");
	const [nIndustry, setNIndustry] = useState("");
	const [nDesc, setNDesc] = useState("");
	const [nimageList, setNimageList] = useState([]);

	const [nTitle, setNtitle] = useState("");
	const [nStipend, setNstipend] = useState("");
	const [nWorkType, setNworkType] = useState("");
	const [nOpenings, setNopening] = useState("");
	const [nWorkHours, setNworkHours] = useState("");
	const [nDescI, setNDescI] = useState("");
	const [nDuration, setNduration] = useState("");
	const [internshipId, setInternshipID] = useState("");

	const [nlogo, setNlogo] = useState();
	const [nlogoData, setNlogoData] = useState();

	const imageListRef = ref(storage, "images/");

	const handleNoOfEmpsChange = (e, val) => {
		setEmps(val);
	};

	const handleDurationChange = (e, val) => {
		setNduration(val);
	};

	const handleWorkHourChange = (e, val) => {
		setNworkHours(val);
	};

	const handleWorkTypeChange = (e, val) => {
		setNworkType(val);
	};

	const fetchLogoData = async (userId) => {
		try {
			// Query to find the document where the 'company' field matches the userId
			const q = query(collection(db, "employers"), where("company", "==", userId));
			const querySnapshot = await getDocs(q);
			let logo = null;
			
			querySnapshot.forEach((doc) => {
				logo = doc.data().logo;
			});
			
			return logo;
		} catch (error) {
			console.error("Error fetching logo data:", error);
			return null;
		}
	};
	

	const addDesc = async (e) => {
		e.preventDefault();

		try {
			const uid = user?.uid;

			const querySnapshot = await getDocs(
				query(collection(db, "employers"), where("company", "==", uid)),
			);

			const updatePromises = querySnapshot.docs.map(async (doc) => {
				const ref = doc.ref;
				try {
					await updateDoc(ref, {
						description: desc,
					});
					console.log("Document updated successfully");
					return true;
				} catch (updateError) {
					console.error("Error updating document:", updateError);
					return false;
				}
			});

			const results = await Promise.all(updatePromises);

			if (results.every((result) => result === true)) {
				fetchUserInfo();
				resetEditDetails();
			} else {
				// Handle partial updates or failure here
			}
		} catch (error) {
			console.error("Error updating documents:", error);
		}
	};

	const fullProfileEdit = async (e) => {
		e.preventDefault();
	
		if (
			!nDesc?.length > 0 ||
			!nName?.length > 0 ||
			!nLocation?.length > 0 ||
			!nIndustry?.length > 0 ||
			!nTagsline?.length > 0 ||
			!noOfEmps?.length
		) {
			setAlert("Fill out all the fields");
			return;
		}
	
		try {
			const uid = user?.uid;
			if (!uid) {
				throw new Error("User is not authenticated");
			}
	
			const downloadURL = nlogoData;
	
			const querySnapshot = await getDocs(query(collection(db, "employers"), where("company", "==", uid)));
	
			if (querySnapshot.empty) {
				throw new Error("No matching documents found");
			}
	
			const updatePromises = querySnapshot.docs.map(async (doc) => {
				const updatedData = {
					name: nName,
					noOfEmps: noOfEmps,
					location: nLocation,
					industry: nIndustry,
					description: nDesc,
					tagline: nTagsline,
					logo: downloadURL,
				};
				return updateDoc(doc.ref, updatedData);
			});
	
			await Promise.all(updatePromises);
			setEditProfile(false);
			fetchUserInfo();
			window.alert("Successfully updated.");
			//window.location.reload();  // Refresh the page
		} catch (error) {
			console.error("Error updating documents:", error);
			window.alert("Internal server error");
		}
	};
	

	const fetchUserInfo = async () => {
		setCompInternships([]);

		if (user) {
			setLoading(true);

			const uid = await user?.uid;

			try {
				const collectionRef = collection(db, "employers");
				const internshipCollectionRef = collection(db, "internships");

				const collectionQuery = where("company", "==", uid);
				const internshipQuery = where("employer", "==", uid);

				const querySnapshot = await getDocs(
					query(collectionRef, collectionQuery),
				);
				const internshipSnap = await getDocs(
					query(internshipCollectionRef, internshipQuery),
				);

				querySnapshot.forEach((doc) => {
					setCompanyData(doc.data());
					//setNname(doc.data().established);
					setNname(doc.data().name);

					setNDesc(doc.data().description);
					setNTagline(doc.data().tagline); 
				});
				internshipSnap.forEach(async (doc) => {
					setCompInternships((prev) => [
						...prev,
						{ data: doc.data(), id: doc.id },
					]);
				});
			} catch (e) {
				console.log(e);
			} finally {
				resetEditDetails();
				setLoading(false);
			}
		}
	};

	const handleInternshipEdit = async () => {
		const uid = await user?.uid;

		try {
			const updatedInternshipData = {
				title: nTitle,
				stipend: nStipend,
				duration: nDuration,
				workHours: nWorkHours,
				workType: nWorkType,
				description: nDescI,
				openings: nOpenings,
				duration: nDuration,
			};

			const internshipDocRef = doc(db, "internships", internshipId);

			await updateDoc(internshipDocRef, updatedInternshipData);
			setEditInternshipModal(false);
		} catch (error) {
			console.error("Error updating internship:", error);
		} finally {
			fetchUserInfo();
		}
	};

	const resetEditDetails = () => {
		try {
			if (companyData && companyData?.length > 0) {
				setNLocation(companyData?.location || ""); // Provide a default value if companyData.location is null or undefined
				setEmps(companyData?.noOfEmps || ""); // Provide a default value if companyData.noOfEmps is null or undefined
				setNIndustry(companyData?.industry || ""); // Provide a default value if companyData.industry is null or undefined
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleFileChange = async (e) => {
		const file = e.target.files[0];
	
		if (file) {
			if (!file.type.startsWith('image/')) {
				window.alert("Please select an image file.");
				return;
			}
	
			try {
				const imageRef = ref(storage, `images/${file.name + v4()}`);
				await uploadBytes(imageRef, file);
				const downloadURL = await getDownloadURL(imageRef);
				console.log("Uploaded Image URL:", downloadURL); // Logging the image URL
				setNlogoData(downloadURL); // Update state with the download URL
	
				// Save the logo URL to Firestore
				const uid = user?.uid;
				if (uid) {
					// Query to find the document where the 'company' field matches the userId
					const q = query(collection(db, "employers"), where("company", "==", uid));
					const querySnapshot = await getDocs(q);
					
					querySnapshot.forEach(async (doc) => {
						const logoDocRef = doc.ref;
						await updateDoc(logoDocRef, { logo: downloadURL });
					});
				}
			} catch (error) {
				console.error("Error uploading image:", error);
				window.alert("Error uploading image. Please try again.");
			}
		}
	};
	

	const handleDeleteInternship = async (internshipId) => {
		try {
			await deleteDoc(doc(db, "internships", internshipId));
			// Optionally refresh the list of internships after deletion
			fetchUserInfo();
			window.alert("Internship deleted successfully");
		} catch (error) {
			console.error("Error deleting internship:", error);
			alert("Failed to delete internship");
		}
	};

	useEffect(() => {
		if (companyData.name) {
			setNname(companyData.name);
		}
	}, [companyData.name]);

	useEffect(() => {
		const userId = user?.uid;
		if (userId) {
			fetchLogoData(userId)
				.then((logoData) => {
					if (logoData) {
						setNlogoData(logoData);
					}
				})
				.catch((error) => {
					console.error("Error fetching logo data:", error);
				});
		}
	}, [user, authChecked]);

	useEffect(() => {
		// Function to handle storage change event
		const handleStorageChange = (event) => {
			if (event.key === "nlogoData") {
				setNlogoData(event.newValue); // Update nlogoData when local storage changes
			}
		};

		// Listen for storage events
		window.addEventListener("storage", handleStorageChange);

		// Cleanup
		return () => {
			window.removeEventListener("storage", handleStorageChange);
		};
	}, []);

	useEffect(() => {
		const fetchUserInfoAndImage = async () => {
			const userId = user?.uid;

			// Fetch user information
			fetchUserInfo();

			// Retrieve image URL from local storage
			const storedLogoData = localStorage.getItem("nlogoData");
			if (storedLogoData) {
				setNlogoData(storedLogoData);
			}
		};

		// Call the function to fetch user information and image
		fetchUserInfoAndImage();
	}, [user, authChecked]);


useEffect(() => {
    const fetchLogoData = async (userId) => {
        try {
            // Query to find the document where the 'company' field matches the userId
            const q = query(collection(db, "employers"), where("company", "==", userId));
            const querySnapshot = await getDocs(q);
            let logo = null;

            querySnapshot.forEach((doc) => {
                logo = doc.data().logo;
            });

            return logo;
        } catch (error) {
            console.error("Error fetching logo data:", error);
            return null;
        }
    };

    const userId = user?.uid;
    if (userId) {
        fetchLogoData(userId).then((logoData) => {
            if (logoData) {
                setNlogoData(logoData);
            }
        }).catch((error) => {
            console.error("Error fetching logo data:", error);
        });
    }
}, [user, authChecked]);

	useEffect(() => {
    setNDesc(companyData?.description || "");
    setNname(companyData?.name || "");
    setNLocation(companyData?.location || "");
	setEmps(companyData?.employees || noOfEmps);
    setNIndustry(companyData?.industry || "");
    setNTagline(companyData?.tagline || "");
}, [companyData]);


	// Ensure to save logo data to local storage whenever it changes
	useEffect(() => {
		const storedLogoData = localStorage.getItem("nlogoData");
		if (storedLogoData) {
			setNlogoData(storedLogoData);
		}
	}, []);

	return (
		<div>
			<Helmet>
				<title>AccessCareer - Employer Dashboard</title>
				<meta
					name="description"
					content="Take your first step towards your dream career with AccessCareer! We offer a curated selection of internships across diverse fields, exclusively for college students and recent graduates. No more sifting through irrelevant job boards - find your perfect internship match on AccessCareer."
				/>
				<meta
					name="keywords"
					content="Software engineering, Mechanical engineering, Electrical engineering, Chemical engineering, Civil engineering, Biomedical engineering, Data science, Cybersecurity, Artificial intelligence, Machine learning, Cloud computing, Software development, Finance, Marketing, Management, Human resources, Operations, Consulting, Graphic design, Product design, UX/UI design, Web design, Fashion design, Interior design, Internship, College internships, Recent graduate internships, Entry-level jobs, Job search, Career development"
				/>
				<meta
					http-equiv="Content-Type"
					content="text/html;charset=UTF-8"
				/>
			</Helmet>

			<EmployerNav />
			<div className="flex flex-col justify-center items-center p-10 gap-4">
				<p className="text-lg font-semibold text-left w-full">
					Dashboard
				</p>
				<div className="flex p-5 border-1p5 border-gray-300 w-full">
					{loading ? (
						<div className=" animate-pulse">
							<CircularProgress />
						</div>
					) : (
						<div className="flex flex-col w-full">
							<div className="flex justify-between w-full">
								<div className="flex flex-col">
									{companyData.name && (
										<p className="text-lg font-semibold flex gap-2 items-center">
											
											{companyData?.name}
											{companyData?.tagline && (
												<span className="text-sm font-normal">
													{companyData?.tagline}
												</span>
											)}
										</p>
									)}
									<p>{companyData?.industry}</p>
									<p>
										{companyData?.noOfEmps}
										&nbsp;
										<span className=" text-gray-600">
											Employees
										</span>
									</p>
									<p>{companyData?.location}</p>
								</div>

								<img
                src={nlogoData || "https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.1395880969.1709344800&semt=sph"}
                className="rounded-sm"
                style={{ width: "7em", height: "7em" }}
                alt="Employer logo"
            />
							</div>
							{companyData?.description &&
							companyData?.description?.length > 0 ? (
								<div className="p-2 border-1p5 border-gray-200 w-full mt-3">
									<p
										sx={{
											maxWidth: "100%",
											wordBreak: "break-word",
										}}
									>
										{companyData?.description}
									</p>
								</div>
							) : (
								<>
									{newDescModal ? (
										<div className="p-5 w-full">
											<form onSubmit={addDesc}>
												<Textarea
													placeholder="Write description"
													maxRows={5}
													minRows={3}
													sx={{
														borderBottomLeftRadius: 0,
													}}
													onChange={(e) => {
														setDesc(e.target.value);
													}}
												/>
												<div className="flex w-full gap-2 mt-1">
													<button
														className="w-full"
														onClick={(e) => {
															e.preventDefault();
															setDescModalState(
																!newDescModal,
															);
														}}
													>
														Cancel
													</button>
													<Button
														type="submit"
														className="w-full"
														onClick={addDesc}
													>
														Submit
													</Button>
												</div>
											</form>
										</div>
									) : (
										<Button
											onClick={(e) => {
												e.preventDefault();
												setDescModalState(
													!newDescModal,
												);
											}}
										>
											Write a description
										</Button>
									)}
								</>
							)}
							<div className="w-full">
								<button
									onClick={() => {
										setEditProfile(true);
										//handleFileChange();
										setNlogo();
										// if (nName) {
										//   console.log("Edit button clicked. Enabling edit mode.");
										//   setEditProfile(true);
										// } else {
										//   console.log("Edit button clicked, but nName is empty.");
										// }
									}}
									className={`flex items-center gap-1 mt-2 w-max float-right ${
										!nName ? "text-gray-400" : "text-black"
									}`}
								>
									Edit <Edit fontSize="25" />
								</button>
							</div>
						</div>
					)}
				</div>

				<TableContainer
					sx={{
						border: "1px solid lightgray",
						background: "#fcfcfc",
					}}
				>
					<Table
						className="w-full"
						aria-label="Currently Open Internships"
					>
						<TableHead>
							<TableRow>
								<TableCell sx={{ align: "center" }}>
									Title
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									Stipend
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									Work Type
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									Applicants
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									Openings
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									Action
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							
							{loading ? (
								<CircularProgress className="m-2" />
							) : (
								compInternships &&
								compInternships.length > 0 &&
								compInternships.map((internship, index) => {
									return (
										<TableRow key={index}>
											<TableCell>
												<Link
													to={`/employer/applications/${internship?.id}?title=${internship?.data.title}&stipend=${internship?.data.stipend}&type=${internship?.data.workType}&hours=${internship?.data.workHours}&desc=${internship?.data.description}`}
													className="font-semibold"
												>
													{internship?.data.title}
												</Link>
											</TableCell>
											<TableCell
												sx={{ textAlign: "center" }}
											>
												{internship?.data.stipend}
											</TableCell>
											<TableCell
												sx={{ textAlign: "center" }}
											>
												{internship?.data.workType}
											</TableCell>
											<TableCell
												sx={{ textAlign: "center" }}
											>
												{internship?.data.applicants}
											</TableCell>
											<TableCell
												sx={{ textAlign: "center" }}
											>
												{internship?.data.openings}
											</TableCell>
											<TableCell
												sx={{
													display: "flex",
													justifyContent:
														"space-evenly",
													alignItems: "center",
												}}
											>
												<button 
													title="Delete Internship" 
													onClick={() => handleDeleteInternship(internship?.id)}
												>
													<Delete sx={{ color: "red" }} />
												</button>
												<button
													title="Edit Internship Details"
													onClick={() => {
														setNDescI(
															internship?.data
																.description,
														);
														setNduration(
															internship?.data
																.duration,
														);
														setNopening(
															internship?.data
																.openings,
														);
														setNstipend(
															internship?.data
																.stipend,
														);
														setNworkHours(
															internship?.data
																.workHours,
														);
														setNworkType(
															internship?.data
																.workType,
														);
														setNtitle(
															internship?.data
																.title,
														);
														setInternshipID(
															internship?.id,
														);

														setEditInternshipModal(
															true,
														);
													}}
												>
													<Edit
														sx={{
															color: "blueviolet",
														}}
													/>
												</button>
												<Link
													title="Applications"
													to={`/employer/applications/${internship?.id}?title=${internship?.data.title}&stipend=${internship?.data.stipend}&type=${internship?.data.workType}&hours=${internship?.data.workHours}&desc=${internship?.data.description}`}
												>
													<Assignment
														sx={{
															color: "green",
														}}
													/>
												</Link>
											</TableCell>
										</TableRow>
									);
								})
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</div>

			{
				//  MODALS
			}
			<Modal
				open={editProfile}
				onClose={() => {
					setEditProfile(false);
					resetEditDetails();
				}}
				aria-labelledby="edit-modal-title"
				aria-describedby="edit-modal-description"
			>
				<Box sx={style}>
					<Typography
						className="flex items-center justify-between gap-1"
						id="modal-title"
						variant="h6"
						component="h2"
					>
						<section className="flex items-center gap-1">
							<EditAttributes />
						</section>
						<section>
							<Close
								className="cursor-pointer"
								onClick={() => {
									setEditProfile(false);
									resetEditDetails();
								}}
							/>
						</section>
					</Typography>
					<form
						action=""
						className=" flex flex-col gap-2"
							onSubmit={fullProfileEdit}

					>
						{alert?.length > 0 && <Alert>{alert}</Alert>}
						<div className="flex flex-col">
							<label htmlFor="">Name</label>
							<Input
								type="text"
								placeholder="New Company Name"
								value={nName}
								onChange={(e) => {
									setNname(e.target.value);
								}}
								required
							/>
						</div>
						<div className="flex flex-col">
							<label htmlFor="">Tagline</label>
							<Input
								type="text"
								placeholder="New Company Tagline"
								value={nTagsline}
								onChange={(e) => {
									setNTagline(e.target.value);
								}}
								required
							/>
						</div>
						<div className="flex flex-col">
							<label htmlFor="">Location</label>
							<select
								style={selectorStyle}
								value={nLocation}
								onChange={(e) => {
									setNLocation(e.target.value);
								}}
								required
							>
								{indianStates.map((state) => (
									<option
										key={state}
										value={state}
									>
										{state}
									</option>
								))}
							</select>
						</div>
						<div className="flex flex-col">
							<label htmlFor="">Industry</label>
							<select
								style={selectorStyle}
								defaultValue={nIndustry}
								onChange={(e) => {
									console.log(e.target.value);

									setNIndustry(e.target.value);
								}}
								required
							>
								{industryTypes.map((type) => (
									<option
										key={type}
										value={type}
									>
										{type}
									</option>
								))}
							</select>
						</div>
						<div className="flex flex-col">
							<label htmlFor="" required>No.of Employees</label>
							<select
								onChange={(e) => {
									setEmps(e.target.value);
								}}
								value={noOfEmps}
								style={selectorStyle}
								required
							>
								<option value="0-10">0-10</option>
								<option value="10-50">10-50</option>
								<option value="50-100">50-100</option>
								<option value="100-200">100-200</option>
								<option value="200+">200+</option>
							</select>
						</div>

						<div className="flex flex-col">
							<label htmlFor="">Description</label>
							<Textarea
								placeholder="Your company description"
								value={nDesc}
								onChange={(e) => {
									setNDesc(e.target.value);
								}}
								maxRows={4}
								required
							></Textarea>
						</div>
						<div className="flex flex-col">
							<div className="flex justify-between items-center w-full">
								<label htmlFor="">Company logo (4x4)</label>
								{(setNlogoData) && (
									<Cancel
										sx={{ cursor: "pointer" }}
										onClick={setNlogoData}
									/>
								)}
							</div>
							<Input
								type="file"
								id="pick_logo"
								sx={{
									display: "none",
								}}
								onChange= {handleFileChange}
								onClick={handleFileChange}
							/>

							<div className="grid place-items-center">
								{nlogo || nlogoData ? (
									<>
										<img
											src={nlogo || nlogoData}
											alt=""
											style={{
												width: "7em",
												height: "7em",
												margin: "0.5em",
												cursor: "pointer",
											}}
											className="rounded-sm"
											onClick={(e) => {
												document
													.getElementById("pick_logo")
													.click();
											}}
										/>
									</>
								) : (
									<>
										<UploadFileOutlined
											sx={{
												color: "lightgray",
												fontSize: "4em",
												cursor: "pointer",
											}}
											onClick={() => {
												document
													.getElementById("pick_logo")
													.click();
											}}
										/>
									</>
								)}
							</div>
						</div>
						<Button type="submit">Save edits</Button>
					</form>
				</Box>
			</Modal>

			<Modal
				open={editInternshipModal}
				onClose={() => setEditInternshipModal(false)}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={style}>
					<div className="flex justify-between items-center">
						<p
							className="font-semibold text-lg"
							id="parent-modal-title"
						>
							Edit Internship
						</p>
						<CancelOutlined
							onClick={() => {
								setEditInternshipModal(false);
							}}
							className="cursor-pointer"
						/>
					</div>
					<div className="">
					<form
						action=""
						className="flex flex-col"
						onSubmit={(e) => {
							e.preventDefault();
							handleInternshipEdit();
						}}
					>
						<div className="mt-2 flex flex-col gap-1">
							<label htmlFor="">Title</label>
							<Input
								placeholder="New title..."
								value={nTitle}
								onChange={(e) => {
									setNtitle(e.target.value);
								}}
							/>
						</div>
						<div className="mt-2 flex flex-col gap-1">
							<label htmlFor="">Stipend</label>
							<Input
								type="number"
								placeholder="Stipend..."
								value={nStipend}
								onChange={(e) => {
									setNstipend(e.target.value);
								}}
							/>
						</div>
						<div className="mt-2 flex flex-col gap-1">
							<label htmlFor="">Duration</label>
							<select
								style={selectorStyle}
								value={nDuration}
								onChange={(e) => {
									setNduration(e.target.value);
								}}
							>
								<option value={1}>1 Months</option>
								<option value={2}>2 Months</option>
								<option value={3}>3 Months</option>
								<option value={6}>6 Months</option>
								<option value={8}>8 Months</option>
								<option value={12}>12 Months</option>
							</select>
						</div>
						<div className="flex gap-2 w-full mt-2">
							<select
								style={selectorStyle}
								value={nWorkHours}
								className="w-full"
								onChange={(e) => {
									setNworkHours(e.target.value);
								}}
							>
								<option value={"Part time"}>Part time</option>
								<option value={"Full time"}>Full time</option>
							</select>
							<select
								style={selectorStyle}
								value={nWorkType}
								className="w-full"
								onChange={(e) => {
									setNworkType(e.target.value);
								}}
							>
								<option value={"Work From Home"}>
									Work From Home
								</option>
								<option value={"Hybrid"}>Hybrid</option>
								<option value={"In-Office"}>In-Office</option>
							</select>
						</div>
						<div className="mt-2 flex flex-col gap-1">
							<label htmlFor="">
								Description of the Internship
							</label>
							<Textarea
								placeholder="A short description on what the internship is about, what the intern will be responsible for etc..."
								maxRows={4}
								minRows={2}
								value={nDescI}
								onChange={(e) => {
									setNDescI(e.target.value);  // Correctly updating the nDescI state
								}}
							/>
						</div>
						<Button
							type="submit"
							disabled={editLoading}
						>
							{loading ? <LinearProgress /> : "Save Edits"}
						</Button>
					</form>
					</div>
				</Box>
			</Modal>
			{<CompoanyFooter/>}
			<Chats />
		</div>
	);
}

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "max-content",
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: "1rem",
	p: 4,
};

const selectorStyle = {
	zIndex: 1000,
	outline: "none",
	border: "1px solid lightgray",
	padding: "0.5rem",
	borderRadius: "5px",
};
/*service firebase.storage {
  match /b/{bucket}/o {
    match /{allPaths=**} {
      // Allow read and write access to all users who are authenticated
      allow read, write: if request.auth != null;
    }
  }
}
*/
