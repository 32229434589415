import React, { useState } from "react";
import logo from "../logo/color_withname.png";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Menu } from "@mui/icons-material";

export default function Nav() {
	const location = useLocation();
	const [isOpen, setIsOpen] = useState(true);
	const [openMenu, setOpenMenu] = useState(false);

	return (
		<>
			<div className="sticky flex flex-wrap gap-5 md:gap-0 md:flex-nowrap p-5 pr-10 pl-10 w-screen justify-between items-center shadow-md bg-white">
				<div className="flex justify-between sm:w-full md:w-auto items-center gap-2">
					<Link
						to="/"
						className="cursor-pointer"
					>
						<img
							src={logo}
							style={{ objectFit: "contain", width: "12rem" }}
							alt=""
						/>
					</Link>
					{/* <CgMore
						className="visible md:hidden"
						onClick={handleMoreClick}
					/> */}
				</div>

				{/* {user && (
					<input
						type="text"
						className=" w-full md:w-5/12 bg-transparent border-1 border-p1 p-3 outline-none rounded-full"
						placeholder="Search AccessCareer..."
					/>
				)} */}

				<ul
					className={`flex flex-wrap gap-8 items-start justify-center list-none ${
						isOpen ? "flex" : "hidden"
					} md:flex`}
				>
					<li>
						<Menu
							className="cursor-pointer text-p1"
							onClick={() => {
								setOpenMenu(!openMenu);
							}}
						/>
						<div
							className={`md:top-16 md:right-12 p-2 rounded-md bg-white shadow-md border-1 border-gray-200 ${
								openMenu
									? "absolute transition-all duration-300 ease-in-out"
									: "hidden"
							}`}
						>
							<ul className="w-full space-y-1">
								<li>
									<Link
										className={`flex flex-col cursor-pointer items-center text-p1 ${location.pathname !== "/tnc" ? "font-normal" : "font-bold"}`}
										to="/"
									>
										Home
									</Link>
								</li>
								<li>
									<Link
										className={`flex flex-col cursor-pointer items-center text-p1 ${location.pathname !== "/tnc" ? "font-normal" : "font-bold"}`}
										to="/tnc"
									>
										Terms & Conditions
									</Link>
								</li>
								<li className="flex justify-between items-center">
									<Link
										className="flex flex-col cursor-pointer items-center text-p1 w-full"
										title="Saved"
										to="/privacy"
									>
										<span
											className={
												location.pathname !== "/privacy"
													? "font-normal"
													: "font-semibold"
											}
										>
											Privacy Policy
										</span>
									</Link>
								</li>
								<li className="flex justify-between items-center">
									<Link
										className="flex flex-col cursor-pointer items-center text-p1 w-full"
										title="Saved"
										to="/about"
									>
										<span
											className={
												location.pathname !== "/about"
													? "font-normal"
													: "font-semibold"
											}
										>
											About Us
										</span>
									</Link>
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
		</>
	);
}
