import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { auth, db } from "../Config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";

const ProtectedRouteEmp = ({ children }) => {
  const [user, setUser] = useState();
  const [authChecked, setAuthChecked] = useState(false);
  const [isEmployer, setEmployer] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);

      if (user) {
        const userDocRef = collection(db, "Users");
        const userDocs = await getDocs(
          query(userDocRef, where("UserID", "==", user.uid))
        );

        userDocs.forEach((doc) => {
          setEmployer(doc.data()?.Employer);
        });
      }

      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  if (!authChecked) {
    return null;
  }

  if (!user?.uid) {
    return <Navigate to={"/auth"} state={{ from: location }} replace />;
  }

  if (!isEmployer) {
    auth.signOut().then(() => {
      return (
        <Navigate to={"/auth?tab=student"} state={{ from: location }} replace />
      );
    });
  }

  return <>{children}</>;
};

export default ProtectedRouteEmp;
