/*import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    addDoc,
    collection,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    where,
	increment,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, auth, storage } from "../Config/firebase";
import useUser from "../Utils/useUser";
import { v4 } from "uuid";
import { FaDownload } from "react-icons/fa";
import { saveAs } from "file-saver";
import logo from '../Components/Assets/white-bg.png';
import { getEmailIdOfUser } from "../Utils/GetEmailIdOfUser";
import { ArrowBackIos } from "@mui/icons-material";

export default function Chat() {
	const navigate = useNavigate()
    const { id } = useParams();
    const [newMessage, setNewMessage] = useState("");
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [lastMessageTime, setLastMessageTime] = useState(null);
    const [user] = useUser();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState(null);
	const [recpEmail, setRecpEmail] = useState();

    const messagesEndRef = useRef(null);
    const messageRef = collection(db, "messages");

	useEffect(() => {
        const fetchEmail = async () => {
            try {
                if (id) {
                    const response = await getEmailIdOfUser(id);
                    setRecpEmail(response);
                }
            } catch (error) {
                console.error('Error fetching recipient email:', error);
            }
        };

        fetchEmail();
    }, [id]); 

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() && !file) return;

        let fileURL = null;
        let fileName = null;

        try {
            setLoading(true);

            // Check if file is present
            if (file) {
                let compressedFile = file;
                const isImage = /\.(jpeg|jpg|gif|png)$/i.test(file.name);

                // Compress the image if it is an image file
                if (isImage) {
                    compressedFile = await compressImage(file);
                }

                const fileExtension = compressedFile.name.split(".").pop();
                const fileNameWithExtension = `${v4()}.${fileExtension}`;
                const fileRef = ref(storage, `filesOfChat/${fileNameWithExtension}`);

                // Upload file
                await uploadBytes(fileRef, compressedFile);
                fileURL = await getDownloadURL(fileRef);
                fileName = compressedFile.name; // Use the compressed file name
            }

            // Add message to firestore
            await addDoc(messageRef, {
                ofChat: id,
                message: newMessage,
                file: fileURL,
                fileName: fileName,
                time: serverTimestamp(),
                messageBy: user?.uid,
				
            });

            setNewMessage("");
            setFile(null);
        } catch (error) {
            console.error("Error submitting message:", error.message);
        } finally {
            setLoading(false);
        }
    };

    const compressImage = async (imageFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(imageFile);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    const maxWidth = 800; // Maximum width for the image
                    const maxHeight = 600; // Maximum height for the image
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    ctx.drawImage(img, 0, 0, width, height);
                    canvas.toBlob(
                        (blob) => {
                            if (blob) {
                                resolve(
                                    new File([blob], imageFile.name, {
                                        type: "image/jpeg",
                                    })
                                );
                            } else {
                                reject(new Error("Compression failed."));
                            }
                        },
                        "image/jpeg",
                        0.7
                    ); // Adjust the quality (0.7 here means 70% quality)
                };
                img.onerror = (error) => {
                    reject(error);
                };
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const openModal = (image) => {
        setModalImage(image);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalImage(null);
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, file]);

    useEffect(() => {
        if (id && user) {
            const q = query(
                collection(db, "messages"),
                where("ofChat", "==", id),
                orderBy("time")
            );

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const fetchedMessages = [];
                let newMessageReceived = false;

                querySnapshot.forEach((doc) => {
                    const messageData = doc.data();
                    fetchedMessages.push(messageData);

                    // Check if the message is new based on the timestamp
                    if (messageData.time && (!lastMessageTime || messageData.time.toMillis() > lastMessageTime?.toMillis())) {
                        newMessageReceived = true;
                        setLastMessageTime(messageData.time);
                    }
                });

                setMessages(fetchedMessages);

            });

            return () => unsubscribe();
        }
    }, [id, user, lastMessageTime]);

	return (
		<form onSubmit={handleSubmit}>
			<div className="flex h-screen antialiased text-gray-800">
				<div
					className="flex flex-row h-full w-full overflow-x-hidden"
					id="chat"
				>
					<div className="flex flex-col flex-auto h-full p-6 relative">
						<button className="border-none outline-none cursor-pointer bg-white p-2 rounded-full shadow-md absolute flex items-center justify-center m-2"
							onClick={() => {
								navigate(-1);
							}}
						>
							<ArrowBackIos />
						</button>
						<div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4 shadow-md">
							<div className="flex flex-col h-full overflow-x-auto mb-4 w-full gap-2">
								{messages.map((message, index) => (
									<Message
										key={index}
										message={message.message}
										file={message.file}
										fileName={message.fileName}
										sentByUser={
											message.messageBy === user?.uid
										}
										openModal={openModal}
									/>
								))}
								<div ref={messagesEndRef} />
							</div>
							<div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
								<div className="flex flex-grow justify-between items-center">
									<div className="flex items-center">
										{file && (
											<span className="mr-2">
												{file.name}
											</span>
										)}
										<label
											htmlFor="fileUpload"
											className="flex items-center justify-center text-gray-400 hover:text-gray-600 cursor-pointer"
										>
											<svg
												className="w-5 h-5 mr-2"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6"
												></path>
											</svg>
											Upload File
											<input
												type="file"
												id="fileUpload"
												className="hidden"
												onChange={handleFileUpload}
											/>
										</label>
									</div>
									<div className="flex-grow ml-4">
										<input
											type="text"
											onChange={(e) => {
												setNewMessage(e.target.value);
											}}
											value={newMessage}
											onKeyDown={(e) => {
												// Check if Enter key is pressed and no files are selected
												if (
													e.key === "Enter" &&
													!file
												) {
													e.preventDefault(); // Prevent form submission on Enter key press
													handleSubmit(e); // Call handleSubmit manually
												}
											}}
											placeholder="Type your message here..."
											className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10 input-msg"
										/>
									</div>
								</div>
								<div className="ml-4">
									<button
										type="submit"
										className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
										disabled={
											(newMessage === "" &&
												file === null) ||
											loading
										}
									>
										{loading ? "Sending..." : "Send"}
										<span className="ml-2">
											<svg
												className="w-4 h-4 transform rotate-45 -mt-px"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
												></path>
											</svg>
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}

const Message = ({ message, file, fileName, sentByUser, openModal }) => {
	const isImage = /\.(jpeg|jpg|gif|png)$/i.test(fileName);

	const handleDownload = async (fileUrl, fileName) => {
		if (fileName) {
			try {
				console.log("Attempting to download file from URL:", fileUrl);

				// Fetch the file as a Blob
				const response = await fetch(fileUrl);
				if (!response.ok) {
					throw new Error(
						`Network response was not ok: ${response.statusText}`,
					);
				}
				const blob = await response.blob();

				// Use file-saver to save the Blob with the correct file name
				saveAs(blob, fileName);

				console.log("File downloaded successfully:", fileName);
			} catch (error) {
				console.error("Error downloading file:", error.message);

				if (error.message.includes("Failed to fetch")) {
					console.error(
						"This might be due to a CORS policy issue or a network connectivity problem.",
					);
				} else if (
					error.message.includes("Network response was not ok")
				) {
					console.error(
						"Ensure the file URL is correct and the server is reachable.",
					);
				} else {
					console.error("An unexpected error occurred.");
				}
			}
		}
	};

	return (
		<div
			className={`flex gap-1 items-center ${
				sentByUser ? "self-end" : ""
			}`}
		>
			{!sentByUser && (
				<img
					src="https://img.freepik.com/premium-photo/dynamic-3d-male-child-captivating-frontfacing-random-character-with-fair-skin-set-against-dar_983420-2488.jpg"
					style={{
						objectFit: "contain",
						borderRadius: "50%",
						width: "30px",
						height: "30px",
					}}
					alt="User avatar"
				/>
			)}
			{file && (
				<div className="flex items-center">
					{isImage ? (
						<img
							src={file}
							alt={fileName}
							style={{
								maxWidth: "250px",
								maxHeight: "250px",
								cursor: "pointer",
							}}
							className="rounded-md"
							onClick={() => openModal(file)}
						/>
					) : (
						<button
							className="flex items-center justify-center text-gray-400 hover:text-gray-600 cursor-pointer"
							onClick={() => {
								console.log(
									`Button clicked to download: ${fileName}`,
								);

								handleDownload(file, fileName);
							}}
						>
							<FaDownload className="mr-2" />
							{fileName}
						</button>
					)}
				</div>
			)}
			{!file && (
				<div
					className={`flex w-max max-w-[40em] p-2 rounded-md ${
						sentByUser ? "bg-p2 text-white self-end" : "bg-p4"
					}`}
				>
					{message}
				</div>
			)}
			{sentByUser && (
				<img
					src="https://img.freepik.com/premium-photo/dynamic-3d-male-child-captivating-frontfacing-front-fair-skin-set-against-dar_983420-2488.jpg"
					style={{
						objectFit: "contain",
						borderRadius: "50%",
						width: "30px",
						height: "30px",
					}}
					alt="User avatar"
				/>
			)}
		</div>
	);
};

const Modal = ({ image, closeModal }) => {
	const handleCloseModal = (e) => {
		if (e.target.classList.contains("modal-overlay")) {
			closeModal();
		}
	};

	return (
		<div
			className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 modal-overlay"
			onClick={handleCloseModal}
		>
			<div className="max-w-lg bg-white w-full rounded-lg p-4 relative">
				<button
					onClick={closeModal}
					className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700"
				>
					<svg
						className="w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						></path>
					</svg>
				</button>
				<img
					src={image}
					alt="Uploaded"
					className="max-w-full"
				/>
			</div>
		</div>
	);
};*/
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    addDoc,
    collection,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    where,
	increment,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, auth, storage } from "../Config/firebase";
import useUser from "../Utils/useUser";
import { v4 } from "uuid";
import { FaDownload } from "react-icons/fa";
import { saveAs } from "file-saver";
import logo from '../Components/Assets/white-bg.png';
import { getEmailIdOfUser } from "../Utils/GetEmailIdOfUser";
import { ArrowBackIos } from "@mui/icons-material";
import profile_logo from "../logo/profile_logo.png"

export default function Chat() {
	const navigate = useNavigate()
    const { id } = useParams();
    const [newMessage, setNewMessage] = useState("");
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [lastMessageTime, setLastMessageTime] = useState(null);
    const [user] = useUser();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState(null);
	const [recpEmail, setRecpEmail] = useState();

    const messagesEndRef = useRef(null);
    const messageRef = collection(db, "messages");

	useEffect(() => {
        const fetchEmail = async () => {
            try {
                if (id) {
                    const response = await getEmailIdOfUser(id);
                    setRecpEmail(response);
                }
            } catch (error) {
                console.error('Error fetching recipient email:', error);
            }
        };

        fetchEmail();
    }, [id]); 

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() && !file) return;

        let fileURL = null;
        let fileName = null;

        try {
            setLoading(true);

            // Check if file is present
            if (file) {
                let compressedFile = file;
                const isImage = /\.(jpeg|jpg|gif|png)$/i.test(file.name);

                // Compress the image if it is an image file
                if (isImage) {
                    compressedFile = await compressImage(file);
                }

                const fileExtension = compressedFile.name.split(".").pop();
                const fileNameWithExtension = `${v4()}.${fileExtension}`;
                const fileRef = ref(storage, `filesOfChat/${fileNameWithExtension}`);

                // Upload file
                await uploadBytes(fileRef, compressedFile);
                fileURL = await getDownloadURL(fileRef);
                fileName = compressedFile.name; // Use the compressed file name
            }

            // Add message to firestore
            await addDoc(messageRef, {
                ofChat: id,
                message: newMessage,
                file: fileURL,
                fileName: fileName,
                time: serverTimestamp(),
                messageBy: user?.uid,
				
            });

            setNewMessage("");
            setFile(null);
        } catch (error) {
            console.error("Error submitting message:", error.message);
        } finally {
            setLoading(false);
        }
    };

    const compressImage = async (imageFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(imageFile);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    const maxWidth = 800; // Maximum width for the image
                    const maxHeight = 600; // Maximum height for the image
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    ctx.drawImage(img, 0, 0, width, height);
                    canvas.toBlob(
                        (blob) => {
                            if (blob) {
                                resolve(
                                    new File([blob], imageFile.name, {
                                        type: "image/jpeg",
                                    })
                                );
                            } else {
                                reject(new Error("Compression failed."));
                            }
                        },
                        "image/jpeg",
                        0.7
                    ); // Adjust the quality (0.7 here means 70% quality)
                };
                img.onerror = (error) => {
                    reject(error);
                };
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const openModal = (image) => {
        setModalImage(image);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalImage(null);
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, file]);

    useEffect(() => {
        if (id && user) {
            const q = query(
                collection(db, "messages"),
                where("ofChat", "==", id),
                orderBy("time")
            );

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const fetchedMessages = [];
                let newMessageReceived = false;

                querySnapshot.forEach((doc) => {
                    const messageData = doc.data();
                    fetchedMessages.push(messageData);

                    // Check if the message is new based on the timestamp
                    if (messageData.time && (!lastMessageTime || messageData.time.toMillis() > lastMessageTime?.toMillis())) {
                        newMessageReceived = true;
                        setLastMessageTime(messageData.time);
                    }
                });

                setMessages(fetchedMessages);

            });

            return () => unsubscribe();
        }
    }, [id, user, lastMessageTime]);

	return (
		<form onSubmit={handleSubmit}>
			<div className="flex h-screen antialiased text-gray-800">
				<div
					className="flex flex-row h-full w-full overflow-x-hidden"
					id="chat"
				>
					<div className="flex flex-col flex-auto h-full p-6 relative">
						<button className="border-none outline-none cursor-pointer bg-white p-2 rounded-full shadow-md absolute flex items-center justify-center m-2"
							onClick={() => {
								navigate(-1);
							}}
						>
							<ArrowBackIos />
						</button>
						<div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4 shadow-md">
							<div className="flex flex-col h-full overflow-x-auto mb-4 w-full gap-2">
								{messages.map((message, index) => (
									<Message
										key={index}
										message={message.message}
										file={message.file}
										fileName={message.fileName}
										sentByUser={
											message.messageBy === user?.uid
										}
										openModal={openModal}
									/>
								))}
								<div ref={messagesEndRef} />
							</div>
							<div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
								<div className="flex flex-grow justify-between items-center">
									<div className="flex items-center">
										{file && (
											<span className="mr-2">
												{file.name}
											</span>
										)}
										<label
											htmlFor="fileUpload"
											className="flex items-center justify-center text-gray-400 hover:text-gray-600 cursor-pointer"
										>
											<svg
												className="w-5 h-5 mr-2"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6"
												></path>
											</svg>
											Upload File
											<input
												type="file"
												id="fileUpload"
												className="hidden"
												onChange={handleFileUpload}
											/>
										</label>
									</div>
									<div className="flex-grow ml-4">
										<input
											type="text"
											onChange={(e) => {
												setNewMessage(e.target.value);
											}}
											value={newMessage}
											onKeyDown={(e) => {
												// Check if Enter key is pressed and no files are selected
												if (
													e.key === "Enter" &&
													!file
												) {
													e.preventDefault(); // Prevent form submission on Enter key press
													handleSubmit(e); // Call handleSubmit manually
												}
											}}
											placeholder="Type your message here..."
											className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10 input-msg"
										/>
									</div>
								</div>
								<div className="ml-4">
									<button
										type="submit"
										className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
										disabled={
											(newMessage === "" &&
												file === null) ||
											loading
										}
									>
										{loading ? "Sending..." : "Send"}
										<span className="ml-2">
											<svg
												className="w-4 h-4 transform rotate-45 -mt-px"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
												></path>
											</svg>
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}

const Message = ({ message, file, fileName, sentByUser, openModal }) => {
	const isImage = /\.(jpeg|jpg|gif|png)$/i.test(fileName);

	const handleDownload = async (fileUrl, fileName) => {
		if (fileName) {
			try {
				console.log("Attempting to download file from URL:", fileUrl);
				const response = await fetch(fileUrl);
				if (!response.ok) {
					throw new Error(`Network response was not ok: ${response.statusText}`);
				}
				const blob = await response.blob();
				saveAs(blob, fileName);
				console.log("File downloaded successfully:", fileName);
			} catch (error) {
				console.error("Error downloading file:", error.message);
				if (error.message.includes("Failed to fetch")) {
					console.error("This might be due to a CORS policy issue or a network connectivity problem.");
				} else if (error.message.includes("Network response was not ok")) {
					console.error("Ensure the file URL is correct and the server is reachable.");
				} else {
					console.error("An unexpected error occurred.");
				}
			}
		}
	};

	return (
		<div className={`flex gap-1 items-center ${sentByUser ? "self-end" : ""}`}>
			{/* User Avatar */}
			{!sentByUser && (
				<img
					src={profile_logo}
					alt="User avatar"
					className="w-8 h-8 rounded-full object-cover"
				/>
			)}

			{/* File Handling */}
			{file ? (
				<div className="flex items-center">
					{isImage ? (
						<img
							src={file}
							alt={fileName}
							className="max-w-[250px] max-h-[250px] cursor-pointer rounded-md"
							onClick={() => openModal(file)}
						/>
					) : (
						<button
							className="flex items-center justify-center text-gray-400 hover:text-gray-600 cursor-pointer"
							onClick={() => {
								console.log(`Button clicked to download: ${fileName}`);
								handleDownload(file, fileName);
							}}
						>
							<FaDownload className="mr-2" />
							{fileName}
						</button>
					)}
				</div>
			) : (
			<div
			className={`flex w-full max-w-[70%] p-2 rounded-md ${
				sentByUser ? "bg-p2 text-white self-end" : "bg-p4"
			}`}
			style={{
				overflowWrap: "break-word",
				wordBreak: "break-word",
				whiteSpace: "pre-wrap",
				maxWidth: "600px", // Limit the width
				wordWrap: "break-word", // Add this for better word break handling
			}}
			>
			<span>{message}</span>
			</div>

			)}

			{sentByUser && (
				<img
					src={profile_logo}
					alt="User avatar"
					className="w-8 h-8 rounded-full object-cover"
				/>
			)}
		</div>
	);
};

const Modal = ({ image, closeModal }) => {
	const handleCloseModal = (e) => {
		if (e.target.classList.contains("modal-overlay")) {
			closeModal();
		}
	};

	return (
		<div
			className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 modal-overlay"
			onClick={handleCloseModal}
		>
			<div className="max-w-lg bg-white w-full rounded-lg p-4 relative">
				<button
					onClick={closeModal}
					className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700"
				>
					<svg
						className="w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						></path>
					</svg>
				</button>
				<img
					src={image}
					alt="Uploaded"
					className="max-w-full"
				/>
			</div>
		</div>
	);
};

export { Message, Modal };