import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { HiDotsHorizontal } from "react-icons/hi";
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc } from "firebase/firestore";
import { db, auth } from '../../../Config/firebase'; // Adjusted path
import { useAuthState } from 'react-firebase-hooks/auth';
import StuProfileCard from './StuProfileCard'; // Adjusted path

const PostCard = ({ id, userId, userName, photoURL, image, content, images, from, postBy, likes, timestamp }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [currentLikes, setCurrentLikes] = useState(likes || []);
  const [user] = useAuthState(auth);
  const [userNameFetched, setUserNameFetched] = useState(userName);

  useEffect(() => {
    const fetchUserName = async () => {
      if (postBy) {
        const userDoc = await getDoc(doc(db, "other_details", postBy));
        if (userDoc.exists()) {
          setUserNameFetched(userDoc.data().name);
        }
      }
    };

    fetchUserName();
  }, [postBy]);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLike = async () => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    const userId = user.uid;
    const postRef = doc(db, 'posts', id);

    // Optimistic UI update
    try {
      if (currentLikes.includes(userId)) {
        // User has already liked the post, remove like optimistically
        setCurrentLikes(currentLikes.filter(id => id !== userId));
        await updateDoc(postRef, {
          likes: arrayRemove(userId)
        });
      } else {
        // User has not liked the post, add like optimistically
        setCurrentLikes([...currentLikes, userId]);
        await updateDoc(postRef, {
          likes: arrayUnion(userId)
        });
      }
    } catch (error) {
      console.error('Error toggling like:', error);
      // Revert to previous state on error
      setCurrentLikes(currentLikes);
    }
  };

  const formattedTimestamp = new Date(timestamp?.seconds * 1000).toLocaleString('en-IN', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return (
    <div className={`relative flex flex-col gap-2 md:p-8 m-2 bg-white ${from === 'e' ? "border-1p5 border-gray-300 p-2" : "shadow-md p-4"} rounded-md min-w-full md:min-w-[40rem] max-w-screen-sm items-center`}>
      <StuProfileCard userId={userId} /> {/* Include StuProfileCard and pass userId */}
      <div className="flex w-full justify-between items-center">
        <p className="text-gray-600 text-sm">{formattedTimestamp}</p>
        {
          from === "p" && (
            <div className="relative">
              <HiDotsHorizontal className='cursor-pointer' color='gray' onClick={toggleMenu} />
              {menuVisible && <Menu />}
            </div>
          )
        }
      </div>
      <p className='p-2 text-left w-full border-1p5 border-gray-200'>
        {content}
      </p>
      {images && images.length > 0 && <ImageCarousel images={images} />}
      <div className="flex w-full justify-between items-center mt-2">
        <button onClick={handleLike} className="text-gray-500 hover:text-gray-700">Like ({currentLikes.length})</button>
      </div>
    </div>
  );
};

const Menu = ({ from }) => {
  return (
    from !== 'vp' && (
      <div className="absolute right-0 top-full mt-2 flex flex-col gap-2 p-4 bg-white shadow-md rounded-md w-max z-10">
        <p className="cursor-pointer">
          Delete Post
        </p>
      </div>
    )
  );
};

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative w-full max-w-full overflow-hidden">
      <div className="relative h-64">
        {images.map((imgSrc, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full transition-transform duration-500 ease-in-out transform rounded-md ${
              index === currentIndex ? 'translate-x-0' : 'translate-x-full'
            } ${index < currentIndex ? 'translate-x-[-100%]' : ''}`}
            style={{
              backgroundImage: `url(${imgSrc})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: "no-repeat"
            }}
          ></div>
        ))}
      </div>
      <button
        onClick={prevSlide}
        className="absolute opacity-50 m-1 top-1/2 left-0 transform -translate-y-1/2 bg-gray-200 text-gray-800 p-2 rounded-full hover:bg-gray-400"
      >
        <FaArrowLeft />
      </button>
      <button
        onClick={nextSlide}
        className="absolute opacity-50 m-1 top-1/2 right-0 transform -translate-y-1/2 bg-gray-200 text-gray-800 p-2 rounded-full hover:bg-gray-400"
      >
        <FaArrowRight />
      </button>
    </div>
  );
};

export default PostCard;
